const Util = require("../helpers/util")
//
// export function Assessment(holeCardsArr, boardCardsArr, gameObj, analysisObj, isThreatAssessment) {
//
//   let allCardsArr = [...holeCardsArr, ...boardCardsArr]
//   let drawObj = {
//     isFlushDraw: false,
//     variety:'',
//     outsArr: [],
//     totalNumSuited:0,
//     fullFlushDrawRankSeq:[],
//     flushDrawRankSeqInPlay:[],
//     flushSuit:'',
//     numBoardSuitedContrib: 0
//   }
//
//   if (!isThreatAssessment) {
//     drawObj = {
//       ...drawObj, ...{
//         isHoleCardTopFlushCardPossible: false,
//         numHoleCardsContrib: 0,
//         isPlayingTheBoard: false,
//         holeFlushCardsArr: [],
//         topFlushHoleCardRank: 0}
//     }
//   }
//
//   let numSuitArr = []
//   for(let i in allCardsArr) {
//     let suit = allCardsArr[i].suit
//     if (!numSuitArr[suit]) {
//       numSuitArr[suit] = 1
//     } else {
//       numSuitArr[suit]++
//     }
//   }
//
//   for(let suit in numSuitArr) {
//     if (numSuitArr[suit] >= 5) {
//       drawObj.variety = 'made flush'
//       drawObj.flushSuit = suit
//       drawObj.totalNumSuited = numSuitArr[suit]
//       break
//     } else if (numSuitArr[suit] === 4 && drawObj.variety === '') {
//       if (isThreatAssessment || (!isThreatAssessment && gameObj.street !== 'river')) {
//         drawObj.variety = 'four flush'
//         drawObj.flushSuit = suit
//         drawObj.totalNumSuited = 4
//       }
//     } else if (numSuitArr[suit] === 3 && drawObj.variety === '') {
//       if (isThreatAssessment || (!isThreatAssessment && gameObj.street !== 'river' && gameObj.street !== 'turn')) {
//         drawObj.variety = 'three flush'
//         drawObj.flushSuit = suit
//         drawObj.totalNumSuited = 3
//       }
//     } else if (isThreatAssessment && numSuitArr[suit] === 2 && drawObj.variety === '') {
//       if (isThreatAssessment || (!isThreatAssessment && gameObj.street !== 'river' && gameObj.street !== 'turn')) {
//         drawObj.variety = 'two flush'
//         drawObj.flushSuit = suit
//         drawObj.totalNumSuited = 2
//       }
//     }
//   }
//
//   if (drawObj.variety === 'made flush') {
//     drawObj.isFlush = true
//   }
//   if (isThreatAssessment && gameObj.street === 'river' && drawObj.variety === 'two flush') {
//     drawObj.variety = ''
//   }else if (drawObj.variety !== '' && drawObj.variety !== 'made flush' && isThreatAssessment) {
//     drawObj.isFlushDraw = true
//   } else if (!isThreatAssessment && drawObj.variety === 'four flush') {
//     drawObj.isFlushDraw = true
//   }
//
//   if (drawObj.variety !== '') {
//
//     // build array of all flush cards
//     for (let i in allCardsArr) {
//       let suit = allCardsArr[i].suit
//       if (suit === drawObj.flushSuit) {
//         drawObj.fullFlushDrawRankSeq.push(allCardsArr[i].rank)
//       }
//     }
//     // sort desc
//     drawObj.fullFlushDrawRankSeq.sort((firstItem, secondItem) => secondItem - firstItem);
//     // handle 14 (ace),6,5,4,3,2
//     let indexStart = 0
//     let indexEnd = drawObj.fullFlushDrawRankSeq.length
//     if (analysisObj.rankObj.straightflush && drawObj.fullFlushDrawRankSeq.length >= 6 && drawObj.fullFlushDrawRankSeq[0] === 14) {
//       indexStart = 1
//     }
//
//     drawObj.flushDrawRankSeqInPlay = [...drawObj.fullFlushDrawRankSeq.slice(indexStart, indexEnd)]
//     analysisObj.boardObj.boardFlushRankArr = []
//     for(let i in boardCardsArr) {
//       if (boardCardsArr[i].suit === drawObj.flushSuit) {
//         drawObj.numBoardSuitedContrib++
//         analysisObj.boardObj.boardFlushRankArr.push(boardCardsArr[i].rank)
//       }
//     }
//
//     // see how many hole cards are in the top 5
//     // a player may be forced to play the board when there are 5 suited
//     // if it's a threat assessment, hole cards are unknown
//     if (!isThreatAssessment) {
//       drawObj.holeCardFlushRankArr = []
//       for (let i in holeCardsArr) {
//         if (holeCardsArr[i].suit === drawObj.flushSuit) {
//           drawObj.holeCardFlushRankArr.push(holeCardsArr[i].rank)
//           drawObj.holeFlushCardsArr.push(holeCardsArr[i])
//         }
//       }
//
//       analysisObj.holeCardsObj.highcard.flushCardPosition = false
//       analysisObj.holeCardsObj.lowcard.flushCardPosition = false
//       for (let i in drawObj.holeFlushCardsArr) {
//         let holeCardRank = drawObj.holeCardFlushRankArr[i]
//         if (analysisObj.holeCardsObj.highcard.rank === holeCardRank && analysisObj.holeCardsObj.highcard.suit === drawObj.flushSuit) {
//           let flushCardPosition = Util.getCardPositionAgainstCardRankArr(
//             holeCardRank,
//             [...analysisObj.boardObj.boardFlushRankArr, analysisObj.holeCardsObj.lowcard.rank]
//           )
//           analysisObj.holeCardsObj.highcard.flushCardPosition = flushCardPosition
//         } else if (analysisObj.holeCardsObj.lowcard.rank === holeCardRank && analysisObj.holeCardsObj.lowcard.suit === drawObj.flushSuit) {
//           let flushCardPosition = Util.getCardPositionAgainstCardRankArr(
//             holeCardRank,
//             [...analysisObj.boardObj.boardFlushRankArr, analysisObj.holeCardsObj.highcard.rank]
//           )
//           analysisObj.holeCardsObj.lowcard.flushCardPosition = flushCardPosition
//         }
//       }
//
//
//
//       // is a flush hole card the top flush card in play? ie. position 1 to the board
//       if (analysisObj.holeCardsObj.highcard.flushCardPosition === 1 || analysisObj.holeCardsObj.lowcard.flushCardPosition === 1) {
//         drawObj.isHoleCardTopFlushCardInPlay = true
//       }
//       if (analysisObj.holeCardsObj.highcard.flushCardPosition <= 5 && analysisObj.holeCardsObj.lowcard.flushCardPosition <= 5) {
//         drawObj.numHoleCardsContrib = 2
//         drawObj.topFlushHoleCardPosition = analysisObj.holeCardsObj.highcard.flushCardPosition
//         drawObj.topFlushHoleCardRank = analysisObj.holeCardsObj.highcard.rank
//       } else if (analysisObj.holeCardsObj.highcard.flushCardPosition <= 5 && analysisObj.holeCardsObj.lowcard.flushCardPosition > 5) {
//         drawObj.numHoleCardsContrib = 1
//         drawObj.topFlushHoleCardPosition = analysisObj.holeCardsObj.highcard.flushCardPosition
//         drawObj.topFlushHoleCardRank = analysisObj.holeCardsObj.highcard.rank
//       } else if (analysisObj.holeCardsObj.highcard.flushCardPosition > 5 && analysisObj.holeCardsObj.lowcard.flushCardPosition <= 5) {
//         drawObj.numHoleCardsContrib = 1
//         drawObj.topFlushHoleCardPosition = analysisObj.holeCardsObj.lowcard.flushCardPosition
//         drawObj.topFlushHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
//       } else {
//         drawObj.numHoleCardsContrib = 0
//         drawObj.topFlushHoleCardRank = false
//         drawObj.topFlushHoleCardPosition = false
//         drawObj.isPlayingTheBoard = true
//       }
//       // is flush hole card top flush card possible? ie. board has A,K,Q of hearts and hole card is J of hearts = top
//       let topRank = 14
//       let sortedCommRankArr = Object.entries(boardCardsArr)
//       sortedCommRankArr.sort(
//         (firstItem, secondItem) => {
//           return firstItem[1].rank < secondItem[1].rank ? 1 : -1
//         }
//       );
//       for(let i in sortedCommRankArr) {
//         let commRank = sortedCommRankArr[i][1].rank
//         let commSuit = sortedCommRankArr[i][1].suit
//         if (commSuit !== drawObj.flushSuit) {
//           continue
//         }
//         if (commRank >= topRank) {
//           topRank--
//         }
//       }
//       if ((holeCardsArr[0].suit === drawObj.flushSuit && holeCardsArr[0].rank >= topRank)
//         || (holeCardsArr[1].suit === drawObj.flushSuit && holeCardsArr[1].rank >= topRank)) {
//         drawObj.isHoleCardTopFlushCardPossible = true
//       }
//
//     }
//
//     // build outs
//     if (drawObj.variety !== 'two flush') {
//       for (let i = 2; i < 15; i++) {
//         if (!drawObj.fullFlushDrawRankSeq.includes(i)) {
//           drawObj.outsArr.push({ rank: i, suit: drawObj.flushSuit })
//         }
//       }
//     }
//
//   }
//
//   if (!isThreatAssessment) {
//     drawObj.fourCardFlush = false
//     drawObj.threeCardFlush = false
//     //if (gameObj.street !== 'river') {
//       //if (drawObj.isFlushDraw) {
//     if (drawObj.variety === 'made flush') {
//       if (drawObj.isPlayingTheBoard) {
//
//       }
//
//     } else if (drawObj.numBoardSuitedContrib === 2 && drawObj.isHoleCardTopFlushCardPossible
//       && drawObj.variety === 'four flush') {
//       drawObj.fourCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 3 && drawObj.isHoleCardTopFlushCardPossible
//       && drawObj.variety === 'four flush') {
//       drawObj.fourCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 2 && drawObj.variety === 'four flush') {
//       drawObj.fourCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 3 && drawObj.variety === 'four flush') {
//       drawObj.fourCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 1 && drawObj.isHoleCardTopFlushCardPossible) {
//       drawObj.threeCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 2 && drawObj.isHoleCardTopFlushCardPossible) {
//       drawObj.threeCardFlush = true
//     } else if (drawObj.numBoardSuitedContrib === 2 && drawObj.numHoleCardsContrib === 1) {
//       drawObj.threeCardFlush = true
//     }
//       //}
//     //}
//   } else {
//     drawObj.fourCardFlush = false
//     drawObj.threeCardFlush = false
//     drawObj.twoCardFlush = false
//     if (drawObj.variety === 'four flush') {
//       drawObj.fourCardFlush = true
//     } else if (drawObj.variety === 'three flush') {
//       drawObj.threeCardFlush = true
//     } else if (drawObj.variety === 'two flush') {
//       drawObj.twoCardFlush = true
//     }
//   }
//
//   return drawObj
//
// }

class baseFlushDraw {
  constructor(boardCardsArr, gameObj, analysisObj) {
    this.boardCardsArr = boardCardsArr
    this.gameObj = gameObj
    this.analysisObj = analysisObj
    this.gameObj = gameObj
    this.allCardsArr = [...boardCardsArr]
    this.drawObj = {
      isFlushDraw: false,
      variety: '',
      secondVariety: '',
      secondFlushSuit: '',
      secondTotalNumSuited: '',
      outsArr: [],
      totalNumSuited: 0,
      fullFlushDrawRankSeq: [],
      flushDrawRankSeqInPlay: [],
      flushSuit: '',
      numBoardSuitedContrib: 0,
      fourCardFlush: false,
      threeCardFlush: false
    }
  }

  betterHandAlreadyHad() {
    // don't bother assessing flush draw when higher hand is already had. need evaluation with a made flush though
    if (this.analysisObj.rankObj.fullhouse || this.analysisObj.rankObj.fourofakind || this.analysisObj.straightflush
      // || this.analysisObj.rankObj.flush
    ) {
      return true
    }
    return false
  }

  setOuts() {
    // build outs
    if (this.drawObj.variety !== 'two flush') {
      for (let i = 2; i < 15; i++) {
        if (!this.drawObj.fullFlushDrawRankSeq.includes(i)) {
          this.drawObj.outsArr.push({ rank: i, suit: this.drawObj.flushSuit })
        }
      }
    }
  }

  setVarietyPlus() {
    let numSuitArr = []
    for (let i in this.allCardsArr) {
      let suit = this.allCardsArr[i].suit
      if (!numSuitArr[suit]) {
        numSuitArr[suit] = 1
      } else {
        numSuitArr[suit]++
      }
    }

    for (let suit in numSuitArr) {
      if (this.gameObj.street === 'turn') {
        //let tmp = 1
      }
      if (numSuitArr[suit] >= 5) {
        this.drawObj.variety = 'made flush'
        this.drawObj.flushSuit = suit
        this.drawObj.totalNumSuited = numSuitArr[suit]
        break
      } else if (numSuitArr[suit] === 4 && this.drawObj.variety === '') {
        this.drawObj.variety = 'four flush'
        this.drawObj.flushSuit = suit
        this.drawObj.totalNumSuited = 4
      } else if (numSuitArr[suit] === 3 && this.drawObj.variety === '') {
        this.drawObj.variety = 'three flush'
        this.drawObj.flushSuit = suit
        this.drawObj.totalNumSuited = 3
      } else if (numSuitArr[suit] === 2 && this.drawObj.variety === '') {
        this.drawObj.variety = 'two flush'
        this.drawObj.flushSuit = suit
        this.drawObj.totalNumSuited = 2
      } else if (numSuitArr[suit] === 2 && this.drawObj.variety === 'two flush' && suit !== this.drawObj.flushSuit) {
        // board is Js,10h,6h,3s - 2 spades and 2 hearts
        this.drawObj.secondVariety = 'two flush'
        this.drawObj.secondFlushSuit = suit
        this.drawObj.secondTotalNumSuited = 2
      }
    }

    if (this.drawObj.variety === 'made flush') {
      this.drawObj.isFlush = true
    }
    if (this.drawObj.variety !== '') {
      // build array of all flush cards
      for (let i in this.allCardsArr) {
        let suit = this.allCardsArr[i].suit
        if (suit === this.drawObj.flushSuit) {
          this.drawObj.fullFlushDrawRankSeq.push(this.allCardsArr[i].rank)
        }
      }
      // sort desc
      this.drawObj.fullFlushDrawRankSeq.sort((firstItem, secondItem) => secondItem - firstItem);
      // handle 14 (ace),6,5,4,3,2
      let indexStart = 0
      let indexEnd = this.drawObj.fullFlushDrawRankSeq.length
      if (this.analysisObj.rankObj.straightflush && this.drawObj.fullFlushDrawRankSeq.length >= 6 && this.drawObj.fullFlushDrawRankSeq[0] === 14) {
        indexStart = 1
      }

      this.drawObj.flushDrawRankSeqInPlay = [...this.drawObj.fullFlushDrawRankSeq.slice(indexStart, indexEnd)]
      this.analysisObj.boardObj.boardFlushRankArr = []
      for (let i in this.boardCardsArr) {
        if (this.boardCardsArr[i].suit === this.drawObj.flushSuit) {
          this.drawObj.numBoardSuitedContrib++
          this.analysisObj.boardObj.boardFlushRankArr.push(this.boardCardsArr[i].rank)
        }
      }
    }
  }

}

export class hopeFlushDraw extends baseFlushDraw {
  constructor(holeCardsArr, boardCardsArr, gameObj, analysisObj) {
    super(boardCardsArr, gameObj, analysisObj)
    this.holeCardsArr = holeCardsArr
    this.allCardsArr = [...holeCardsArr, ...boardCardsArr]
    this.gameObj = gameObj
    this.drawObj = {
      isFlushDraw: false,
      variety:'',
      outsArr: [],
      totalNumSuited:0,
      fullFlushDrawRankSeq:[],
      flushDrawRankSeqInPlay:[],
      flushSuit:'',
      numBoardSuitedContrib: 0,
      isHoleCardTopFlushCardPossible: false,
      numHoleCardsContrib: 0,
      isPlayingTheBoard: false,
      holeFlushCardsArr: [],
      topFlushHoleCardRank: 0,
      bothHoleCards: false,
      oneHoleCard: false
    }
  }
  
  getFlushDrawObj() {

    if (this.betterHandAlreadyHad()) {
      return this.drawObj
    }

    this.setVarietyPlus()
    this.setInfoWithHoleCards()
    this.setOuts()
    return this.drawObj
    
  }

  setInfoWithHoleCards() {

    // see how many hole cards are in the top 5
    // a player may be forced to play the board when there are 5 suited
    this.drawObj.holeCardFlushRankArr = []
    for (let i in this.holeCardsArr) {
      if (this.holeCardsArr[i].suit === this.drawObj.flushSuit) {
        this.drawObj.holeCardFlushRankArr.push(this.holeCardsArr[i].rank)
        this.drawObj.holeFlushCardsArr.push(this.holeCardsArr[i])
      }
    }
    let num = 0
    if (this.analysisObj.holeCardsObj.highcard.suit === this.drawObj.flushSuit) {
      num++
    }
    if (this.analysisObj.holeCardsObj.lowcard.suit === this.drawObj.flushSuit) {
      num++
    }
    this.drawObj.numHoleCardsContrib = num

    this.analysisObj.holeCardsObj.highcard.flushCardPosition = false
    this.analysisObj.holeCardsObj.lowcard.flushCardPosition = false
    let cardRankArr = []
    if (this.analysisObj.holeCardsObj.highcard.suit === this.drawObj.flushSuit
      && this.analysisObj.holeCardsObj.lowcard.suit === this.drawObj.flushSuit
    ) {
      // both holecards are suited, get position of highcard against board suited and low card suited
      cardRankArr = [...this.analysisObj.boardObj.boardFlushRankArr, this.analysisObj.holeCardsObj.lowcard.rank]
      let flushCardPosition = Util.getCardPositionAgainstCardRankArr(this.analysisObj.holeCardsObj.highcard.rank, cardRankArr)
      this.analysisObj.holeCardsObj.highcard.flushCardPosition = flushCardPosition
      // get position of lowcard suited
      cardRankArr = [...this.analysisObj.boardObj.boardFlushRankArr, this.analysisObj.holeCardsObj.highcard.rank]
      flushCardPosition = Util.getCardPositionAgainstCardRankArr(this.analysisObj.holeCardsObj.highcard.rank, cardRankArr)
      this.analysisObj.holeCardsObj.lowcard.flushCardPosition = flushCardPosition
    } else if (this.analysisObj.holeCardsObj.highcard.suit === this.drawObj.flushSuit ) {
      // only high holecard is suited, get position of highcard against board suited
      cardRankArr = [...this.analysisObj.boardObj.boardFlushRankArr]
      let flushCardPosition = Util.getCardPositionAgainstCardRankArr(this.analysisObj.holeCardsObj.highcard.rank, cardRankArr)
      this.analysisObj.holeCardsObj.highcard.flushCardPosition = flushCardPosition
    } else if (this.analysisObj.holeCardsObj.lowcard.suit === this.drawObj.flushSuit) {
      // only low holecard is suited, get position of lowcard against board suited
      cardRankArr = [...this.analysisObj.boardObj.boardFlushRankArr]
      let flushCardPosition = Util.getCardPositionAgainstCardRankArr(this.analysisObj.holeCardsObj.lowcard.rank, cardRankArr)
      this.analysisObj.holeCardsObj.lowcard.flushCardPosition = flushCardPosition
    }

    // is a flush hole card the top flush card in play? ie. position 1 to the board
    if (this.analysisObj.holeCardsObj.highcard.flushCardPosition === 1 || this.analysisObj.holeCardsObj.lowcard.flushCardPosition === 1) {
      this.drawObj.isHoleCardTopFlushCardInPlay = true
    }
    if (this.analysisObj.holeCardsObj.highcard.flushCardPosition <= 5 && this.analysisObj.holeCardsObj.lowcard.flushCardPosition <= 5) {
      this.drawObj.topFlushHoleCardPosition = this.analysisObj.holeCardsObj.highcard.flushCardPosition
      this.drawObj.topFlushHoleCardRank = this.analysisObj.holeCardsObj.highcard.rank
    } else if (this.analysisObj.holeCardsObj.highcard.flushCardPosition <= 5 && this.analysisObj.holeCardsObj.lowcard.flushCardPosition > 5) {
      this.drawObj.topFlushHoleCardPosition = this.analysisObj.holeCardsObj.highcard.flushCardPosition
      this.drawObj.topFlushHoleCardRank = this.analysisObj.holeCardsObj.highcard.rank
    } else if (this.analysisObj.holeCardsObj.highcard.flushCardPosition > 5 && this.analysisObj.holeCardsObj.lowcard.flushCardPosition <= 5) {
      this.drawObj.topFlushHoleCardPosition = this.analysisObj.holeCardsObj.lowcard.flushCardPosition
      this.drawObj.topFlushHoleCardRank = this.analysisObj.holeCardsObj.lowcard.rank
    } else {
      this.drawObj.topFlushHoleCardRank = false
      this.drawObj.topFlushHoleCardPosition = false
      this.drawObj.isPlayingTheBoard = true
    }

    // is flush hole card top flush card possible? ie. board has A,K,Q of hearts and hole card is J of hearts = top
    let topRank = 14
    let sortedCommRankArr = Object.entries(this.boardCardsArr)
    sortedCommRankArr.sort(
      (firstItem, secondItem) => {
        return firstItem[1].rank < secondItem[1].rank ? 1 : -1
      }
    );
    for(let i in sortedCommRankArr) {
      let commRank = sortedCommRankArr[i][1].rank
      let commSuit = sortedCommRankArr[i][1].suit
      if (commSuit !== this.drawObj.flushSuit) {
        continue
      }
      if (commRank >= topRank) {
        topRank--
      }
    }
    if ((this.holeCardsArr[0].suit === this.drawObj.flushSuit && this.holeCardsArr[0].rank >= topRank)
      || (this.holeCardsArr[1].suit === this.drawObj.flushSuit && this.holeCardsArr[1].rank >= topRank)) {
      this.drawObj.isHoleCardTopFlushCardPossible = true
    }

    if (this.drawObj.variety === 'made flush') {
      if (this.drawObj.isPlayingTheBoard) {
      /// ???
      }
    } else if (this.drawObj.numBoardSuitedContrib === 2 && this.drawObj.numHoleCardsContrib === 2) {
      this.drawObj.fourCardFlush = true
      this.drawObj.bothHoleCards = true
    } else if (this.drawObj.numBoardSuitedContrib === 3 && this.drawObj.numHoleCardsContrib === 1) {
      this.drawObj.fourCardFlush = true
      this.drawObj.bothHoleCards = false
      this.drawObj.oneHoleCard = true
    }
    // else if (this.drawObj.numBoardSuitedContrib === 2 && this.drawObj.numHoleCardsContrib === 1) {
    //   this.drawObj.threeCardFlush = true
    //   this.drawObj.bothHoleCards = false
    // } else if (this.drawObj.numBoardSuitedContrib === 1 && this.drawObj.numHoleCardsContrib === 2) {
    //   this.drawObj.threeCardFlush = true
    //   this.drawObj.bothHoleCards = true
    // }
    if (this.drawObj.fourCardFlush) {
      this.drawObj.isFlushDraw = true
    }
  }

}

export class threatFlushDraw extends baseFlushDraw {

  constructor(boardCardsArr, gameObj, analysisObj) {
    super(boardCardsArr, gameObj, analysisObj)
    this.allCardsArr = [...boardCardsArr]
    this.drawObj.twoCardFlush = false
  }

  betterHandAlreadyHad() {
    // don't bother assessing flush draw when higher hand is already had. need eval of flush though
    if (this.analysisObj.boardObj.rankObj.fullhouse || this.analysisObj.boardObj.rankObj.fourofakind
      || this.analysisObj.boardObj.straightflush
      //|| this.analysisObj.boardObj.rankObj.flush
    ) {
      return true
    }
    return false
  }

  getFlushDrawObj() {

    if (this.betterHandAlreadyHad()) {
      return this.drawObj
    }

    if (this.gameObj.street === 'river' && this.drawObj.variety === 'two flush') {
      this.drawObj.variety = ''
    } else if (this.drawObj.variety !== '' && this.drawObj.variety !== 'made flush') {
      this.drawObj.isFlushDraw = true
    }
    this.setVarietyPlus()
    // the threat only evaluates the board, so if the board has 4 suited, it's a four flush
    if (this.drawObj.variety === 'four flush') {
      this.drawObj.fourCardFlush = true
    } else if (this.drawObj.variety === 'three flush') {
      this.drawObj.threeCardFlush = true
    } else if (this.drawObj.variety === 'two flush') {
      this.drawObj.twoCardFlush = true
    }
    this.setOuts()
    return this.drawObj
  }

}