import React from 'react'
const Util = require('../helpers/util')

// THE TEXT IN THE GAME TABLE ("Flop: Human checks. Flop: Computer checks")

export default function ActionMsg({
  gameObj
}) {

  let computerActionText = Util.getActionTextByActor('computer', gameObj)
  let humanActionText = Util.getActionTextByActor('human', gameObj)
  let humanActionTextClass = "humanActionText"
  let computerActionTextClass = "computerActionText"
  if (gameObj.previousBetType === 'folded') {
    humanActionTextClass = "humanActionTextNotAnimated"
    computerActionTextClass = "computerActionTextNotAnimated"
  }
  let lastRow = Util.getLastRow(gameObj)
  if (lastRow.actor !== 'computer') {
    computerActionTextClass = "computerActionTextNotAnimated"
  }

  return (
    <React.Fragment key ={computerActionText + "_" + humanActionText}>
      <div className={humanActionTextClass} dangerouslySetInnerHTML={{ __html: humanActionText}} />
      <div className={computerActionTextClass} dangerouslySetInnerHTML={{ __html: computerActionText}} />
    </React.Fragment>
  )
}