import './App.css';
import Intro from "./components/intro";
//import Login from "./components/login";
import Main from "./components/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
const initGameObj = require("./helpers/gameObj").initGameObj;

function App() {

  // Set on or the other, don't set both at once
  const useWait = false // set useWait to true to halt code flow at the end of each player action
  const useComputerWait = 0 // halt before computer goes and require click on 'Go Computer' button

  function newTourn() {
    window.location.reload();
  }

  let gameObj = initGameObj(true, null, useWait)

  // dev = true to get hand values assessed by computer
  //let dev = false// gets passed to Main below
  //window.dev = dev// sets it globally

  let win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    windowWidth = win.innerWidth || docElem.clientWidth || body.clientWidth;//,
  //y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
  window.windowWidth = windowWidth
  console.log("windowWidth",windowWidth)

  return (
    <Router>
      <Switch>
        {/*<Route exact path="/login" component={Login} />*/}
        <Route exact path="/headsup" render={(props) =>
          <Main
              {...props}
              initGameObj = {initGameObj}
              // dev = {dev}
              newTourn = {newTourn}
              firstGameObj = {gameObj}
              initUseWait = {useWait}
              initUseComputerWait = {useComputerWait}
            />
        }
        />
        <Route exact path={["/", "/intro"]} component={Intro} />
      </Switch>
    </Router>

  );
}

export default App;

