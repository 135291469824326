import actionsbase from "./actionsbase"

export default class straight extends actionsbase {

  mngAllIn() {
    let actObj = {}
    if (this.threatFlushScore >= this.threatFlushScoreTh || this.handScore < this.adjStraightScoreTh) {
      actObj.action = 'fold'
    } else {
      actObj.action = 'call'
    }
    return actObj
  }

// START FLOP
//
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      // flopped the best
      // both holecards are required to be in play when flopping it
      if (this.handScore >= this.adjStraightScoreTh || this.threatFlushScore >= this.threatFlushScoreTh) {
        actObj = { action: 'allIn', betAmount: this.computerChips }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.handScore >= this.adjStraightScoreTh) {
        // both holecards are required to be in play when flopping it
        // flopped the best it was checked to human and they bet, call any
        actObj = { action: 'call' }
      }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      // both holecards are required to be in play when flopping it
      if (this.handScore >= this.adjStraightScoreTh || this.threatFlushScore >= this.threatFlushScoreTh) {
        actObj = {action:'raise', betAmount:this.computerChips}
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjStraightScoreTh  && this.threatFlushScore >= this.threatFlushScoreTh) {
        actObj = { action: 'allIn', betAmount: this.computerChips }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = {action: 'call' }
      }
    }
    return actObj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = {action: 'check' }
      if (this.handScore >= this.adjStraightScoreTh || this.threatFlushScore >= this.threatFlushScoreTh) {
        actObj = { action: 'allIn', betAmount: this.computerChips }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      actObj = {}
      if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore >= this.threatFlushScoreTh && this.threatFlushScore < 1000) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore === 1000 && this.hopeFlushScore >= this.hopeFlushScoreTh) {
        actObj = { action: 'call' }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'call'  }
      } else {
        actObj = {action:"fold"}
      }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = {action: 'check', betAmount: 0 }
      if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore >= this.threatFlushScoreTh) {
        actObj = { action: 'allIn', betAmount: this.computerChips }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      }
    } else { //canReraise || canCall
      if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'call'  }
      } else {
        actObj = {action:"fold", betAmount: 0}
      }
    }
    return actObj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.threatFlushScore === 1000) {
        actObj = {action:'check'}
      } else if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore < this.threatFlushScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount}
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold'}
      if (this.threatFlushScore === 1000) {
        actObj = { action: 'fold' }
      } else if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore < this.threatFlushScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj.action = 'call'
      } else if (this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.threatFlushScore === 1000) {
        actObj = {action:'check'}
      } else if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore < this.threatFlushScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.threatFlushScore === 1000) {
        actObj = { action: 'fold' }
      } else if (this.handScore >= this.adjStraightScoreTh && this.threatFlushScore < this.threatFlushScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjStraightScoreTh) {
        actObj = { action: 'call' }
      } else if (this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }
//
// END RIVER
  
  
}