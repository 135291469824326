import React from "react";
import { Link } from "react-router-dom";

const Intro = ({numHands}) => {
  return (
    <div className="App" key={numHands}>
      <div className='introTextCont'>
        Welcome to headsup Texas Hold 'em!
        {/*<br/><br/>*/}
        {/*Log in to record your scores. &nbsp;*/}
        {/*<Link*/}
        {/*    to={"/login"}*/}
        {/*    className="introButton"*/}
        {/*    >Login &raquo;</Link>*/}
        <br />
        <br />
        Ready to dive in? <Link to={"/headsup"} className="introButton">Play headsup against the computer &raquo;</Link>
        <br/><br/>

        Review the <a
          className="introButton"
          rel="noopener noreferrer"
          href='https://www.pokernews.com/poker-rules/texas-holdem.htm#2-texas-hold-em-rule'
          target='blank'
        >rules</a> or the <a
          className="introButton"
          rel="noopener noreferrer"
          href='https://www.pokernews.com/poker-rules/texas-holdem.htm#3-the-hands-in-texas'
          target='_blank'
        >hand ranks</a> of Texas Hold 'em if you need to.
        <br/>
        <br/>
        Written in Reactjs/Javascript and playable in your browser by <a
        className="introButton"
        href="https://full-stack-dev.net/blog/heads-up-poker.html"
        target="_blank"
        rel="noreferrer"
      >full-stack-dev.net</a>

        {/*<hr/>*/}
        {/*TODO*/}
        {/*<br/>*/}
        {/*Tie gameplay to an online identity.*/}
        {/*Save game to db and enable revisit later.<br />*/}
        {/*Highscores and badges.<br/>*/}
        {/*Enable configuration of game. (Hyper, turbo, etc)<br/>*/}



      </div>
    </div>
  )
}

export default Intro