import {getThreatAndHope} from './threatAndHope'

//const Util = require('../helpers/util')

export function getDecisionDataObj(gameObj, analysisObj) {

  let obj = {}
  obj.hopeObj = false
  obj.threatObj = false
  if (gameObj.street !== 'preflop' && analysisObj.drawObj) {
    [obj.hopeObj, obj.threatObj] = getThreatAndHope(analysisObj, gameObj)
  }
  obj.handEvalObj = {} //this gets set in betData
  return obj

}


