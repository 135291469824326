const Debug = require('../helpers/debug')
const Util = require('../helpers/util')

export function fold(tmpGameObj) {

  let playerBetTurn = tmpGameObj.playerBetTurn
  let handWinner
  let handWonAmount
  let streetHistoryObj = {actor: playerBetTurn, street: tmpGameObj.street, action: 'fold', betAmount:0}
  let streetHistoryArr = [...tmpGameObj.streetHistoryArr, {...streetHistoryObj}]
  // human folds
  if (playerBetTurn === 'human') {
    let loses = ++tmpGameObj.loses
    let computerChips = (tmpGameObj.computerChips + tmpGameObj.pot)
    handWinner = 'Computer'
    handWonAmount = Util.getHandWonAmount('computer', tmpGameObj)
    tmpGameObj = {
      ...tmpGameObj,
      loses: loses,
      computerChips: computerChips,
      handWinner: handWinner,
      handWonAmount: handWonAmount,
      readyForNextHand:true,
      previousBetType: "folded",
      previousBetAmount: 0,
      pot:0,
      streetHistoryArr:streetHistoryArr
    }
  } else {
    // computer folds
    let wins = ++tmpGameObj.wins
    let humanChips = (tmpGameObj.humanChips + tmpGameObj.pot)
    handWinner = 'Human'
    handWonAmount = Util.getHandWonAmount('human', tmpGameObj)
    tmpGameObj = {
      ...tmpGameObj,
      wins: wins,
      humanChips: humanChips,
      readyForNextHand:true,
      previousBetType: "folded",
      previousBetAmount: 0,
      handWinner: handWinner,
      handWonAmount: handWonAmount,
      pot:0,
      streetHistoryArr:streetHistoryArr
    }
  }
  return tmpGameObj
}

export function call(bet, tmpGameObj) {

  let playerBetTurn = tmpGameObj.playerBetTurn
  let pot = tmpGameObj.pot + bet
  let humanChips = tmpGameObj.humanChips
  let computerChips = tmpGameObj.computerChips
  let betType = 'call'
  let isHumanHandActionDone = tmpGameObj.isHumanHandActionDone
  let isComputerHandActionDone = tmpGameObj.isComputerHandActionDone

  if (playerBetTurn === "human") {
    if (bet >= humanChips) {
      bet = humanChips
      humanChips = 0
      betType = 'allIn'
    } else {
      humanChips = humanChips - bet
    }
  } else {
    if (bet >= computerChips) {
      bet = computerChips
      computerChips = 0
      betType = 'allIn'
    } else {
      computerChips = (computerChips - bet)
    }
  }
  // Big stack can bet and short stack can call, making short stack's call an 'allIn'
  // Short stack can go all in and big stack can call, making short stack 'allIn' and big stack XCalledAllIn
  if (humanChips === 0 || computerChips === 0) {
    isComputerHandActionDone = true
    isHumanHandActionDone = true
  }
  tmpGameObj = {...tmpGameObj,
    pot: pot,
    humanChips: humanChips,
    computerChips: computerChips,
    isHumanHandActionDone:isHumanHandActionDone,
    isComputerHandActionDone:isComputerHandActionDone,
    previousBetType:betType,
  }
  let streetHistoryObj = {actor:playerBetTurn, street: tmpGameObj.street, action: betType, betAmount: bet}
  tmpGameObj.streetHistoryArr = [...tmpGameObj.streetHistoryArr, {...streetHistoryObj}]
  return tmpGameObj

}

export function check(tmpGameObj, useWait, useComputerWait) {

  let playerBetTurn = tmpGameObj.playerBetTurn
  let doSetNextStreet = false
  let doForwardToNext = false
  let streetHistoryObj = {actor: playerBetTurn, street: tmpGameObj.street, action: 'check', betAmount:0}
  let tmpStreetHistoryArr = [...tmpGameObj.streetHistoryArr, {...streetHistoryObj}]
  // preflop, smallBlindPlayer cannot check
  if (playerBetTurn === tmpGameObj.bigBlindPlayer && tmpGameObj.street === "preflop") {
    tmpGameObj = {...tmpGameObj,
      previousBetType: "check",
      streetHistoryArr: tmpStreetHistoryArr
    }
    doSetNextStreet = true
  } else if (playerBetTurn === tmpGameObj.smallBlindPlayer && tmpGameObj.street !== "preflop") {
    // post preflop, small blind player acts second and if they're checking, action is finished for that street
    tmpGameObj = {...tmpGameObj,
      previousBetType: "check",
      streetHistoryArr: tmpStreetHistoryArr
    }
    doSetNextStreet = true
  } else {
    doForwardToNext = true
    // big blind went first and checked, action to small blind for the street
    let tmpPlayerBetTurn = Util.getPlayerBetTurn(playerBetTurn, useWait, useComputerWait)
    tmpGameObj = {...tmpGameObj,
      previousBetType: "check",
      playerBetTurn: tmpPlayerBetTurn,
      displayBetAmount:tmpGameObj.bigBlind,
      streetHistoryArr: tmpStreetHistoryArr
    }

  }

  let arr = [doSetNextStreet, doForwardToNext, tmpGameObj]
  return arr

}

export function smallBlindCall(tmpGameObj) {

  let isHumanHandActionDone = tmpGameObj.isHumanHandActionDone
  let isComputerHandActionDone = tmpGameObj.isComputerHandActionDone
  let playerBetTurn = tmpGameObj.playerBetTurn
  let bet = tmpGameObj.smallBlind
  let pot = tmpGameObj.pot
  let humanChips = tmpGameObj.humanChips
  let computerChips = tmpGameObj.computerChips
  let previousBetAmount = tmpGameObj.smallBlind

  if (playerBetTurn === "human") {
    if (bet > humanChips) {
      bet = humanChips
      humanChips = 0
    } else {
      humanChips = humanChips - bet
    }
  } else if (playerBetTurn === "computer") {
    if (bet > computerChips) {
      bet = computerChips
      computerChips = 0
    } else {
      computerChips = computerChips - bet
    }
  }

  if (humanChips === 0) {
    //"Human allIn preflop"
    isHumanHandActionDone = true
    isComputerHandActionDone = true
  }
  if (computerChips === 0) {
    //"Computer allIn preflop"
    isHumanHandActionDone = true
    isComputerHandActionDone = true
  }
  let streetHistoryObj = {actor:playerBetTurn, street: tmpGameObj.street, action: 'smallBlindCall', betAmount: bet}
  tmpGameObj = {...tmpGameObj,
    pot: (pot + bet),
    humanChips: humanChips,
    computerChips: computerChips,
    previousBetType: "smallBlindCall",
    previousBetAmount: previousBetAmount,
    isComputerHandActionDone: isComputerHandActionDone,
    isHumanHandActionDone: isHumanHandActionDone,
    streetHistoryArr: [...tmpGameObj.streetHistoryArr, {...streetHistoryObj}]
  }

  return tmpGameObj

}

export function raise(betType, bet, tmpGameObj, useWait, useComputerWait) {

  let debugFunc = 0
  if (debugFunc){
    Debug.log(tmpGameObj, 'computer', "raise() betType", betType, "bet", bet, "streetHistoryArr", tmpGameObj.streetHistoryArr)
  }

  let playerBetTurn = tmpGameObj.playerBetTurn
  let humanChips = tmpGameObj.humanChips
  let computerChips = tmpGameObj.computerChips
  let pot = tmpGameObj.pot
  let isHumanHandActionDone = false
  let isComputerHandActionDone = false
  if (tmpGameObj.street === 'preflop' && playerBetTurn === tmpGameObj.smallBlindPlayer && tmpGameObj.previousBetAmount === 0
    && playerBetTurn === 'human' && humanChips - (bet + tmpGameObj.smallBlind) === 0
  ) {
    betType = 'allIn'
    if (debugFunc)console.log("raise() A")
  } else if (tmpGameObj.street === 'preflop' && playerBetTurn === tmpGameObj.smallBlindPlayer && tmpGameObj.previousBetAmount === 0
    && playerBetTurn === 'computer' && computerChips - (bet + tmpGameObj.smallBlind) === 0
  ) {
    betType = 'allIn'
    if (debugFunc)console.log("raise() B")
  } else if (playerBetTurn === 'human' && humanChips - bet === 0) {
    betType = 'allIn'
    if (debugFunc)console.log("raise() C")
  } else if (playerBetTurn === 'computer' && computerChips - bet === 0) {
    betType = 'allIn'
    if (debugFunc)console.log("raise() D")
  } else if (playerBetTurn === 'human' && computerChips - bet === 0) {
    // if human bets the most computer can possibly bet, human hand action is done
    isHumanHandActionDone = true
    if (debugFunc)console.log("raise() E")
  } else if (playerBetTurn === 'computer' && humanChips - bet === 0) {
    // if computer bets the most human can possibly bet, computer hand action is done
    isComputerHandActionDone = true
    if (debugFunc)console.log("raise() F")
  }
  if (betType === 'allIn') {
    if (tmpGameObj.playerBetTurn === "human") {
      isHumanHandActionDone = true
      if (tmpGameObj.isComputerHandActionDone) {
        isComputerHandActionDone = true
      }
      pot = pot + bet
      humanChips = humanChips - bet
      if (debugFunc)console.log("raise() G")
    } else {
      isComputerHandActionDone = true
      if (tmpGameObj.isHumanHandActionDone) {
        isHumanHandActionDone = true
      }
      pot = pot + bet
      computerChips = computerChips - bet
      if (debugFunc)console.log("raise() H")
    }
  } else if (betType === 'pot' && playerBetTurn === 'human') {
    humanChips = humanChips - bet
    pot = (pot + bet)
    if (debugFunc)console.log("raise() I")
  } else if (betType === 'pot' && playerBetTurn === 'computer') {
    computerChips = computerChips - bet
    pot = (pot + bet)
    if (debugFunc)console.log("raise() J")
  } else if (playerBetTurn === "human") {
    humanChips = (humanChips - bet)
    pot = (pot + bet)
    if (debugFunc)console.log("raise() K: " + bet)
  } else {
    computerChips = (computerChips - bet)
    pot = (pot + bet)
    if (debugFunc)console.log("raise() L")
  }
  if (betType === 'pot') {
    betType = 'raise'
  }
  // add smallBlindCall to pot as it was removed from the bet above
  if (tmpGameObj.street === 'preflop' && playerBetTurn === tmpGameObj.smallBlindPlayer
    && tmpGameObj.previousBetAmount === 0 && tmpGameObj.previousBetType === 'smallBlind'
  ) {
    pot+=tmpGameObj.smallBlind
    if (playerBetTurn === 'computer') {
      computerChips-=tmpGameObj.smallBlind
      if (debugFunc)console.log("raise() M")
    } else {
      humanChips-=tmpGameObj.smallBlind
      if (debugFunc)console.log("raise() N")
    }
  }

  let tmpStreetHistoryArr = []
  let streetHistoryRaiseObj = {}
  let streetHistoryCallObj = {}
  let lastRow = Util.getLastRowForStreet(tmpGameObj)
  // if actor is re-raising, get the call amount of their raise (eg. c bets 40, h raises 80 - a call of 40 and a raise of 40)
  // and set it in history as such
  if (lastRow.action === 'raise' && lastRow.actor !== playerBetTurn) {
    if (debugFunc)console.log("tmpStreetHistoryArr", tmpStreetHistoryArr)
    streetHistoryCallObj = {actor:playerBetTurn, street: tmpGameObj.street, action: 'call', betAmount: lastRow.betAmount}
    if (debugFunc)console.log("streetHistoryCallObj", streetHistoryCallObj)
    if (bet - lastRow.betAmount > 0) {
      streetHistoryRaiseObj = {actor: playerBetTurn,street: tmpGameObj.street,action: betType,betAmount: (bet - lastRow.betAmount)}
      if (debugFunc)console.log("streetHistoryRaiseObj", streetHistoryRaiseObj)
      //tmpStreetHistoryArr = [...tmpStreetHistoryArr, streetHistoryCallObj]
      tmpStreetHistoryArr.push({...streetHistoryCallObj})
      if (debugFunc)console.log("raise() A tmpStreetHistoryArr", tmpStreetHistoryArr)
      //tmpStreetHistoryArr = [...tmpStreetHistoryArr, streetHistoryRaiseObj]
      tmpStreetHistoryArr.push({...streetHistoryRaiseObj})
      if (debugFunc)console.log("raise() B tmpStreetHistoryArr", tmpStreetHistoryArr)
      if (debugFunc)console.log(tmpGameObj.playerBetTurn, "X raise() tmpStreetHistoryArr", tmpStreetHistoryArr)
    } else {
      // player ended up going allIn in the 'call' portion of the bet
      isComputerHandActionDone = true
      isHumanHandActionDone = true
      tmpStreetHistoryArr.push({...streetHistoryCallObj})
      if (debugFunc)console.log(tmpGameObj.playerBetTurn, "Y raise() tmpStreetHistoryArr", tmpStreetHistoryArr)
    }
  } else {
    let streetHistoryRaiseObj = {actor:playerBetTurn, street: tmpGameObj.street, action: betType, betAmount: bet}
    // must use tmpStreetHistoryArr passed in as streetHistoryFullArr isn't readable from state
    // after smallBlindCall()
    tmpStreetHistoryArr.push({...streetHistoryRaiseObj})
    if (debugFunc)console.log(tmpGameObj.playerBetTurn, "Z raise() tmpStreetHistoryArr", tmpStreetHistoryArr)
  }
  let previousRaiseAmount
  if (lastRow.action !== 'smallBlindCall') {
    previousRaiseAmount = lastRow.betAmount
  } else {
    previousRaiseAmount = bet
  }

  let tmpPlayerBetTurn = Util.getPlayerBetTurn(playerBetTurn, useWait, useComputerWait)

  tmpGameObj = {...tmpGameObj,
    previousBetAmount:bet,
    previousBetType:betType,
    humanChips:humanChips,
    computerChips:computerChips,
    pot:pot,
    playerBetTurn: tmpPlayerBetTurn,
    previousRaiseAmount: previousRaiseAmount,
    isHumanHandActionDone:isHumanHandActionDone,
    isComputerHandActionDone:isComputerHandActionDone
  }
  tmpGameObj.streetHistoryArr = [...tmpGameObj.streetHistoryArr, ...tmpStreetHistoryArr]
  return tmpGameObj

}


