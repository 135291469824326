import actionsbase from "./actionsbase"

const Util = require("../util")

export default class preflop extends actionsbase {

  constructor(gameObj, analysisObj, ddObj, isFirstToAct) {
    super(gameObj, analysisObj, ddObj, isFirstToAct)
    this.autoCall = false
    if (this.humanCategoryIndex >= Util.NOT_SERIOUS && this.minCallAmount <= (this.gameObj.bigBlind * 3)) {
      this.autoCall = true
    } else if (this.humanCategoryIndex >= Util.LOOSE && this.minCallAmount <= (this.gameObj.bigBlind * 2)) {
      this.autoCall = true
    } else if (this.humanCategoryIndex <= Util.TIGHT && this.minCallAmount <= this.gameObj.bigBlind) {
      this.autoCall = true
    }
  }
  /*
  Out of 1000 deals, this is the typical frequency of holecard scores
  /holecard score : num occured : frequency
  0  : 365 : 0.36
  45 : 181 : 0.18 - top 80 - 107 holecards J,5 up to J,8
  50 : 232 : 0.23 - top 40 - 80 holecards J,8 up to K,9
  57 : 135 : 0.14 - top 20 - 40 holecards K,9 up to K,J
  62 : 100  : 0.1 - top 1 - 20 holecards K,J up to A,A
   */
  /*
  0  379 0.38
  45 128 0.13
  50 111 0.11
  53 146 0.15
  57 134 0.13
  62 103 0.10
   */

  // holecards with a score of 57 or greater occur 23% of the time (223/1000) so 2 out of 10 hands should hit that range
  // holecards with a score of 53 or greater occur 40% of the time (400/1000) so 4 out of 10 hands should hit that range

  // Starting with 50 big blinds
  // 5  folds in a row = 45/50 = .9
  // 10 folds in a row = 40/50 = .8
  // 15 folds in a row = 35/50 = .7
  // 20 folds in a row = 30/50 = .6
  // 25 folds in a row = 25/50 = .5

  // create an array of holecard scores based on human's category and holecards' ranks. eg. human is 'tight' = 100, so holecard score
  // of 68 (pocket 88 up to and including pocket AA) or above is best, holecards' rank of 62 (pocket 66 up to and including AKs)
  getHoleCardsDataArr() {
    let holeCardsBestScoresArr = this.getHoleCardsScoreArr(this.humanCategoryIndex)
    let bbPercRemainingArr
    if (this.humanCategoryIndex === 100 || this.humanCategoryIndex === 0) {
      bbPercRemainingArr = [1.1,.95, .7, .6 , .5]
    } else if (this.humanCategoryIndex === 400) {

      bbPercRemainingArr = [.9, .8, .7, .6, .4]
    } else {

      // if (this.minCallPercOfStack <= .33) {
      //   holeCardsBestScoresArr = [56,54,52,50,48]
      // } else {
      //   holeCardsBestScoresArr = [62,57,50,45,40]
      // }

      bbPercRemainingArr = [1.6, .8, .7, .6, .4]

    }
    return [holeCardsBestScoresArr, bbPercRemainingArr]
  }

  getHoleCardsScoreArr() {
    let holeCardsBestScoresArr
    if (this.humanCategoryIndex === 100 || this.humanCategoryIndex === 0) {
      holeCardsBestScoresArr = [68, 62, 59, 55, 50]
    } else if (this.humanCategoryIndex === 400) {
      holeCardsBestScoresArr = [62,57,55,50,50]
    } else {

      // if (this.minCallPercOfStack <= .33) {
      //   holeCardsBestScoresArr = [56,54,52,50,48]
      // } else {
      //   holeCardsBestScoresArr = [62,57,50,45,40]
      // }

      holeCardsBestScoresArr =  [57, 53, 52, 51, 50]

    }

    return holeCardsBestScoresArr

  }

  mngAllIn() {
    let obj = {}
    let holeCardsScore = this.holeCardsObj.score
    let [holeCardsBestScoresArr, bbPercRemainingArr] = this.getHoleCardsDataArr(this.humanCategoryIndex)
    let bestScore
    let bbPercRemaining = this.bbObj.numBBRemaining / this.bbObj.numBBStarting
    // based on the number of big blinds remaining, set the best holecard score required to go all in on
    let autoCall = false
    if (bbPercRemaining >= bbPercRemainingArr[0]) {
      bestScore = holeCardsBestScoresArr[0]
    } else if (bbPercRemaining >= bbPercRemainingArr[1]) {
      bestScore = holeCardsBestScoresArr[1]
    } else if (bbPercRemaining >= bbPercRemainingArr[2]) {
      bestScore = holeCardsBestScoresArr[2]
    } else if (bbPercRemaining >= bbPercRemainingArr[3]) {
      bestScore = holeCardsBestScoresArr[3]
    } else if (bbPercRemaining >= bbPercRemainingArr[4]) {
      bestScore = holeCardsBestScoresArr[4]
    } else {
      // if under n of bb remaining, play any two cards
      bestScore = holeCardsScore
      autoCall = true
    }

    if (holeCardsScore >= bestScore || autoCall) {
      obj.action = 'call'
    } else {
      obj.action = 'fold'
    }

    //console.log(obj.action, "holeCardsScore", holeCardsScore,"must be >=: ", bestScore, "bbPercRemaining", bbPercRemaining, "this.minCallPercOfStack", this.minCallPercOfStack, "computer", this.computerChips, "human", this.humanChips, "pot", this.pot)
    return obj
  }

  preflopFirstToAct() {
    let obj = {}
    let holeCardsScore = this.holeCardsObj.score
    let holeCardsScoreArr = this.getHoleCardsScoreArr()
    let bestRaiseAmount = this.bestRaiseAmount
    if (this.canRaise || this.canCheck || this.canSmallBlindCall) {
      if (holeCardsScore >= holeCardsScoreArr[0]) {
        obj.action = 'raise'
        obj.betAmount = bestRaiseAmount
      } else if (holeCardsScore > holeCardsScoreArr[1]) {
        obj.action = 'raise'
        obj.betAmount = this.minRaiseAmount
      } else if (holeCardsScore >= holeCardsScoreArr[2]) {
        obj.action = 'smallBlindCall'
      } else {
        obj.action = 'smallBlindCall'
        if ((this.holeCardsObj.suited || this.holeCardsObj.connected || this.holeCardsObj.paired)) {
          obj.action = 'smallBlindCall'
        }
      }
    } else {
      if (this.alwaysAllIn) {
        if (holeCardsScore >= holeCardsScoreArr[0]) {
          obj.action = 'allIn'
        } else if (holeCardsScore > holeCardsScoreArr[1]) {
          obj.action = 'allIn'
        } else if (holeCardsScore >= holeCardsScoreArr[2]) {
          obj.action = 'call'
        } else if (this.autoCall) {
          obj.action = 'call'
        } else {
          obj.action = 'fold'
        }
      } else if (holeCardsScore >= 74) {
        // they've re-raised against computers pocket 10's or better
        if (this.goAllIn) {
          obj.action = 'allIn'
        } else {
          obj.action = 'raise'
          obj.betAmount = this.minRaiseAmount
        }
      } else if (holeCardsScore >= holeCardsScoreArr[0]) {
        obj.action = 'call'
      } else if (holeCardsScore > holeCardsScoreArr[1]) {
        obj.action = 'call'
      } else if (holeCardsScore >= holeCardsScoreArr[2]) {
        obj.action = 'call'
      } else {
        obj.action = 'fold'
        if (this.autoCall) {
          obj.action = 'call'
        }
      }
    }
    return obj
  }

  preflopNotFirstToAct() {
    let obj = {}
    let holeCardsScore = this.holeCardsObj.score
    let holeCardsScoreArr = this.getHoleCardsScoreArr()
    let bestRaiseAmount = this.bestRaiseAmount
    if (this.canRaise || this.canCheck) {
      if (holeCardsScore >= 74) {
        obj.action = 'raise'
        obj.betAmount = bestRaiseAmount
      } else if (holeCardsScore >= holeCardsScoreArr[0]) {
        obj.action = 'raise'
        obj.betAmount = this.minRaiseAmount
      } else if (holeCardsScore > holeCardsScoreArr[1]) {
        obj.action = 'raise'
        obj.betAmount = this.minRaiseAmount
      } else if (holeCardsScore >= holeCardsScoreArr[2]) {
        obj.action = 'check'
      } else {
        obj.action = 'check'
        // if (this.holeCardsObj.suited || this.holeCardsObj.connected || this.holeCardsObj.paired) {
        //   obj.action = 'call'
        // }
      }
    } else {
      if (this.alwaysAllIn) {
        if (holeCardsScore >= holeCardsScoreArr[0]) {
          obj.action = 'allIn'
        } else if (holeCardsScore > holeCardsScoreArr[1]) {
          obj.action = 'allIn'
        } else if (holeCardsScore >= holeCardsScoreArr[2]) {
          obj.action = 'call'
        } else if (this.autoCall) {
          obj.action = 'call'
        } else {
          obj.action = 'fold'
        }
      } else if (holeCardsScore >= 74) {
        // they've re-raised against computers pocket 10's or better
        if (this.goAllIn) {
          obj.action = 'allIn'
        } else {
          obj.action = 'raise'
          obj.betAmount = bestRaiseAmount
        }
      } else if (holeCardsScore >= holeCardsScoreArr[0]) {
        obj.action = 'call'
      } else if (holeCardsScore > holeCardsScoreArr[1]) {
        obj.action = 'call'
      } else if (holeCardsScore >= holeCardsScoreArr[2]) {
        obj.action = 'call'
      } else if (holeCardsScore >= 50) {
        obj = {action:'call'}
      } else {
        obj.action = 'fold'
        if (this.autoCall) {
          obj.action = 'call'
        }
      }
    }
    return obj
  }

}

