import actionsbase from "./actionsbase"

export default class fourofakind extends actionsbase {

  mngAllIn() {
    let obj = {}
    if (this.handScore === 1000) {
      obj = { action: 'call' }
    } else {
      obj = this.dangerDecision()
    }
    return obj
  }

  dangerDecision () {
    let obj = {}
    if (this.handScore === 600 && this.computerMinCallAmountPercOfStack <= .1
      && ((this.humanHandAggressionScore < 400 && this.humanCategoryIndex <= 100) || (this.humanCategoryIndex >= 400))
    ) {
      // board has 4 of a kind, comp holecard king
      // small bet against either tight human with mild hand aggression or a loose player
      obj = {action: 'call' }
    } else if (this.handScore === 500 && this.computerMinCallAmountPercOfStack <= .08 && this.humanHandAggressionScore < 400
      && ((this.humanHandAggressionScore < 400 && this.humanCategoryIndex <= 100) || (this.humanCategoryIndex >= 400))
    ) {
      // board has 4 of a kind, comp holecard Q
      // small bet against either tight human with mild hand aggression or a loose player
      obj = {action: 'call' }
    } else {
      obj.action = 'fold'
    }
    return obj
  }

// START FLOP
//
// Four of a kind on the flop cannot be from playing the board, so computer has it
  flopFirstToAct() {
    let obj = {}
    if (this.canRaise || this.canCheck) {
      obj = { action: 'check' }
    } else {
      if (this.handScore === 1000 || this.safeCall) {
        obj = { action: 'call' }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }

  flopNotFirstToAct() {
    let obj = {}
    if (this.canRaise || this.canCheck) {
      obj = { action: 'check' }
    } else {
      if (this.handScore === 1000 || this.safeCall) {
        obj = { action: 'call' }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let obj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore === 1000) {
        if (this.threatDrawDeadFlushScore >= this.threatFlushScoreTh || this.threatDrawDeadStrtScore >= this.threatStraightScoreTh) {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount }
        } else {
          obj = { action: "raise", betAmount: this.minRaiseAmount}
        }
      } else {
        obj = {action:'check'}
      }
    } else {
      if (this.handScore === 1000 || this.safeCall) {
        obj = { action: 'call' }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }

  turnNotFirstToAct() {

    let obj = {}
    if (this.canRaise || this.canCheck) {
      obj = { action: 'raise', betAmount: this.minRaiseAmount }
    } else {
      if (this.handScore === 1000 || this.safeCall) {
        obj = { action: 'call' }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let obj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore === 1000) {
        if (this.threatDrawDeadFlushScore >= this.threatFlushScoreTh || this.threatDrawDeadStrtScore >= this.threatStraightScoreTh) {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount * 2 }
        } else {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      } else {
        obj.action = 'check'
      }
    } else {
      if (this.handScore === 1000) {
        obj = { action: 'allIn' }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }

  riverNotFirstToAct() {
    let obj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore === 1000) {
        if (this.threatDrawDeadFlushScore >= this.threatFlushScoreTh || this.threatDrawDeadStrtScore >= this.threatStraightScoreTh) {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount * 2 }
        } else {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      } else {
        obj.action = 'check'
      }
    } else {
      if (this.handScore === 1000) {
        if (this.threatDrawDeadFlushScore >= this.threatFlushScoreTh || this.threatDrawDeadStrtScore >= this.threatStraightScoreTh) {
          obj = { action: 'allIn' }
        } else {
          obj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      } else {
        obj = this.dangerDecision()
      }
    }
    return obj
  }
//
// END RIVER

}