import actionsbase from "./actionsbase"

export default class twopair extends actionsbase {

  mngAllIn () {
    let actObj = {}
    if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj.action = 'call'
    } else {
      actObj.action = 'fold'
    }
    return actObj
  }

// START FLOP
//
  flopFirstToAct () {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj = { action: 'call' }
      }
    }
    return actObj
  }

  flopNotFirstToAct () {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn' }
      } else {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj = { action: 'call' }
      }
    }
    return actObj
  }

//
// END FLOP

// START TURN
//
  turnFirstToAct () {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj.action = 'call'
      } else {
        actObj = { action: 'fold' }
      }
    }
    return actObj
  }

  turnNotFirstToAct () {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj.action = 'call'
      } else {
        actObj = { action: 'fold' }
      }
    }
    return actObj
  }

//
// END TURN

// START RIVER
//
  riverFirstToAct () {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'check' }
      } else if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj = { action: 'check' }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'call' }
      } else if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj = { action: "call" }
      }
    }
    return actObj
  }

  riverNotFirstToAct () {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjTwopairScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'check' }
      } else if (this.handScore >= this.adjTwopairScoreTh) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      }
    } else { //canReraise || canCall
      if (this.handScore >= this.adjTwopairScoreTh && !this.threatDrawThCrossed) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjTwopairScoreTh || this.safeCall) {
        actObj = { action: "call" }
      } else {
        actObj = { action: 'fold' }
      }
    }
    return actObj
  }

//
// END RIVER

}