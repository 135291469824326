const Util = require('../helpers/util')

export function getThreatObj(analysisObj, gameObj) {

  let obj = {}
  let threatFlushObj = analysisObj.drawObj.threatFlushObj
  let threatStraightObj = analysisObj.drawObj.threatStraightObj
  let actionForHandArr = Util.getActionForHand(gameObj)

  // threat flush draw
  // 500 or above is strong - two suited cards on the board on the flop or turn
  // 700 or above is strongest - three suited cards on the board
  // 1000 is 4 card suited on board
  let flushScore = 0
  if (threatFlushObj.twoCardFlush && gameObj.street === 'river') {
    flushScore = 0
  } else if (threatFlushObj.fourCardFlush) {
    flushScore = 1000
  } else if (threatFlushObj.threeCardFlush && gameObj.street === 'flop') {
    flushScore = 900
  } else if (threatFlushObj.threeCardFlush && gameObj.street === 'turn') {
    flushScore = 800
  } else if (threatFlushObj.threeCardFlush && gameObj.street === 'river') {
    flushScore = 700
  } else if (threatFlushObj.twoCardFlush && gameObj.street === 'flop') {
    flushScore = 600
  } else if (threatFlushObj.twoCardFlush && gameObj.street === 'turn') {
    flushScore = 400
  }
  obj.flushScore = flushScore
  let humanLimpedIn = actionForHandArr['human'].limpedIn

  if (gameObj.street === 'turn') {
    //let tmp = 1
  }
  let straightScore = 0
  if (threatStraightObj.isSingleCardDraw && threatStraightObj.isOpenEnded && gameObj.street === 'flop' ) {
    straightScore = 1000
  } else if (threatStraightObj.isSingleCardDraw && threatStraightObj.isOpenEnded && gameObj.street === 'turn' ) {
    straightScore = 900
  } else if (threatStraightObj.isSingleCardDraw && threatStraightObj.isOpenEnded && gameObj.street === 'river' ) {
    straightScore = 800
  } else if (threatStraightObj.isSingleCardDraw) {
    straightScore = 700
  } else if (threatStraightObj.isTwoCardDraw && gameObj.street === 'flop' && humanLimpedIn) {
    straightScore = 650
  } else if (threatStraightObj.isTwoCardDraw && gameObj.street === 'flop') {
    straightScore = 600
  } else if (threatStraightObj.isTwoCardDraw && gameObj.street === 'turn' && threatStraightObj.isOpenEnded) {
    straightScore = 550
  } else if (threatStraightObj.isTwoCardDraw && gameObj.street === 'turn') {
    straightScore = 500
  } else if (threatStraightObj.isTwoCardDraw && gameObj.street === 'river') {
    straightScore = 400
  }
  obj.straightScore = straightScore
  return obj

}

export function getHopeObj(analysisObj, gameObj) {

  let obj = {
    flushScore:0,
    straightScore:0
  }
  if (gameObj.street === 'river') {
    return obj
  }
  if (gameObj.street === 'turn') {
    //let tmp = 1
  }
  let flushObj = analysisObj.drawObj.flushObj
  let straightObj = analysisObj.drawObj.straightObj
  //let straightFlushObj = analysisObj.drawObj.straightFlushObj

  // hope flush draw
  // 500 or above is good
  // 700 or above is drawing to the nuts
  let flushScore = 0
  if (!flushObj.isPlayingTheBoard && !analysisObj.rankObj.flush) {
    if (flushObj.fourCardFlush && flushObj.bothHoleCards && flushObj.holeCardsAreTopPossible) {
      // As,Ks - 2s,7s
      // As,Js - Qs,10s
      flushScore = 1000
    } else if (flushObj.fourCardFlush && flushObj.bothHoleCards && flushObj.isHoleCardTopFlushCardPossible) {
      // Qs,2s - As,Ks
      // As,2s - 10s,4s
      flushScore = 1000
    } else if (flushObj.fourCardFlush && flushObj.oneHoleCard && flushObj.isHoleCardTopFlushCardPossible) {
      // Qs,2o - As,Ks
      // As,2o - 7s,4s
      flushScore = 1000
    } else if (flushObj.fourCardFlush && flushObj.isHoleCardTopFlushCardInPlay) {
      // 10s,4s - 2s, 7s
      flushScore = 700
    } else if (flushObj.fourCardFlush && flushObj.bothHoleCards) {
      // 10s,7s - Js,4s
      flushScore = 700
    } else if (flushObj.fourCardFlush && flushObj.oneHoleCard) {
      // 10s,9o - Ks,4s,Qs
      flushScore = 500
    }
  }
if (gameObj.street === 'turn') {
  //let tmp = 1
}
  let straightScore = 0
  if (straightObj.isSingleCardDraw && !straightObj.isPlayingTheBoard && !analysisObj.rankObj.straight) {
    if (straightObj.bothHoleCards && straightObj.holeCardsAreTopPossible) {
      // A,K - Q,J,10 || Q,J - A,K,10
      // isn't open ended, but is top
      straightScore = 700
    } else if (straightObj.bothHoleCards && straightObj.holeCardsAreTop && straightObj.isOpenEnded) {
      // 10,9 - 8,7 || K,Q - J,10
      straightScore = 1000
    } else if (straightObj.holeCardsAreTopAndMiddle && straightObj.isOpenEnded) {
      straightScore = 900
    } else if (straightObj.oneHoleCard && straightObj.holeCardIsTopEnd && straightObj.isOpenEnded) {
      // 10 - 9,8,7
      straightScore = 800
    } else if (straightObj.holeCardsAreTopAndBottom && straightObj.isOpenEnded) {
      // 10,7 - 9,8 -
      straightScore = 700
    } else if (straightObj.holeCardsAreMiddle && straightObj.isOpenEnded) {
      // 9,8 - 10,7
      straightScore = 700
    } else if (straightObj.holeCardsAreBottom && straightObj.isOpenEnded) {
      // 8,7 - 10,9
      straightScore = 700
    } else if (straightObj.holeCardIsMiddle && straightObj.isOpenEnded) {
      // 8 - 10,9,7
      straightScore = 700
  // NOT OPEN ENDED BELOW
    } else if (straightObj.oneHoleCard && straightObj.holeCardIsTopPossible) {
      // A - K, Q,J,10 || Q - A,K,J,10
      // isn't open ended, but is top
      straightScore = 600
    } else if (straightObj.holeCardsAreTopAndBottom) {
      // 10,6 - 9,7 -
      straightScore = 600
    } else if (straightObj.bothHoleCards && straightObj.holeCardsAreTop) {
      // 10,9 - 8,6
      straightScore = 600
    } else if (straightObj.bothHoleCards && (straightObj.holeCardsAreMiddle || straightObj.holeCardsAreMiddleAndBottom)) {
      // Q,J - K,9,2
      straightScore = 600
    } else if (straightObj.bothHoleCards && straightObj.holeCardsAreBottom) {
      // 4,3 - 7,6,10
      straightScore = 450
    } else if (straightObj.oneHoleCard && straightObj.holeCardIsTopEnd) {
      // 10 - 9,7,6
      straightScore = 450
    } else if (straightObj.holeCardIsBottom && straightObj.isOpenEnded) {
      // 400 and below = draw card might come, but there's a chance it will be counterfeited
      // 6 - 9,8,7
      straightScore = 400
    } else if (straightObj.holeCardsAreTopAndMiddle || straightObj.holeCardsAreBottomAndMiddle) {
      // 5,2 - 9,3,A || 7,4 - 8,5,6
      straightScore = 300
    } else if (straightObj.holeCardIsMiddle) {
      // 7,2 = 8,6,5
      straightScore = 250
    } else if (straightObj.holeCardIsBottom) {
      // 6 - 10,9,8
      straightScore = 200
    }

  }

  obj.straightScore = straightScore
  obj.flushScore = flushScore

  return obj

}

export function getThreatAndHope(analysisObj, gameObj) {

  let hopeObj = getHopeObj(analysisObj, gameObj)
  let threatObj = getThreatObj(analysisObj, gameObj)

  threatObj.pairedBoardScore = 0
  if (analysisObj.boardObj.rankObj.pair) {
    threatObj.pairedBoardScore = 500
  } else if (analysisObj.boardObj.rankObj.twopair) {
    threatObj.pairedBoardScore = 700
  } else if (analysisObj.boardObj.rankObj.threeofakind) {
    threatObj.pairedBoardScore = 1000
  }

  if (gameObj.street === 'flop') {
   // let tmp = 1
  }

  if (analysisObj.rankObj.handrank >= 6) {
    // if computer has a flush or better, there is no straight threat nor need for a hope of a straight
    threatObj.drawDeadStrtScore = threatObj.straightScore
    threatObj.straightScore = 0
    hopeObj.straightScore = 0
  }
  if (analysisObj.rankObj.handrank >= 7) {
    // if computer has fullhouse or better, there is no flush threat nor need for the hope of a flush
    threatObj.drawDeadFlushScore = threatObj.flushScore
    threatObj.flushScore = 0
    hopeObj.flushScore = 0
  }
  if (analysisObj.drawObj.straightObj.isStraightDraw && hopeObj.straightScore >= 700) {
    // if computer is on the same straight draw as the board, there is no straight threat
    if (JSON.stringify(analysisObj.drawObj.straightObj.filledSeqArr) === JSON.stringify(analysisObj.drawObj.threatStraightObj.filledSeqArr)) {
      threatObj.drawDeadStrtScore = threatObj.straightScore
      threatObj.straightScore = 0
    }
  }
  let bestCardsRankArr = analysisObj.bestcardsArr.map((obj) => { return obj.rank})
  if (analysisObj.rankObj.straight && JSON.stringify(bestCardsRankArr) === JSON.stringify(analysisObj.drawObj.threatStraightObj.filledSeqArr)) {
    // if computer has a made straight that the threat is drawing to, there is no threat
    threatObj.drawDeadStrtScore = threatObj.straightScore
    threatObj.straightScore = 0
  }

  if (analysisObj.rankObj.flush && analysisObj.drawObj.threatFlushObj.flushSuit === analysisObj.rankObj.flushsuit) {
    // if computer has the flush and human has a flush draw of the same suit, then there is no flush threat
    // TODO check rank of flush
    threatObj.drawDeadFlushScore = threatObj.flushScore
    threatObj.flushScore = 0
  } else if (hopeObj.flushScore >= 500 && threatObj.flushScore >= 500) {
    if (analysisObj.drawObj.flushObj.flushSuit === analysisObj.drawObj.threatFlushObj.flushSuit) {
      // if both players are on the same flush draw, if computer has A, K, or Q, consider threat by human to be 0,
      // but only if there are not two sets of suited cards on the board. eg. NOT 7s,8s,10h,Jh on the turn
      if (analysisObj.drawObj.flushObj.flushDrawRankSeqInPlay[0] >= 12 && analysisObj.drawObj.threatFlushObj.secondVariety !== 'two flush') {
        threatObj.drawDeadFlushScore = threatObj.flushScore
        threatObj.flushScore = 0
        threatObj.msg = 'computer has A,K,or Q of flush draw so human is not a threat'
      }
    }
  }

  // TODO if threat of flush is same suit as hope of flush, threat is less likely as computer suited holecard(s) are blockers

  let arr = [hopeObj, threatObj]
  return arr

}
