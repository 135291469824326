const Chips = require('../helpers/chips')

export function initGameObj(initHistory, oldGameObj, useWait) {

  let bigBlindPlayer
  let v = 0
  if (v === 0) {
    bigBlindPlayer = "human"
  } else if (v === 1) {
    bigBlindPlayer = "computer"
  } else {
    bigBlindPlayer = Math.random() < 0.5 ? "computer" : "human"
  }
  let smallBlindPlayer = bigBlindPlayer === "human" ? "computer" : "human"
  let bigBlind = 40
  let smallBlind = 20
  let startingChips = 800// for each individual player, not a sum of both
  let initHumanChips = startingChips
  let initComputerChips = startingChips
  let playerBetTurn
  if (useWait) {
    playerBetTurn = (smallBlindPlayer === 'human' ? 'waithuman' : 'waitcomputer')
  } else {
    playerBetTurn = (smallBlindPlayer === 'human' ? 'human' : 'computer')
  }
  // if human is big blind, assuming small blind min calls, big blind can raise big blind or more
  let displayBetAmount = bigBlind
  if (smallBlindPlayer === 'human') {
    // if human is small blind, if they raise, it is small blind plus big blind
    displayBetAmount = (bigBlind + smallBlind)
  }

  let gameObj = {
    doAnimation:'blinds',
    humanHoleCardsArr:[],
    computerHoleCardsArr:[],
    boardCardsArr:[],
    holeCardsObj:{},
    startingChips:startingChips,
    humanWonTourn: 0,
    playerBetTurn: playerBetTurn,
    bigBlind: bigBlind,
    tmpBigBlind: 0,
    smallBlind: smallBlind,
    tmpSmallBlind: 0,
    initSmallBlind: smallBlind,
    bigBlindPlayer: bigBlindPlayer,
    smallBlindPlayer: smallBlindPlayer,
    humanChips: initHumanChips,
    computerChips: initComputerChips,
    pot: 0,
    animatePotTo:(bigBlind + smallBlind),
    displayBetAmount: displayBetAmount,
    preflopSmallBlindCallAmount: smallBlind,
    preflopSmallBlindMinRaiseAmount: (smallBlind + bigBlind),
    street: "preflop",
    msg: "",
    wins: 0,
    loses: 0,
    previousBetType: "smallBlind",// smallBlind is mandatory as is bigBlind. bet history will be bigBlind and then smallBlind
    previousBetAmount: 0,
    gameOver: 0,
    isHumanHandActionDone: false,
    isComputerHandActionDone: false,
    readyForNextHand: false,
    previousRaiseAmount: 0,
    handWinner: "",
    handWonAmount: 0,
    humanTournWins:0,
    computerTournWins:0,
    tournMaxNum:3,
    numHands: 1,
    totalStreets: 1,
    numStreets: 0,
    humanCategoryIndex: 0,
    tournCurrentNum: 1
  }

  if (initHistory) {
    let historyObj = {
      num_actions:0,
      check: 0,
      raise: 0,
      call: 0,
      fold: 0,
      allIn: 0,
      showdown:0,
      showdown_won:0,
      saw_flop_as_small_blind:0,
      saw_flop_as_big_blind:0,
      fold_preflop:0,
      perc_showdown:0,
      perc_showdown_won:0,
      perc_check:0,
      perc_raise:0,
      perc_call:0,
      perc_fold:0,
      perc_allIn:0
    }
    gameObj.computerHistoryObj = { ...historyObj }
    gameObj.humanHistoryObj = { ...historyObj }
  } else {
    gameObj.computerHistoryObj = oldGameObj.computerHistoryObj
    gameObj.humanHistoryObj = oldGameObj.humanHistoryObj
    gameObj.numHands = oldGameObj.numHands
    gameObj.totalStreets = oldGameObj.totalStreets
    gameObj.numStreets = oldGameObj.numStreets
    gameObj.humanCategoryIndex = oldGameObj.humanCategoryIndex
    gameObj.tournCurrentNum = ++gameObj.tournCurrentNum
    gameObj.computerTournWins = oldGameObj.computerTournWins
    gameObj.humanTournWins = oldGameObj.humanTournWins
  }
  let streetHistoryArr = [
    {actor: gameObj.bigBlindPlayer, street: gameObj.street, action: 'bigBlind', betAmount:gameObj.bigBlind},
    {actor: gameObj.smallBlindPlayer, street: gameObj.street, action: 'smallBlind', betAmount:gameObj.smallBlind}
  ]
  gameObj.streetHistoryArr = streetHistoryArr
  gameObj.betObj = Chips.getBetObj(gameObj, streetHistoryArr)
  return gameObj
}
