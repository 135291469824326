import Hand from './hand.js'
import HeadsUpBoardCards from './headsup_board_cards'
import React, { useState } from 'react'
import ActionText from "../components/actiontext";

const Util = require("../helpers/util")

// Present both players hole cards, community cards, handle betting and progression through streets
export default function TableHeadsUp ({
  humanHoleCardsArr,
  computerHoleCardsArr,
  boardCardsArr,
  gameObj,
  dev,
  setDev
}) {

  // function getIncAmount(gameObj) {
  //   let lastRow = Util.getLastRow(gameObj)
  //   let secondToLastRow = Util.getSecondToLastRow(gameObj)
  //   let amount = 0
  //   if (lastRow.action === 'smallBlind' && secondToLastRow.action === 'bigBlind') {
  //     amount = lastRow.betAmount + secondToLastRow.betAmount
  //   } else if (secondToLastRow.action === 'call' && lastRow.action === 'raise') {
  //     amount = secondToLastRow.betAmount + lastRow.betAmount
  //   } else if (lastRow.action === 'raise') {
  //     amount = lastRow.betAmount
  //   } else if (lastRow.action === 'call') {
  //     amount = lastRow.betAmount
  //   }
  //   return amount
  // }

  function toggleShow() {
    if (showBool) {
      setShowBool(false)
    } else {
      setShowBool(true)
    }
  }

  //let amount = getIncAmount(gameObj)
  let [showBool, setShowBool] = useState(false)
  let showBtnStyle = {}
  if (!dev) {
    showBool = false
    showBtnStyle = {display:'none'}
  }

  let show
  if (!showBool) {
    show = false
  } else if (showBool) {
    show = true
  }
  if (gameObj.street === 'done') {
    show = true
  } else if (gameObj.readyForNextHand && dev) {
    // show computer's holecards at end of hand if in dev mode
    show = true
  }

  let bigBlindComputerMsgStyle
  let bigBlindHumanMsgStyle
  let smallBlindHumanMsgStyle
  let smallBlindComputerMsgStyle
  let blindHumanClass = 'humanBlindMsg'
  let blindComputerClass = 'computerBlindMsg'
  let chipImgTag = Util.getChipImg()
  let blindsChipImgTag = Util.getChipImg()
  let computerBlindAmountStyle = {visibility:'visible'}
  let humanBlindAmountStyle = {visibility:'visible'}
  if (gameObj.street !== 'preflop') {
    blindHumanClass = 'humanBlindMsgPost'
    blindsChipImgTag = ""
    humanBlindAmountStyle = {visibility:'hidden'}
    blindComputerClass = 'computerBlindMsgPost'
    blindsChipImgTag = ""
    computerBlindAmountStyle = {visibility:'hidden'}
  }

  if (gameObj.bigBlindPlayer === 'human') {
    bigBlindHumanMsgStyle = { display: 'block'}
    smallBlindHumanMsgStyle = {display:'none'}
    smallBlindComputerMsgStyle = { display: 'block'}
    bigBlindComputerMsgStyle = {display:'none'}
  } else {
    bigBlindHumanMsgStyle = { display: 'none' }
    smallBlindHumanMsgStyle = {display:'block'}
    smallBlindComputerMsgStyle = { display: 'none'}
    bigBlindComputerMsgStyle = {display:'block'}
  }

  return <div className="tableHeadsUpCont" key={gameObj.street}>

    <div className="cb"></div>
    <button
      className='setDevBtn'
      style={{display:'block'}}
      onClick={() => setDev(dev)}>Toggle Dev
    </button>
    <div className="blindCont"><div className="chipImgCont" dangerouslySetInnerHTML={{ __html:chipImgTag}} />Computer Chips: {gameObj.computerChips}</div>
    <div className="cb"></div>
    <div className="holeCards">
      <Hand handArr={computerHoleCardsArr}  player={"computer"} show = {show}/>
      <div className="cb"></div>
    </div>

    <button className="showBtn" style = {showBtnStyle} onClick={() => toggleShow()}></button>
    <div className="cb"></div>

    <div className={blindComputerClass} style={bigBlindComputerMsgStyle}>
      <div className="chipImgCont" dangerouslySetInnerHTML={{ __html:blindsChipImgTag}} />Big blind
      <span style={computerBlindAmountStyle}>&nbsp;{gameObj.bigBlind}</span>
    </div>
    <div className={blindComputerClass} style={smallBlindComputerMsgStyle}>
      <div className="chipImgCont" dangerouslySetInnerHTML={{ __html:blindsChipImgTag}} />Small blind
      <span style={computerBlindAmountStyle}>&nbsp;{gameObj.smallBlind}</span>
    </div>

    <HeadsUpBoardCards boardCardsArr={boardCardsArr} gameObj={gameObj}/>
    <div className="cb"></div>

    <div className={blindHumanClass} style={bigBlindHumanMsgStyle}>
      <div className="chipImgCont" dangerouslySetInnerHTML={{ __html:blindsChipImgTag}} />Big blind
      <span style={humanBlindAmountStyle}>&nbsp;{gameObj.bigBlind}</span>
    </div>
    <div className={blindHumanClass} style={smallBlindHumanMsgStyle}>
      <div className="chipImgCont" dangerouslySetInnerHTML={{ __html:blindsChipImgTag}} />Small blind
      <span style={humanBlindAmountStyle}>&nbsp;{gameObj.smallBlind}</span>
    </div>

    <div className="chips pot">
      <div className="chipImgCont" dangerouslySetInnerHTML={{ __html:chipImgTag}} />Pot:&nbsp;
      <span className="number" style={{display:"inline"}}>
        {gameObj.pot}
      </span>
    </div>
    <div className="holeCards">
      <Hand handArr={humanHoleCardsArr} player={"human"} show = {true} />
      <div className="cb"></div>
    </div>

    <div className="cb"></div>
    <div className={"blindCont"}><div className="chipImgCont" dangerouslySetInnerHTML={{ __html:chipImgTag}} />Human Chips: {gameObj.humanChips}</div>
    <div className="cb"></div>

    <ActionText gameObj = {gameObj} />
  </div>

}
