const Util = require("../util")

export default class actionsbase {

  constructor (gameObj, analysisObj, ddObj, isFirstToAct) {

    let canCheck = false
    let canRaise = false
    let canSmallBlindCall = false
    let betObj = gameObj.betObj
    if (gameObj.street === 'preflop') {
      if (gameObj.previousBetAmount === 0 || gameObj.previousBetAmount === gameObj.smallBlind) {
        canCheck = true
      }
      //canCall = false
      //if (gameObj.previousBetAmount > 0 && gameObj.previousBetAmount !== gameObj.smallBlind) {
        //canCall = true
      //}
      if ((gameObj.previousBetAmount === 0 || gameObj.previousBetAmount === gameObj.smallBlind)
        && gameObj.computerChips >= betObj.minRaiseAmount) {
        canRaise = true
      }
      //canReraise = false
      //if (gameObj.previousBetAmount > 0 && gameObj.computerChips >= gameObj.previousBetAmount
      //  && gameObj.previousBetAmount !== gameObj.smallBlind) {
        //canReraise = true
      //}

      // small blind first to act
      if (gameObj.playerBetTurn === 'computer' && gameObj.playerBetTurn === gameObj.smallBlindPlayer
        && gameObj.previousBetAmount === 0 && gameObj.previousBetType === 'smallBlind'
      ) {
        canSmallBlindCall = true
      }
    } else {
      canCheck = gameObj.previousBetAmount === 0 ? true : false
      //canCall = gameObj.previousBetAmount > 0 ? true : false
      canRaise = gameObj.previousBetAmount === 0 && gameObj.computerChips >= betObj.minRaiseAmount ? true : false
      //canReraise = gameObj.previousBetAmount > 0 && gameObj.computerChips >= gameObj.previousBetAmount ? true : false
    }

    this.computerChips = gameObj.computerChips
    this.humanChips = gameObj.humanChips
    this.betObj = betObj
    this.canRaise = canRaise
    this.canCheck = canCheck
    this.canSmallBlindCall = canSmallBlindCall
    this.gameObj = gameObj

    this.hopeFlushScore = ddObj.hopeObj.flushScore
    this.hopeStraightScore = ddObj.hopeObj.straightScore
    this.threatFlushScore = ddObj.threatObj.flushScore
    this.threatStraightScore = ddObj.threatObj.straightScore
    this.threatDrawDeadFlushScore = ddObj.threatObj.drawDeadFlushScore
    this.threatDrawDeadStrtScore = ddObj.threatObj.drawDeadStrtScore
    this.threatPairedBoardScore = ddObj.threatObj.pairedBoardScore
    this.handScore = ddObj.handEvalObj.score
    this.handName = ddObj.handEvalObj.handName

    this.street = gameObj.street
    this.holeCardsObj = analysisObj.holeCardsObj
    this.prevStreetBetObj = Util.getPreviousStreetBet(gameObj)
    // computer can make a continuation bet of their own previous bet or the human's previous bet
    this.continuationBet = 0
    if (this.prevStreetBetObj.human.action === 'call') {
      this.continuationBet = this.prevStreetBetObj.human.betAmount
    } else if (this.prevStreetBetObj.computer.action === 'call') {
      this.continuationBet = this.prevStreetBetObj.computer.betAmount
    }
    if (this.continuationBet) {
      this.continuationBetPercOfHumanStack = Util.getPerc(this.continuationBet, gameObj.humanChips)
      this.continuationBetPercOfComputerStack = Util.getPerc(this.continuationBet, gameObj.computerChips)
      this.continuationBetPercOfPot = Util.getPerc(this.continuationBet, gameObj.pot)
    }
    
    if (typeof betObj === 'undefined' || typeof betObj.minRaiseAmount === 'undefined') {
      //let tmp = 1
    }
    this.minRaiseAmount = betObj.minRaiseAmount
    // when comp has likely best hand, use bestRaiseAmount
    this.bestRaiseAmount = this.minRaiseAmount
    if (isFirstToAct && this.continuationBet) {
      // continuation bet
      this.bestRaiseAmount = this.continuationBet
    }
    this.pot = gameObj.pot
    this.halfPot = Util.roundToNearestBB(gameObj.pot/2, gameObj)
    this.threeBB = gameObj.bigBlind * 3
    // for when computer has the nuts...
    if (gameObj.street === 'flop' && this.bestRaiseAmount < this.threeBB) {
      this.bestRaiseAmount = this.threeBB
    } else if (gameObj.street === 'turn' && this.bestRaiseAmount < this.halfPot) {
      this.bestRaiseAmount = this.halfPot
    } else if (gameObj.street === 'river' && this.bestRaiseAmount < this.pot) {
      this.bestRaiseAmount = this.pot
    }
    let maxBetPrev = Util.getMaxBetPrev(gameObj)
    if (maxBetPrev > this.bestRaiseAmount) {
      this.bestRaiseAmount = maxBetPrev
    }
    this.minCallAmount = betObj.minCallAmount

    let goAllIn = this.pot / this.computerChips >= .4 // if the pot is almost half computer's chips or more, ok to shove
    this.goAllIn = goAllIn// TODO change to this.goAllInOnReraise

    this.humanCategoryIndex = gameObj.humanCategoryIndex
    this.humanHandAggressionScore = ddObj.humanHandAggressionScore
    this.boardRankObj = analysisObj.boardObj ? analysisObj.boardObj.rankObj : {}
    this.computerRankObj = analysisObj.rankObj
    this.computerBestcardsArr = analysisObj.bestcardsArr
    this.randObj = gameObj.randObj


    this.potPercOfComputerStack = Util.getPerc(gameObj.pot, gameObj.computerChips)
    this.potPercOfHumanStack = Util.getPerc(gameObj.pot, gameObj.humanChips)
    this.humanCategoryIndex = Util.getHumanCategoryIndex(gameObj)
    // if action is to computer, did human raise or check? if raise, what percent of pot was the raise
    let humanLastRow = Util.getLastRowByActor(gameObj, 'human')
    this.humanRaisePercOfPot = 0
    this.humanRaisePercOfHumanStack = 0
    this.computerMinCallAmountPercOfStack = 0
    this.computerMinCallAmountPercOfPot = 0
    if (gameObj.street === 'flop') {
      //let tmp = 1
    }
    if (humanLastRow.action === 'raise' || humanLastRow.action === 'allIn') {
      this.humanRaisePercOfPot = Util.getPerc(humanLastRow.betAmount, gameObj.pot)
      this.humanRaisePercOfHumanStack = Util.getPerc(humanLastRow.betAmount, gameObj.humanChips)
      this.computerMinCallAmountPercOfStack = Util.getPerc(gameObj.betObj.minCallAmount, gameObj.computerChips)
      this.computerMinCallAmountPercOfPot = Util.getPerc(gameObj.betObj.minCallAmount, gameObj.pot)
    }
    this.computerMinRaisePercOfComputerStack = Util.getPerc(gameObj.betObj.minRaiseAmount, gameObj.computerChips)
    this.computerMinRaisePercOfPot = Util.getPerc(gameObj.betObj.minRaiseAmount, gameObj.pot)
    this.computerMinRaisePercOfHumanStack = Util.getPerc(gameObj.betObj.minRaiseAmount, gameObj.humanChips)
    this.computerPotBetPercOfComputerStack = Util.getPerc(gameObj.pot, gameObj.computerChips)
    this.computerPotBetPercOfHumanStack = Util.getPerc(gameObj.pot, gameObj.humanChips)

    this.humanTotalAmountBetForHand = Util.getTotalAmountBetForHandByActor("human", gameObj)
    this.computerTotalAmountBetForHand = Util.getTotalAmountBetForHandByActor("computer", gameObj)
    this.humanTotalAmountBetPercOfStack = Util.getPerc(this.humanTotalAmountBetForHand, gameObj.humanChips)

    this.minRaiseAmount = gameObj.betObj.minRaiseAmount
    this.minCallAmount = gameObj.betObj.minCallAmount
    this.pot = gameObj.pot
    this.betEquityObj = Util.getBetEquityObj(gameObj, 'to nearest')
    this.equityCallAmount = Util.getEquityCallAmount(gameObj)

    this.prevStreetAct = Util.getPreviousActionOnStreet(gameObj)
    //this.prevStreetBetObj = Util.getPreviousStreetBet(gameObj)
    this.actionForHandArr = Util.getActionForHand(gameObj)
    this.bbObj = Util.calcBigBlinds(gameObj)

    this.drawProbability = analysisObj.drawObj ? analysisObj.drawObj.drawProbability : 0
    this.drawNumOuts = analysisObj.drawObj ? analysisObj.drawObj.numOuts : 0

    this.alwaysAllIn = false
    if (gameObj.humanHistoryObj.perc_raise >= 75 || gameObj.humanHistoryObj.perc_allIn >= 75) {
      this.alwaysAllIn = true
    }

    // TODO reduce the threshold scores of made hands if the call amount is of a certain % of computer's stack or pot, so
    // a threshold score of 700 for a pair maybe reduced to 500 (more likely incurring a call) if the call amount is only .2 of the pot
    // TODO set a 'bestScore' for each hand type which will trigger different actions. eg. hopeFlushScoreTh of 700 is great,
    //  but 1000 should trigger different actions
    // TODO set values for 'worst' threat. eg. worstThreatFlushScore = 1000
    // TODO how to incorporate humanHandAgression into the score?
    // TODO how to add bet variance? Use randObj?
    // TODO make use of pairedBoardScore threat
    // TODO - make a priorityAction in each action class (pair.js, etc) that is specific to each action class that
    // will determine what to do in the case of a each threat given computer's holdings. eg. a flush with a paired board,
    // a straight with a 3 card flush board. Given the size of the bet, the human type, either call, fold or let
    // the regular evaluations occur.
    // if (this.priorityAction === 'call') {
    //   actObj.action = 'call'
    // } else if (this.priorityAction === 'fold') {
    //   actObj.action = 'fold'
    // } else {
    //   ... regular assessment
    // }


    // set score thresholds based on type of human; loose, tight, etc.
    // this.handScore must be greater than the threshold ('Th' suffix) values set here in order to make bets/calls, so
    // a pair with a this.handScore of 700 must be greater than this.pairScoreTh set below in order to enter
    // certain conditionals in streetactions/pair.js
    this.highcardScoreTh = 1000//Ace with top kicker
    this.pairScoreTh = 700
    this.twopairScoreTh = 1000
    this.threeofakindScoreTh = 900
    this.straightScoreTh = 700
    this.flushScoreTh = 700
    this.fullhouseScoreTh = 700
    this.fourofakindScoreTh = 1000
    this.straightflushScoreTh = 700
    // threats
    //this.threatFlushScoreTh = 400 // two suited on the board before the river is reached
    //this.threatStraightScoreTh = 400 // two card draw
    this.threatFlushScoreTh = 700 // three suited on the board
    this.threatStraightScoreTh = 700 // single card draw
    // paired board
    this.pairedBoardScoreTh = 500
    // hopes
    this.hopeFlushScoreTh = 700
    this.hopeStraightScoreTh = 700
    //
    // if (this.humanCategoryIndex === 400) {
    //   // 'loose'
    //   this.highcardScoreTh = 800
    //   this.pairScoreTh = 650
    //   this.twopairScoreTh = 500
    //   this.threeofakindScoreTh = 900
    //   this.straightScoreTh = 700
    //   this.flushScoreTh = 700
    //   this.fullhouseScoreTh = 700
    //   this.fourofakindScoreTh = 1000
    //   this.straightflushScoreTh = 700
    //   // threats
    //   this.threatFlushScoreTh = 700
    //   this.threatStraightScoreTh = 700
    //   // paired board
    //   this.pairedBoardScoreTh = 500
    //   // hopes
    //   this.hopeFlushScoreTh = 700
    //   this.hopeStraightScoreTh = 700
    // } else if (this.humanCategoryIndex === 500) {
    //   // 'notserious' aka maniac
    //   this.highcardScoreTh = 700
    //   this.pairScoreTh = 450
    //   this.twopairScoreTh = 300
    //   this.threeofakindScoreTh = 900
    //   this.straightScoreTh = 600
    //   this.flushScoreTh = 300
    //   this.fullhouseScoreTh = 600
    //   this.fourofakindScoreTh = 600
    //   this.straightflushScoreTh = 700
    //   // threats
    //   this.threatFlushScoreTh = 900
    //   this.threatStraightScoreTh = 800
    //   // paired board
    //   this.pairedBoardScoreTh = 500
    //   // hopes
    //   this.hopeFlushScoreTh = 700
    //   this.hopeStraightScoreTh = 700
    // }

    // TODO add adjusted to threat and hope
    // Never fold to a 1 big blind raise
    if (gameObj.street === 'turn') {
      //let t = 1
    }
    //let adjNum = 0
    //let minCallPercOfPot = gameObj.betObj.minCallAmount / gameObj.pot
    // only adjust when in 'call' or 'reraise' mode and computer has a pair or better. Checking is risk free.
    // if (gameObj.betObj.minCallAmount > 0 && analysisObj.rankObj.handrank > 1 &&
    //   (gameObj.betObj.minCallAmount <= gameObj.bigBlind * 3 || minCallPercOfPot <= .2)
    // ) {
    //   // this will make sure the hand is more valued when it is a small bet
    //   adjNum = 500
    // }
    // this.adjHighcardScoreTh = this.highcardScoreTh - adjNum
    // this.adjPairScoreTh= this.pairScoreTh - adjNum
    // this.adjTwopairScoreTh= this.twopairScoreTh - adjNum
    // this.adjThreeofakindScoreTh= this.threeofakindScoreTh - adjNum
    // this.adjStraightScoreTh= this.straightScoreTh - adjNum
    // this.adjFlushScoreTh= this.flushScoreTh - adjNum
    // this.adjFullhouseScoreTh= this.fullhouseScoreTh - adjNum
    // this.adjFourofakindScoreTh= this.fourofakindScoreTh - adjNum
    // this.adjStraightflushScoreTh= this.straightflushScoreTh - adjNum

    this.adjHighcardScoreTh = this.highcardScoreTh
    this.adjPairScoreTh= this.pairScoreTh
    this.adjTwopairScoreTh= this.twopairScoreTh
    this.adjThreeofakindScoreTh= this.threeofakindScoreTh
    this.adjStraightScoreTh= this.straightScoreTh
    this.adjFlushScoreTh= this.flushScoreTh
    this.adjFullhouseScoreTh= this.fullhouseScoreTh
    this.adjFourofakindScoreTh= this.fourofakindScoreTh
    this.adjStraightflushScoreTh= this.straightflushScoreTh

    this.threatDrawThCrossed = false
    if (this.threatStraightScore >= this.threatStraightScoreTh || this.threatFlushScore >= this.threatFlushScoreTh) {
      this.threatDrawThCrossed = true
    }
    this.noThreatDraw = false
    if (this.threatFlushScore < this.threatFlushScoreTh && this.threatStraightScore < this.threatStraightScoreTh) {
      this.noThreatDraw = true
    }
    this.hopeDrawThCrossed = false
    if (this.hopeStraightScore >= this.hopeStraightScoreTh || this.hopeFlushScore >= this.hopeFlushScoreTh) {
      this.hopeDrawThCrossed = true
    }
    this.safeCall = false
    if (this.computerMinCallAmountPercOfPot <= .25 || gameObj.betObj.minCallAmount === gameObj.bigBlind) {
      this.safeCall = true
    } else if (this.humanCategoryIndex >= 400 &&
      (this.computerMinCallAmountPercOfPot <= .35 || gameObj.betObj.minCallAmount === gameObj.bigBlind * 3)
    ) {
      this.safeCall = true
    }

    this.hasAHopeDraw = false
    if (this.hopeStraightScore || this.hopeFlushScore) {
      this.hasAHopeDraw = true
    }

  }

  dangerDecision() {


  }


  playingTheBoard() {

    let actObj = {}

    if (this.canRaise || this.canCheck) {
      actObj.action = 'check'
      if (this.street === 'river' && (this.holeCardsObj.hasAce || (this.boardRankObj.threeofakindrank === 14 && this.holeCardsObj.hasKing))
      ) {
        // on the river
        // try to make tight players fold
        // if computer has an Ace overcard to the three of a kind on the board or the board has 3 aces and player has a King
        let bet = this.pot
        actObj.action = 'raise'
        actObj.betAmount = bet
      }
    } else {
      actObj.action = 'fold'
      if (this.holeCardsObj.hasPositionOneAndTwo && this.holeCardsObj.hasAce) {
        actObj.action = this.dangerDecision()
      } else if (this.holeCardsObj.hasPositionOne && this.holeCardsObj.hasAce) {
        actObj.action = this.dangerDecision()
      }
    }
    return actObj

  }

  mngAllIn() {
    let actObj = {}
    actObj.action = 'call'
    return actObj
  }

  // START FLOP
  //
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }
  //
  // END FLOP

  // START TURN
  //
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }
  //
  // END TURN

  // START RIVER
  //
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {

    } else { //canReraise || canCall

    }
    return actObj
  }
  //
  // END RIVER
  
}
