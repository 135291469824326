import actionsbase from "./actionsbase"

export default class straightflush extends actionsbase {

  mngAllIn() {
    let actObj = {}
    actObj.action = 'call'
    return actObj
  }

// START FLOP
//
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      // flopped the best flush, check it to let them catch up
      // both holecards are required to be in play when flopping a flush
      actObj = { action: 'check' }
    } else {
      // both holecards are required to be in play when flopping a flush
      // flopped the best flush, it was checked and human bet, call any
      actObj = { action: 'call' }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check'}
      // flopped the best flush, and they checked, check back
      // both holecards are required to be in play when flopping a flush
    } else {
      // flopped the best flush, and they bet, call any
      actObj = { action: 'call'  }
    }
    return actObj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.threatDrawDeadFlushScore >= 700 || this.threatDrawDeadStrtScore >= 700 || this.threatPairedBoardScore) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj = { action: "raise", betAmount: this.minRaiseAmount}
      }
    } else {
      actObj = { action: 'call'  }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.threatDrawDeadFlushScore >= this.threatFlushScoreTh
        || this.threatDrawDeadStrtScore >= this.threatStraightScoreTh
        || this.threatPairedBoardScore) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj = { action: "raise", betAmount: this.minRaiseAmount}
      }
    } else {
      actObj = { action: 'call' }
    }
    return actObj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
    } else {
      actObj = {action: 'raise', betAmount: this.bestRaiseAmount}
    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
    } else {
      actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
    }
    return actObj
  }
//
// END RIVER

}