const Base = require('../helpers/base.js');

// https://www.npmjs.com/package/react-card-flip
export default function Card ({cardObj, show}) {

  let rank = cardObj['rank'];
  let suit = cardObj['suit'];

  let displayed = 'cover';
  if (show) {
      displayed = 'face';
  }

  let key =  rank + "_" + suit + "_" + displayed;

  let displayCard = '';
  let classname = "card"
  if (displayed === 'cover') {
    classname = "cardCover"
    displayCard = '';
  } else {
    let rankDisplay = Base.convertRankToFullName(rank);
    if (window.windowWidth <= 420) {
      if (rankDisplay === 'Queen') {
        rankDisplay = "Q"
      } else if (rankDisplay === 'King') {
        rankDisplay = "K"
      } else if (rankDisplay === 'Ace') {
        rankDisplay = "A"
      } else if (rankDisplay === 'Jack') {
        rankDisplay = "J"
      }
    }
    let htmlSuit = Base.convertHTMLSuit(suit);
    displayCard = '<div class="rankDisplay">' + rankDisplay + '</div><div class="suitSymbolBigCard">' + htmlSuit + '</div>';
  }

  return <div key={key} className={classname} dangerouslySetInnerHTML={{ __html: displayCard}} />

}
