const Util = require('../helpers/util')
const Base = require('../helpers/base')

/*

Evaluate made hands and give the hand a 'score' based on its strength. 700 or better is always good. 1000 is great
See helpers/threatAndHope.js for evaluating and scoring draws
*/

// This evaluates solely based on the rank of the cards, not the pairs et al formed
// eg. holecards K,Q - A,A,J,9,6 = K = position 2, Q = position 3 etc
export function evalHoleCardPosition(holeCardsObj, limitedCommRankArr) {

  holeCardsObj.lowcard.position = 1 //1 is best
  holeCardsObj.highcard.position = 1
  // get rid of duplicate ranks on the board
  let boardArr = []
  for(let i in limitedCommRankArr) {
    let rank = limitedCommRankArr[i]
    if (!boardArr.includes(rank)) {
      boardArr.push(rank)
    }
  }
  let highcardAndBoardRankArr = [...boardArr, holeCardsObj.highcard.rank]
  let lowcardAndBoardRankArr = [...boardArr, holeCardsObj.lowcard.rank]
  // compare low holecard to all cards in play including high holecard
  for(let i in highcardAndBoardRankArr) {
    let boardRank = highcardAndBoardRankArr[i]
    if (boardRank > holeCardsObj.lowcard.rank) {
      holeCardsObj.lowcard.position++
    }
  }
  for(let i in lowcardAndBoardRankArr) {
    if (lowcardAndBoardRankArr[i] > holeCardsObj.highcard.rank) {
      holeCardsObj.highcard.position++
    }
  }
  if (holeCardsObj.highcard.position === holeCardsObj.lowcard.position && holeCardsObj.highcard.rank !== holeCardsObj.lowcard.rank) {
    holeCardsObj.lowcard.position++
  }

  // give non-paired kicker card a position without considering the position of the paired cards
  // if (pairrank) {
  //   holeCardsObj.lowcard.nonpairedposition = 1 //1 is best
  //   holeCardsObj.highcard.nonpairedposition = 1
  //   for(let i in limitedCommRankArr) {
  //     if (pairrank !== limitedCommRankArr[i] &&  limitedCommRankArr[i] > holeCardsObj.lowcard.rank) {
  //       holeCardsObj.lowcard.nonpairedposition++
  //     }
  //     if (pairrank !== limitedCommRankArr[i] && limitedCommRankArr[i] > holeCardsObj.highcard.rank) {
  //       holeCardsObj.highcard.nonpairedposition++
  //     }
  //   }
  // }

  // determine if holecards are two overcards to the board
  holeCardsObj.hasPositionOneAndTwo = false
  holeCardsObj.hasPositionOne = false
  if (holeCardsObj.lowcard.position === 2 && holeCardsObj.highcard.position === 1) {
    holeCardsObj.hasPositionOneAndTwo = true
  }
  if (holeCardsObj.highcard.position === 1) {
    holeCardsObj.hasPositionOne = true
  }

  return holeCardsObj

}

export function evalHoleCards(arr) {
  let c1 = arr[0].rank === 10 ? "T" : arr[0].rank
  let c2 = arr[1].rank === 10 ? "T" : arr[1].rank
  let suited = "o"
  if (arr[0].suit === arr[1].suit) {
    suited = "s"
  }
  c1 = String(Base.convertRankToLetter(c1))
  c2 = String(Base.convertRankToLetter(c2))
  let cards = c1 + c2 + suited
  let r = runEvalHoleCards(cards)

  if (!r) {
    // switch the order of cards if no result
    let c1 = arr[1].rank === 10 ? "T" : arr[1].rank
    let c2 = arr[0].rank === 10 ? "T" : arr[0].rank
    suited = "o"
    if (arr[0].suit === arr[1].suit) {
      suited = "s"
    }
    cards = String(Base.convertRankToLetter(c1))
    cards+= String(Base.convertRankToLetter(c2))
    cards+=suited
    r = runEvalHoleCards(cards)
  }

  let obj = {}
  obj.score = Math.ceil(r)
  obj.suited = suited === 's' ? true : false
  obj.connected = (arr[0].rank - 1 === arr[1].rank || arr[0].rank + 1 === arr[1].rank) ? true : false
  obj.paired = arr[0].rank === arr[1].rank ? true : false
  if (arr[0].rank === arr[1].rank) {
    obj.highcard = arr[0]
    obj.lowcard = arr[1]
  } else {
    obj.highcard = arr[0].rank >= arr[1].rank ? arr[0] : arr[1]
    obj.lowcard = arr[0].rank <= arr[1].rank ? arr[0] : arr[1]
  }
  obj.hasAce = arr[0].rank === 14 || arr[1].rank === 14
  obj.hasKing = arr[0].rank === 13 || arr[1].rank === 13
  obj.highcard.position = 0
  //obj.highcard.nonpairedposition = 0
  obj.lowcard.position = 0
  //obj.lowcard.nonpairedposition = 0

  return obj

}

function runEvalHoleCards(cards) {

  switch (cards) {
    case "AAo" : return 84.93
    case "KKo" : return 82.11
    case "QQo" : return 79.63
    case "JJo" : return 77.15
    case "TTo" : return 74.66
    case "99o" : return 71.66
    case "88o" : return 68.71
    case "AKs" : return 66.21
    case "77o" : return 65.72
    case "AQs" : return 65.31
    case "AJs" : return 64.39
    case "AKo" : return 64.46
    case "ATs" : return 63.48
    case "AQo" : return 63.50
    case "AJo" : return 62.53
    case "KQs" : return 62.40
    case "66o" : return 62.70
    case "A9s" : return 61.50
    case "ATo" : return 61.56
    case "KJs" : return 61.47
    case "A8s" : return 60.50
    case "KTs" : return 60.58
    case "KQo" : return 60.43
    case "A7s" : return 59.38
    case "A9o" : return 59.44
    case "KJo" : return 59.44
    case "55o" : return 59.64
    case "QJs" : return 59.07
    case "K9s" : return 58.63
    case "A5s" : return 58.06
    case "A6s" : return 58.17
    case "A8o" : return 58.37
    case "KTo" : return 58.49
    case "QTs" : return 58.17
    case "A4s" : return 57.13
    case "A7o" : return 57.16
    case "K8s" : return 56.79
    case "A3s" : return 56.33
    case "QJo" : return 56.90
    case "K9o" : return 56.40
    case "A5o" : return 55.74
    case "A6o" : return 55.87
    case "Q9s" : return 56.22
    case "K7s" : return 55.84
    case "JTs" : return 56.15
    case "A2s" : return 55.50
    case "QTo" : return 55.94
    case "44o" : return 56.25
    case "A4o" : return 54.73
    case "K6s" : return 54.80
    case "K8o" : return 54.43
    case "Q8s" : return 54.41
    case "A3o" : return 53.85
    case "K5s" : return 53.83
    case "J9s" : return 54.11
    case "Q9o" : return 53.86
    case "JTo" : return 53.82
    case "K7o" : return 53.41
    case "A2o" : return 52.94
    case "K4s" : return 52.88
    case "Q7s" : return 52.52
    case "K6o" : return 52.29
    case "K3s" : return 52.07
    case "T9s" : return 52.37
    case "J8s" : return 52.31
    case "33o" : return 52.83
    case "Q6s" : return 51.67
    case "Q8o" : return 51.93
    case "K5o" : return 51.25
    case "J9o" : return 51.63
    case "K2s" : return 51.23
    case "Q5s" : return 50.71
    case "T8s" : return 50.50
    case "K4o" : return 50.22
    case "J7s" : return 50.45
    case "Q4s" : return 49.76
    case "Q7o" : return 49.90
    case "T9o" : return 49.81
    case "J8o" : return 49.71
    case "K3o" : return 49.33
    case "Q6o" : return 48.99
    case "Q3s" : return 48.93
    case "98s" : return 48.85
    case "T7s" : return 48.65
    case "J6s" : return 48.57
    case "K2o" : return 48.42
    case "22o" : return 49.38
    case "Q2s" : return 48.10
    case "Q5o" : return 47.95
    case "J5s" : return 47.82
    case "T8o" : return 47.81
    case "J7o" : return 47.72
    case "Q4o" : return 46.92
    case "97s" : return 46.99
    case "J4s" : return 46.86
    case "T6s" : return 46.80
    case "J3s" : return 46.04
    case "Q3o" : return 46.02
    case "98o" : return 46.06
    case "87s" : return 45.68
    case "T7o" : return 45.82
    case "J6o" : return 45.71
    case "96s" : return 45.15
    case "J2s" : return 45.20
    case "Q2o" : return 45.10
    case "T5s" : return 44.93
    case "J5o" : return 44.90
    case "T4s" : return 44.20
    case "97o" : return 44.07
    case "86s" : return 43.81
    case "J4o" : return 43.86
    case "T6o" : return 43.84
    case "95s" : return 43.31
    case "T3s" : return 43.37
    case "76s" : return 42.82
    case "J3o" : return 42.96
    case "87o" : return 42.69
    case "T2s" : return 42.54
    case "85s" : return 41.99
    case "96o" : return 42.10
    case "J2o" : return 42.04
    case "T5o" : return 41.85
    case "94s" : return 41.40
    case "75s" : return 40.97
    case "T4o" : return 41.05
    case "93s" : return 40.80
    case "86o" : return 40.69
    case "65s" : return 40.34
    case "84s" : return 40.10
    case "95o" : return 40.13
    case "T3o" : return 40.15
    case "92s" : return 39.97
    case "76o" : return 39.65
    case "74s" : return 39.10
    case "T2o" : return 39.23
    case "54s" : return 38.53
    case "85o" : return 38.74
    case "64s" : return 38.48
    case "83s" : return 38.28
    case "94o" : return 38.08
    case "75o" : return 37.67
    case "82s" : return 37.67
    case "73s" : return 37.30
    case "93o" : return 37.42
    case "65o" : return 37.01
    case "53s" : return 36.75
    case "63s" : return 36.68
    case "84o" : return 36.70
    case "92o" : return 36.51
    case "43s" : return 35.72
    case "74o" : return 35.66
    case "72s" : return 35.43
    case "54o" : return 35.07
    case "64o" : return 35.00
    case "52s" : return 34.92
    case "62s" : return 34.83
    case "83o" : return 34.74
    case "42s" : return 33.91
    case "82o" : return 34.08
    case "73o" : return 33.71
    case "53o" : return 33.16
    case "63o" : return 33.06
    case "32s" : return 33.09
    case "43o" : return 32.06
    case "72o" : return 31.71
    case "52o" : return 31.19
    case "62o" : return 31.07
    case "42o" : return 30.11
    case "32o" : return 29.23
    default: return false

  }

}

export function evalHighcard(gameObj, analysisObj) {

  let score = 0
  if (analysisObj.holeCardsObj.hasPositionOneAndTwo && analysisObj.holeCardsObj.hasAce) {
    score = 1000
  } else if (analysisObj.holeCardsObj.hasAce) {
    score = 900
  } else if (analysisObj.holeCardsObj.hasPositionOneAndTwo) {
    score = 800
  } else if (analysisObj.holeCardsObj.hasPositionOne) {
    score = 700
  }
  let obj = {}
  obj.score = score
  obj.handName = 'highcard'
  return obj

}

export function evalPair(gameObj, analysisObj, actOn) {

  let pairObj = {}
  if (actOn === 'highpairrank') {
    // when two pair evaluates one of its pairs
    pairObj.cardPairRank = analysisObj.rankObj.highpairrank
  } else if (actOn === 'lowpairrank') {
    // when two pair evaluates one of its pairs
    pairObj.cardPairRank = analysisObj.rankObj.lowpairrank
  } else {
    // single pair in hand
    pairObj.cardPairRank = analysisObj.rankObj.pairrank
    if (analysisObj.rankObj.pairrank === analysisObj.holeCardsObj.highcard.rank) {
      pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
    } else {
      pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.highcard.rank
    }
  }

  let holeCardsObj = analysisObj.holeCardsObj
  pairObj.pairPosition = 1 //best
  for(let i in analysisObj.boardObj.bestcardsArr) {
    if (typeof analysisObj.boardObj.bestcardsArr[i] === 'undefined') {
      continue
    }
    let boardCardRank = analysisObj.boardObj.bestcardsArr[i].rank
    if (boardCardRank > pairObj.cardPairRank) {
      pairObj.pairPosition++
    }
  }

  pairObj.ownedBy = 'board'
  if (actOn === 'highpairrank') {
    if (analysisObj.holeCardsObj.highcard.rank === analysisObj.rankObj.highpairrank) {
      pairObj.ownedBy = 'holecard'
      if (analysisObj.boardObj.isBoardPaired) {
        pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
      }
    }
    if (analysisObj.holeCardsObj.lowcard.rank === analysisObj.rankObj.highpairrank) {
      pairObj.ownedBy = 'holecard'
      if (analysisObj.boardObj.isBoardPaired) {
        pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
      }
    }
  } else if (actOn === 'lowpairrank') {
    if (analysisObj.holeCardsObj.lowcard.rank === analysisObj.rankObj.lowpairrank) {
      pairObj.ownedBy = 'holecard'
      if (analysisObj.boardObj.isBoardPaired) {
        pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.highcard.rank
      }
    }
    if (analysisObj.holeCardsObj.highcard.rank === analysisObj.rankObj.lowpairrank) {
      pairObj.ownedBy = 'holecard'
      if (analysisObj.boardObj.isBoardPaired) {
        pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
      }
    }
  } else {
    if (analysisObj.holeCardsObj.highcard.rank === analysisObj.rankObj.pairrank) {
      pairObj.ownedBy = 'holecard'
      pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.lowcard.rank
    } else if (analysisObj.holeCardsObj.lowcard.rank === analysisObj.rankObj.pairrank
    ) {
      pairObj.ownedBy = 'holecard'
      pairObj.nonPairedHoleCardRank = analysisObj.holeCardsObj.highcard.rank
    }
  }

  pairObj.nonPairedHoleCardPosition = Util.getCardPositionAgainstBoard(pairObj.nonPairedHoleCardRank, analysisObj)

  pairObj.pocketPair = false
  if (holeCardsObj.paired) {
    pairObj.pocketPair = true
  }
  pairObj.highcardPosition = analysisObj.holeCardsObj.highcard.position
  pairObj.lowcardPosition = analysisObj.holeCardsObj.lowcard.position

  if (actOn !== 'singlepair') {
    return pairObj
  }

  // pairN
  // Ace paired
  // Strong ace - Holecards are a pair of Aces
  // Strong ace - Holecard Ace pairs with the board and holecard kicker has a rank >= 10 and is position 1 HC: A,10 Board: A,8,4
  // -- if human has A,8 and computer has A,10, computer has outs to improve to a better two pair
  // Medium ace - Holecard Ace pairs with the board and holecard kicker is position 1 to non-paired board cards. HC: A,9 Board: A,5,8
  // Weak ace - Holecard Ace pairs with the board and holecard kicker is position 2 or less to non-paired board cards. HC: A,7 Board: A,5,8
  // top pair non-ace
  // Strong top pair - Holecards are a pair with a rank >= 10 and position 1 HC: 10,10 Board: 8,4,2
  // Strong top pair - Holecard pairs with the board position 1 and pair is rank >=10 and holecard kicker is position 1. HC: K,9 Board: 9,4,2
  // Weak top pair - Holecard pairs position 1 of any rank and holecard kicker is position 2 or less. HC: 9,7 Board: 9,4,2
  // middle pair
  // Strong middle pair - Holecard pairs with the board in position 2 and holecard kicker is position 1 HC: 8,11 Board: 8,6,9
  // Weak middle pair - Holecard pairs with the board in position 2 and holecard kicker is position 2 or less HC: 8,7 Board: 8,2,14
  // Bottom pair
  // Bottom pair - Holecard pairs with the board in position 3 or greater HC: J,9 Board:A,K,Q,9
  // Board paired

  // anything 500 or less is not so good
  // anything 600 is a good pair but not top pair
  // anything 700 or above is top pair
  let score = 0
  if (pairObj.pocketPair) {
    if (pairObj.cardPairRank === 14) {
      // pocket aces
      score = 1000
    } else if (pairObj.cardPairRank === 13 && pairObj.pairPosition === 1) {
      score = 950
    } else if (pairObj.cardPairRank === 12 && pairObj.pairPosition === 1) {
      score = 900
    } else if (pairObj.cardPairRank === 11 && pairObj.pairPosition === 1) {
      score = 850
    } else if (pairObj.cardPairRank <= 10 && pairObj.cardPairRank >= 8 && pairObj.pairPosition === 1) {
      score = 700
    } else if (pairObj.pairPosition === 1) {
      score = 700
    } else if (pairObj.cardPairRank === 13) {
      score = 600
    } else if (pairObj.cardPairRank === 12) {
      score = 600
    } else if (pairObj.cardPairRank === 11) {
      score = 600
    } else {
      score = 400
    }
  } else if (pairObj.ownedBy !== 'board') {
    if (pairObj.cardPairRank === 14) {
      // Ace paired, eval kicker
      if (holeCardsObj.lowcard.rank >= 10 && pairObj.nonPairedHoleCardPosition === 1) {
        // kicker is a 10 or higher and an overcard to board
        score = 950
      } else if (pairObj.nonPairedHoleCardPosition === 1) {
        // kicker is an overcard to the board
        score = 900
      } else if (pairObj.nonPairedHoleCardPosition === 2) {
        // kicker is in position 2 - paired card may be in position 1, maybe rethink
        score = 800
      } else {
        // kicker is an undercard to the board
        score = 700
      }
    } else if (pairObj.cardPairRank >= 11 && pairObj.pairPosition === 1) {
      score = 700
    } else if (pairObj.cardPairRank >= 6 && pairObj.pairPosition === 1) {
      score = 650
    } else if (pairObj.pairPosition === 2 && pairObj.nonPairedHoleCardPosition === 1) {
      // pair isn't top pair but kicker is top
      score = 450
    } else {
      score = 400
    }
  }

  pairObj.score = score
  pairObj.handName = 'pair'
  return pairObj

}

export function evalTwoPair(gameObj, analysisObj) {

  let twoPairObj = {}
  twoPairObj.boardPairOneObj = {}
  twoPairObj.boardPairTwoObj = {}
  twoPairObj.boardPairedOnce = false
  if (analysisObj.boardObj.rankObj.pair) {
    twoPairObj.boardPairedOnce = true
    twoPairObj.boardPairOneObj.rank = analysisObj.boardObj.rankObj.pairrank
  }
  twoPairObj.boardPairedTwice = false
  if (analysisObj.boardObj.rankObj.twopair) {
    twoPairObj.boardPairedTwice = true
    twoPairObj.boardPairOneObj.rank = analysisObj.boardObj.rankObj.highpairrank
    twoPairObj.boardPairTwoObj.rank = analysisObj.boardObj.rankObj.lowpairrank
  }
  twoPairObj.eachHoleCardPairedWithTheBoard = false
  if (twoPairObj.boardPairedOnce === false && twoPairObj.boardPairedTwice === false) {
    twoPairObj.eachHoleCardPairedWithTheBoard = true
  }
  twoPairObj.isPlayingTheBoard = false
  if (twoPairObj.boardPairedTwice) {
    twoPairObj.isPlayingTheBoard = true
    for (let i in analysisObj.bestcardsArr) {
      let cardRank = analysisObj.bestcardsArr[i].rank
      if (cardRank === analysisObj.rankObj.highpairrank || cardRank === analysisObj.rankObj.lowpairrank) {
        continue
      }
      if (analysisObj.holeCardsObj.highcard.rank === cardRank || analysisObj.holeCardsObj.lowcard.rank === cardRank) {
        // if either hole card is in the bestcardsarr, that means they are not playing the board
        twoPairObj.isPlayingTheBoard = false
      }
    }
  }
  twoPairObj.holeCardPairOneRank = false
  twoPairObj.holeCardPairTwoRank = false
  if (analysisObj.rankObj.highpairrank === analysisObj.holeCardsObj.highcard.rank ||
    analysisObj.rankObj.highpairrank === analysisObj.holeCardsObj.lowcard.rank) {
    twoPairObj.holeCardPairOneRank = analysisObj.rankObj.highpairrank
  }
  if (analysisObj.rankObj.lowpairrank === analysisObj.holeCardsObj.highcard.rank ||
    analysisObj.rankObj.lowpairrank === analysisObj.holeCardsObj.lowcard.rank) {
    twoPairObj.holeCardPairTwoRank = analysisObj.rankObj.lowpairrank
  }
  twoPairObj.areHoleCardsAPair = analysisObj.holeCardsObj.paired
  twoPairObj.isHoleCardTopPair = false
  if (analysisObj.rankObj.highpairrank === analysisObj.holeCardsObj.highcard.rank ||
    analysisObj.rankObj.highpairrank === analysisObj.holeCardsObj.lowcard.rank) {
    twoPairObj.isHoleCardTopPair = true
  }
  twoPairObj.boardHasHigherRankingCardThanTwoPair = false
  for(let i in analysisObj.boardObj.bestcardsArr) {
    if (typeof analysisObj.boardObj.bestcardsArr[i] === 'undefined') {
      continue
    }
    let boardCardRank = analysisObj.boardObj.bestcardsArr[i].rank
    if (boardCardRank === analysisObj.rankObj.highpairrank || boardCardRank === analysisObj.rankObj.lowpairrank) {
      continue
    }
    if (boardCardRank > analysisObj.rankObj.highpairrank) {
      twoPairObj.boardHasHigherRankingCardThanTwoPair = true
      break
    }
  }
  twoPairObj.holeCardHasHigherRankingCardThanTwoPair = false
  for(let i in analysisObj.bestcardsArr) {
    let cardRank = analysisObj.bestcardsArr[i].rank
    if (cardRank === analysisObj.rankObj.highpairrank || cardRank === analysisObj.rankObj.lowpairrank) {
      continue
    }
    if (cardRank > analysisObj.rankObj.highpairrank || cardRank > analysisObj.rankObj.lowpairrank) {
      twoPairObj.holeCardHasHigherRankingCardThanTwoPair = true
      break
    }
  }

  twoPairObj.numBoardUnpairedCardsWithHigherRankThanHoleCards = 0
  for(let i in analysisObj.boardObj.bestcardsArr) {
    if (typeof analysisObj.boardObj.bestcardsArr[i] === 'undefined') {
      continue
    }
    let cardRank = analysisObj.boardObj.bestcardsArr[i].rank
    if (cardRank === analysisObj.rankObj.highpairrank || cardRank === analysisObj.rankObj.lowpairrank) {
      continue
    }
    if (cardRank > analysisObj.holeCardsObj.highcard.rank && cardRank > analysisObj.holeCardsObj.lowcard.rank) {
      twoPairObj.numBoardUnpairedCardsWithHigherRankThanHoleCards++
      break
    }
  }

  twoPairObj.pairOneObj = evalPair(gameObj, analysisObj, 'highpairrank')
  twoPairObj.pairTwoObj   = evalPair(gameObj, analysisObj, 'lowpairrank')

  // two pair A
  // each holecard pairs with the board and makes top pair
  // each holecard pairs with the board and makes middle pair
  // each holecard pairs with the board and makes bottom pair
  // two pair B
  // holecards are top pair and board paired
  // holecards are middle pair and board paired
  // holecards are bottom pair and board paired
  // two pair C
  // hole card made top pair with board and board made a pair
  // hole card made middle pair with board and board made a pair
  // hole card made bottom pair with board and board made a pair
  let score = 0
  if (twoPairObj.eachHoleCardPairedWithTheBoard && !analysisObj.boardObj.rankObj.isBoardPaired) {
    score = 1000
  } else if (twoPairObj.eachHoleCardPairedWithTheBoard && analysisObj.boardObj.isBoardPaired) {
    // 6,7 - 6,7,10,10
    if (analysisObj.boardObj.rankObj.pairrank > twoPairObj.pairOneObj.cardPairRank
      && analysisObj.boardObj.rankObj.pairrank > twoPairObj.pairTwoObj.cardPairRank
    ) {
      score = 400
    } else if (analysisObj.boardObj.rankObj.pairrank < analysisObj.rankObj.highpairrank) {
      score = 500
    }
  } else if (twoPairObj.isPlayingTheBoard === false && twoPairObj.boardPairedTwice && analysisObj.holeCardsObj.hasAce) {
    // A, 2 - 6,6,8,8,10
    score = 200
  } else if (twoPairObj.isPlayingTheBoard) {
    score = 0
  } else if (analysisObj.boardObj.rankObj.pair) {
    // if the board paired, see if the holecard that paired is the higher ranking pair
    if (twoPairObj.pairOneObj.ownedBy === 'holecard' && twoPairObj.pairOneObj.cardPairRank > analysisObj.boardObj.rankObj.pairrank) {
      score = 600
    } else if (twoPairObj.pairTwoObj.ownedBy === 'holecard' && twoPairObj.pairTwoObj.cardPairRank > analysisObj.boardObj.rankObj.pairrank) {
      score = 600
    } else {
      score = 300
    }
  }

  twoPairObj.score = score
  twoPairObj.handName = 'two pair'
  return twoPairObj

}

// threeofakind
// top set
// middle set
// bottom set
// top trips strong kicker plays
// top trips weak kicker plays
// top trips kicker does not play
// middle trips strong kicker plays
// middle trips weak kicker plays
// middle trips kicker does not play
// bottom trips strong kicker plays
// bottom trips weak kicker plays
// bottom trips kicker does not play
export function evalThreeOfAKind(gameObj, analysisObj) {
  let obj = {}
  let score = 0
  if (analysisObj.boardObj.rankObj.threeofakind) {
    // three of a kind on the board
    score = 100
    obj.handName = 'board set'
    //Util.getCardPositionAgainstBoard(analysisObj.holeCardsObj.highcard.rank, analysisObj)
  } else if (analysisObj.holeCardsObj.paired) {
    // a set
    score = 1000
    obj.handName = 'set'
  } else {
    // trips
    score = 900
    obj.handName = 'trips'
  }

  obj.score = score
  return obj

}

export function evalStraight(gameObj, analysisObj) {

  let straightAssessment = require("./straightAssessment")
  let straightRankArr = analysisObj.bestcardsArr.map((obj) => {    return obj.rank  })
  let straightObj = straightAssessment.evaluateHoleCardsForStraight(analysisObj.holeCardsObj, straightRankArr, straightRankArr,analysisObj)
  let score = 0
  if (straightObj.bothHoleCards && straightObj.holeCardsAreTopPossible) {
    // A,K - Q,J,10 || Q,J - A,K,10
    score = 1000
  } else if (straightObj.bothHoleCards && straightObj.holeCardsAreTop) {
    // 10,9 - 8,7,6 || K,Q - J,10,9
    score = 1000
  } else if (straightObj.oneHoleCard && straightObj.holeCardIsTopPossible) {
    // A - K, Q,J,10 || Q - A,K,J,10
    score = 900
  } else if (straightObj.holeCardsAreTopAndMiddle) {
    score = 900
  } else if (straightObj.holeCardsAreTopAndBottom) {
    // 10,6 - 9,8,7
    score = 800
  } else if (straightObj.oneHoleCard && straightObj.holeCardIsTopEnd) {
    // 11 - 10,9,8,7
    score = 700
  } else if (straightObj.holeCardsAreMiddle) {
    // 9,8 - 10,7,6
    score = 700
  } else if (straightObj.bothHoleCards && straightObj.holeCardsAreMiddleAndBottom) {
    // Q,9 - 13,11,10
    score = 700
  } else if (straightObj.holeCardIsMiddle) {
    // 8 - 10,9,7,6
    score = 700
  } else if (straightObj.bothHoleCards && straightObj.holeCardsAreBottom) {
    // 8,7 - 11,10,9
    score = 700
  } else if (straightObj.holeCardIsBottom) {
    // 400 and below = card might come and there's a chance it will be counterfeited
    // 6 - 10,9,8,7
    score = 600
  } else if (analysisObj.drawObj.straightObj.isPlayingTheBoard) {
    score =  100
  }
  let obj = {}
  obj.straightObj = {...straightObj}
  obj.score = score
  obj.handName = 'straight'
  return obj
}

export function evalFlush(gameObj, analysisObj) {

  let score = 0
  let flushObj = analysisObj.drawObj.flushObj
  //let boardObj = analysisObj.boardObj
  //flushObj.isBoardPaired = boardObj.rankObj.isBoardPaired

  // 600 or above is good
  // 800 or above is great
  // having both hole cards suited can be more deceiving
  if (flushObj.isPlayingTheBoard) {
    score = 100
  } else if (flushObj.isHoleCardTopFlushCardPossible) {
    score = 1000
  } else if (!flushObj.isHoleCardTopFlushCardPossible && flushObj.numBoardSuitedContrib === 3) {
    if (flushObj.topFlushHoleCardRank === 13) {
      score = 900
    } else if (flushObj.topFlushHoleCardRank > 10 && flushObj.topFlushHoleCardPosition === 2) {
      score = 800
    } else {
      // 10s,9o - Ks,4s,7s
      score = 700
    }
  } else if (!flushObj.isHoleCardTopFlushCardPossible && flushObj.numBoardSuitedContrib === 4) {
    if (flushObj.topFlushHoleCardRank === 13) {
      score = 600
    } else if (flushObj.topFlushHoleCardRank > 10 && flushObj.topFlushHoleCardPosition === 2) {
      score = 400
    } else {
      // 6s,9o - Ks,4s,7s,10s
      score = 300
    }
  }

  let obj = {}
  obj.score = score
  obj.handName = 'flush'
  obj.flushObj = {...flushObj}
  if (obj.flushObj.variety === 'made flush') {
    delete obj.flushObj.outsArr
    delete obj.flushObj.holeFlushCardsArr
  }
  return obj

}

// fullhouse ranking A
// if it's a fullhouse with computer's hole card making three of a kind and the other hole card making a pair
// if it's a fullhouse with both computer's hole cards making a set and the board makes a pair
// if it's a fullhouse with one computer hole cards making trips and the board makes two pair
// if it's a fullhouse with one computer hole cards making a pair and the board making three of a kind
// fullhouse ranking B
// not sure if this level of granularity is needed, what's more important is the role hole cards play in the fullhouse
// if it's a fullhouse with top three of a kind
// if it's a fullhouse with middle three of a kind
// if it's a fullhouse with bottom three of a kind
// if it's a fullhouse with computer hole cards being a top pair
// if it's a fullhouse with computer hole cards being a middle pair
// if it's a fullhouse with computer hole cards being a bottom pair
export function evalFullHouse(gameObj, analysisObj) {

  let boardObj = analysisObj.boardObj
  let holeCardsObj = analysisObj.holeCardsObj
  let rankObj = analysisObj.rankObj
  let score = 0
  // 1000 is great
  // 500 and below, proceed with caution
  if (analysisObj.rankObj.isPlayingTheBoard) {
    score = 100
  } else if (holeCardsObj.paired && holeCardsObj.highcard.rank === analysisObj.rankObj.threeofakindrank) {
    // K,K - A,A,K
    score = 1000
  } else if (!holeCardsObj.paired && boardObj.rankObj.pair
    && (boardObj.rankObj.pairrank === holeCardsObj.highcard.rank || holeCardsObj.lowcard.rank === boardObj.rankObj.pairrank)) {
    // K,8 - K,8,K || K,8 - 8,K,8
    score = 1000
  } else if (holeCardsObj.paired && boardObj.rankObj.pair
    && (boardObj.rankObj.pairrank === holeCardsObj.highcard.rank || holeCardsObj.lowcard.rank === boardObj.rankObj.pairrank)) {
    // K,K - 8,8,K || 8,8 - 8,K,K
    score = 1000
  } else if (boardObj.rankObj.twopair) {
    // two pair on the board
    // did the holecard make the big three of a kind or the small three of a kind?
    if (rankObj.threeofakindrank > rankObj.pairrank && (rankObj.threeofakindrank === holeCardsObj.highcard.rank
      || rankObj.threeofakindrank === holeCardsObj.lowcard.rank)) {
      // K,7 - 9,9,K,K || A,9 - 9,9,7,7
      score = 1000
    } else {
      // 9,7 - 9,9,K,K
      score = 700
    }
  } else if (boardObj.rankObj.threeofakind && holeCardsObj.paired) {
    // K,K - 8,8,8 || 8,8 - K,K,K
    let position = Util.getCardPositionAgainstBoard(holeCardsObj.highcard.rank, analysisObj)
    if (position === 1) {
      // K,K - 8,8,8
      score = 700
    } else if (position === 2) {
      // 8,8 - K,K,K
      score = 500
    } else if (position === 3) {
      score = 300
    } else {
      score = 100
    }
  } else if (boardObj.rankObj.threeofakind && !holeCardsObj.paired) {
    // K,7 - 8,8,8,7 || 8,7 - K,K,K,8
    let cardPairRank = holeCardsObj.highcard.rank
    if (rankObj.pairrank === holeCardsObj.lowcard.pair) {
      cardPairRank = holeCardsObj.lowcard.rank
    }
    let position = Util.getCardPositionAgainstBoard(cardPairRank, analysisObj)
    if (position === 1) {
      score = 700
    } else if (position === 2) {
      score = 400
    } else if (position === 3) {
      score = 300
    } else {
      score = 100
    }
  }
  let obj = {}
  obj.score = score
  obj.handName = 'full house'
  return obj

}

// fourofakind
// two paired hole cards match the pair on the board
// one hole card matches three of the same on the board
// the board has four of a kind and an ace on the board
// the board has four of a kind and a holecard is a high kicker that plays
// the board has four of a kind and a holecard is a low kicker that plays
// the board has four of a kind and no holecards play
// board action that may lead to more calls: two pairs on the board, fullhouse on the board, flush draw, straight draw
export function evalFourOfAKind(gameObj, analysisObj) {

  let score = 0
  if (!analysisObj.rankObj.isPlayingTheBoard || (analysisObj.rankObj.isPlayingTheBoard && analysisObj.holeCardsObj.hasAce)) {
    score = 1000
  } else if (analysisObj.rankObj.isPlayingTheBoard && analysisObj.holeCardsObj.highcard.rank === 13) {
    score = 600
  } else if (analysisObj.rankObj.isPlayingTheBoard && analysisObj.holeCardsObj.highcard.rank === 12) {
    score = 500
  } else {
    score = 100
  }
  let obj = {}
  obj.score = score
  obj.handName = '4 of a kind'
  return obj

}

export function evalStraightFlush(gameObj, analysisObj) {

  let score = 0
  let flushObj = analysisObj.drawObj.flushObj
  let straightObj = analysisObj.drawObj.straightObj
  if (flushObj.isPlayingTheBoard && analysisObj.boardObj.bestcardsArr[0] !== 14) {
    // board made a straight flush but not broadway
    score = 100
  } else if (straightObj.holeCardIsBottom) {
    score = 700
  } else {
    score = 1000
  }
  let obj = {}
  obj.score = score
  obj.flushObj = {...flushObj}
  obj.straightObj = {...straightObj}
  obj.handName = 'straight flush'
  return obj

}

