function isPlayingTheBoard(arr, holeCardsObj) {
  if (!arr.includes(holeCardsObj.highcard.rank) && !arr.includes(holeCardsObj.lowcard.rank)) {
    return true
  }
  return false
}

function getSequences(rankArr, gameObj, isThreatAssessment, holeCardObj, isFlushStraightDrawAssessment, analysisObj) {

  let allSeqObjArr = []

  // Top to the bottom. Loop thru each sequence and add fill cards
  // if more than 2 fill cards and filled sequence less than 5, skip
  let fullObj = {}
  fullObj.allCardsArr = []
  fullObj.playerCardsArr = []
  let topToBottomArr = []
  for (let cardRank = 14; cardRank > 1; cardRank--) {
    topToBottomArr.push(cardRank)
  }
  fullObj.allCardsArr.push(topToBottomArr)
  fullObj.playerCardsArr.push(rankArr)
  // Bottom to the top.
  let bottomToTopArr = []
  for (let cardRank = 1; cardRank < 14; cardRank++) {
    bottomToTopArr.push(cardRank)
  }
  // set any ace from a rank of 14 to a rank of 1 to eval baby straight
  let tmpRankArr = rankArr.map((rank) => { return rank === 14 ? 1 : rank})
  fullObj.allCardsArr.push(bottomToTopArr)
  fullObj.playerCardsArr.push(tmpRankArr)
  let debug = 0
  for(let i = 0; i < fullObj.allCardsArr.length; i++) {
    let orderOfEval = i === 0 ? "top to bottom" : "bottom to top"
    let fillCardArr = []
    let origSeqArr = []
    let reset = false
    let arr = fullObj.allCardsArr[i]
    let handArr = fullObj.playerCardsArr[i]
    let j = 0
    let restartAtIndex = 0
    do {
      let cardRank = arr[j]
      let hasCard = handArr.includes(cardRank)
      if (!hasCard) {
        fillCardArr.push(cardRank)
      } else {
        origSeqArr.push(cardRank)
      }
      if (fillCardArr.length > 2) {
        if (debug) {
          let seqObj = {}
          seqObj.fillCardArr = [...fillCardArr]
          seqObj.origSeqArr = [...origSeqArr]
          seqObj.filledSeqArr = [...origSeqArr, ...fillCardArr]
          seqObj.orderOfEval = orderOfEval
          seqObj.failed = true
          seqObj.startedEvalAtCardRank = arr[restartAtIndex]
          seqObj.startedEvalAtIndex = restartAtIndex
          allSeqObjArr.push(seqObj)
        }
        reset = true
      } else if (origSeqArr.length + fillCardArr.length === 5) {
        let seqObj = initStraightObj()
        if (debug) {
          let restartAtCardRank = arr[restartAtIndex]
          seqObj.startedEvalAtCardRank = restartAtCardRank
          seqObj.startedEvalAtIndex = restartAtIndex
        }
        // see if sequence is open ended. if so, add fill card to appropriate end
        if (origSeqArr.length === 4) {
          origSeqArr.sort(function(a,b){return b - a})
          fillCardArr.sort(function(a,b){return b - a})
          let topOrigSeqCardRank = origSeqArr[0]
          let bottomOrigSeqCardRank = origSeqArr[origSeqArr.length - 1]
          let fillCardRank = fillCardArr[0]
          if (topOrigSeqCardRank + 1 === fillCardRank) {
            // fill card is for top end so add fill card to bottom of sequence
            if (bottomOrigSeqCardRank - 1 > 0) {
              fillCardArr.push(bottomOrigSeqCardRank - 1)
            }
          } else if (bottomOrigSeqCardRank - 1 === fillCardRank) {
            if (topOrigSeqCardRank + 1 < 15) {
              fillCardArr.push(topOrigSeqCardRank + 1)
            }
          }
        }
        seqObj.fillCardArr = [...fillCardArr]
        seqObj.origSeqArr = [...origSeqArr]
        seqObj.filledSeqArr = [...origSeqArr, ...fillCardArr]
        if (debug) {
          seqObj.orderOfEval = orderOfEval
        }
        let alreadyAdded = false
        for(let obj of allSeqObjArr) {
          if (JSON.stringify(obj.filledSeqArr) === JSON.stringify(seqObj.filledSeqArr)){
            alreadyAdded = true
          }
        }
        if (!alreadyAdded) {
          allSeqObjArr.push(seqObj)
        }
        reset = true
      } else {
        reset = false
      }
      if (reset) {
        // evaluate 14, 13, 12 and then 13,12,11 all the way to end
        let l1 = origSeqArr.length - 1
        let l2 = fillCardArr.length - 1
        if (origSeqArr.length === 4) {
          // additional fill card is added to fillCardArr to make open ended, so fillCardArr would have a length of 2
          // when it was only iterated over once. adjust here
          l2 = 0
        }
        restartAtIndex = j - (l1 + l2)
        j = restartAtIndex
        fillCardArr = []
        origSeqArr = []
      } else {
        j++
      }
    } while(j < 13)// j is the array index, not card rank. length is 13 instead of 14 because ace is only used once in each array
  }

  // set double gapped open ended straight draws as such. eg. 10,8,7,6,4 - a single 9 or a single 5 will complete the
  // straight so it is open ended
  for(let i in allSeqObjArr) {
    let objOne = allSeqObjArr[i]
    if (!debug && objOne.failed) {
      continue
    }
    if (objOne.origSeqArr.length < 4) {
      objOne.isOpenEndedDoubleGapped = false
      continue
    }
    objOne.origSeqArr.sort(function (a, b) {return b - a})
    objOne.origSeqArr = objOne.origSeqArr.slice(0, 5)
    for(let j in allSeqObjArr) {
      if (j === i) {
        continue
      }
      let objTwo = allSeqObjArr[j]
      if (!debug && objTwo.failed) {
        continue
      }
      if (objTwo.origSeqArr.length < 4) {
        objTwo.isOpenEndedDoubleGapped = false
        continue
      }
      let topOneCard = objOne.origSeqArr[0]
      // top card from objTwo
      let fillCardTwo = objTwo.fillCardArr[0]
      // top card from objOne
      let fillCardOne = objOne.fillCardArr[0]
      // Reminder: this only applies to origSeqArr with a length of 4
      // Requires 3 cards on the board and 2 hole cards or 5 cards on the board, so applies to all
      // 10,8,7,6,4 - 9 or 5
      // 10,8,7,6 - 9
      // 8,7,6,4 - 5
      // 10 - 5 = 5
      // 14,12,11,10,8 - 13 or 9
      // 14,12,11,10 - 13
      // 12,11,10,8 - 9
      // 14 - 9 = 5

      // Requires 6 cards - 4 board and 2 hole cards, so only applies to computer
      // 11,10, 8,7, 5,4 - 9 or 6
      // 11,10,8,7 - 9
      // 8,7,5,4 - 6
      // 9 - 6 = 3

      if (!isThreatAssessment || gameObj.turn === 'river') {
//        let tmp = 1
      }
      let r1 = Math.abs(fillCardOne - fillCardTwo)
      let r2 = Math.abs(topOneCard - 5)
      if (r2 === 5 || r1 === 3) {
        let fillCardArr = [objOne.fillCardArr[0], objTwo.fillCardArr[0]].sort((a,b) => {return b - a})
        objOne.doubleGappedOpenEndedFillCardArr = fillCardArr
        objTwo.doubleGappedOpenEndedFillCardArr = fillCardArr
        objOne.doubleGappedOpenEndedSeqArr = Array
          .from(new Set(objOne.origSeqArr.concat(objTwo.origSeqArr)))
          .sort(function (a, b) {return b - a})
        objTwo.doubleGappedOpenEndedSeqArr = Array
          .from(new Set(objOne.origSeqArr.concat(objTwo.origSeqArr)))
          .sort(function (a, b) {return b - a})
        objOne.doubleGappedOpenEndedFilledSeqArr = Array
          .from(new Set(objOne.origSeqArr.concat(objTwo.origSeqArr).concat(fillCardArr)))
          .sort(function (a, b) {return b - a})
        objTwo.doubleGappedOpenEndedFilledSeqArr = Array
          .from(new Set(objOne.origSeqArr.concat(objTwo.origSeqArr).concat(fillCardArr)))
          .sort( (a, b) => {return b - a})

        let topOfSeqArr = objOne.doubleGappedOpenEndedFilledSeqArr.slice(0,5)
        let bottomOfSeqArr = objOne.doubleGappedOpenEndedFilledSeqArr.slice(-5)

        if (!isThreatAssessment) {
          if ((topOfSeqArr.includes(holeCardObj.highcard.rank) || topOfSeqArr.includes(holeCardObj.lowcard.rank))
            && (bottomOfSeqArr.includes(holeCardObj.highcard.rank) || bottomOfSeqArr.includes(holeCardObj.lowcard.rank))) {
            // with holecards 4,3 the hand will play the board if an 8 fills 10,9,7,6
            objOne.isOpenEnded = true
            objTwo.isOpenEndedDoubleGapped = true
          }
        }
      }
    }
  }

  // select best straight draw
  let fSeqObjArr = []
  for(let obj of allSeqObjArr) {
    if (!debug && obj.failed) {
      continue
    }
    // let tmpObj = initStraightObj()
    // obj = { ...tmpObj, ...obj }
    obj.fillCardArr.sort(function (a, b) {return b - a})
    obj.numOuts = obj.fillCardArr.length * 4
    obj.origSeqArr.sort(function (a, b) {return b - a})
    obj.filledSeqArr.sort(function (a, b) {return b - a})
    obj.filledSeqArr = obj.filledSeqArr.slice(0, 5)
    let origSeq = obj.origSeqArr
    let origSeqL = origSeq.length
    if (isFlushStraightDrawAssessment && !isThreatAssessment) {
      //let tmp = 1
    }

    if (!isThreatAssessment) {
      if (origSeqL === 4 && gameObj.street !== 'river') {
        obj.isStraightDraw = true
        obj.isSingleCardDraw = true
      } else if (origSeqL === 3 && gameObj.street !== 'river' && gameObj.street !== 'turn') {
        obj.isTwoCardDraw = true
      }
    } else if (isThreatAssessment) {
      if (origSeqL === 3) {
        obj.isTwoCardDraw = true
        obj.isStraightDraw = true
        obj.isSingleCardDraw = false
      } else if (origSeqL === 4) {
        obj.isStraightDraw = true
        obj.isTwoCardDraw = false
        obj.isSingleCardDraw = true
      }
    }
    if (gameObj.street === 'turn') {
//      let tmp = 1
    }
    obj.isStraightDrawConsecutive = isStraightDrawConsecutive(obj)
    if (gameObj.street === 'turn') {
//      let tmp = 1
    }
    if (!obj.isOpenEndedDoubleGapped) {
      obj.isOpenEnded = isStraightDrawOpenEnded(obj)
    }
    obj.isStraight = (obj.origSeqArr.length === 5) ? true : false
    obj.isThreatAssessment = isThreatAssessment
    if (!isThreatAssessment) {
      obj.isPlayingTheBoard = isPlayingTheBoard(obj.filledSeqArr, holeCardObj)
    } else if (obj.isStraight && isThreatAssessment && obj.origSeqArr[0] === 14) {
      // only evaluating the board aka community cards for threat assessment and not human's holecards,
      // so the only way we can know if the human is playing the board is if the board is A,K,Q,J,10
      obj.isPlayingTheBoard = true
    }
    if (!isThreatAssessment && !obj.isPlayingTheBoard && obj.isSingleCardDraw === true) {
      let tmpObj = evaluateHoleCardsForStraight(holeCardObj, obj.filledSeqArr, obj.origSeqArr, analysisObj)
      obj = {...obj, ...tmpObj}
    }

    if (!isThreatAssessment && isFlushStraightDrawAssessment) {
      //console.log(obj)
    }
    fSeqObjArr.push(obj)
  }

  // set the best straight draw to a single object
  let bestDrawObj = initStraightObj()
  if (fSeqObjArr.length === 1) {
    bestDrawObj = fSeqObjArr[0]
  } else {
    let bestIndex = false
    let highestCard = 0
    for(let index in fSeqObjArr) {
      let obj = fSeqObjArr[index]
      if (obj.isSingleCardDraw && obj.origSeqArr[0] > highestCard && obj.isOpenEnded && !obj.isPlayingTheBoard) {
        bestIndex = index
        highestCard = obj.origSeqArr[0]
      }
    }
    // if no single card straight draw open ended, see if if single card straight draw
    for(let index in fSeqObjArr) {
      let obj = fSeqObjArr[index]
      if (obj.isSingleCardDraw && obj.origSeqArr[0] > highestCard && !obj.isPlayingTheBoard) {
        bestIndex = index
        highestCard = obj.origSeqArr[0]
      }
    }

    // if no single card straight draw, see if there isTwoCardDraw
    if (bestIndex === false) {
      highestCard = 0
      for(let index in fSeqObjArr) {
        let obj = fSeqObjArr[index]
        if (obj.isTwoCardDraw) {
          if (obj.filledSeqArr[0] > highestCard && !obj.isPlayingTheBoard) {
            bestIndex = index
            highestCard = obj.origSeqArr[0]
          }
        }
      }
    }
    if (bestIndex) {
      bestDrawObj = fSeqObjArr[bestIndex]
    }
  }

  if (isThreatAssessment) {
    //let tmp = 1
    //console.log("isThreatAssessment straight bestDrawObj", bestDrawObj)

  } else if(!isThreatAssessment && !isFlushStraightDrawAssessment) {
    //console.log("fSeqObjArr", fSeqObjArr)
    //console.log("bestDrawObj straightDrawAssessment", bestDrawObj)
  }
  return bestDrawObj

}

// when evaluating a made straight, origSeqArr won't be passed in and the fullSeqArr will hold the straight
// when evaluating a draw, the filledSeqArr will be passed in as fullSeqArr and origSeqArr will be passed in as origSeqArr
// when evaluating a made straight, use the fullSeqArr as the origSeqArr
export function evaluateHoleCardsForStraight(holeCardsObj, fullSeqArr, origSeqArr, analysisObj) {

  let obj = {}
  obj.bothHoleCards = false
  obj.oneHoleCard = false
  obj.holeCardsAreTopPossible = false
  obj.holeCardsAreTop = false
  obj.holeCardIsTopPossible = false
  obj.holeCardsAreTopAndBottom = false
  obj.holeCardsAreTopAndMiddle = false
  obj.holeCardsAreMiddle = false
  obj.holeCardsAreMiddleAndBottom = false
  obj.holeCardsAreBottom = false
  obj.holeCardIsTopEnd = false
  obj.holeCardIsMiddle = false
  obj.holeCardIsBottom = false
  obj.drawCardIsMiddle = false
  obj.drawCardIsTop = false
  obj.drawCardIsBottom = false

  // Single card draw sequences only. They may be open ended, but require only one card to make the straight
  // The fullSeqArr will have 5 cards
  // The origSeqArr will have only 4 cards that are in play. To evaluate holeCardIsTopPossible, origSeqArr must be
  // used as fullSeqArr may have a draw card that is actually the top <- TODO - doesn't seem right
  let highcard = holeCardsObj.highcard.rank
  let lowcard = holeCardsObj.lowcard.rank
  // if an Ace is in the sequence containing 2,3,4 or 5, then that Ace is lowcard with a rank of 1
  if (fullSeqArr.includes(1) && highcard === 14) {
    highcard = lowcard
    lowcard = 1
  }
  //let tmp = 1
  // handle the holecard already being on the board. eg. 7,4 - 4,5,8 - only the 7 holecard is in play with the straight draw
  if (analysisObj.boardObj.bestcardsRankArr.includes(highcard) && origSeqArr.includes(highcard)) {
    highcard = false
  }
  if (analysisObj.boardObj.bestcardsRankArr.includes(lowcard) && origSeqArr.includes(lowcard)) {
    lowcard = false
  }
  if (
    (highcard === 12 && lowcard === 11 && origSeqArr[0] === 14 && origSeqArr[1] === 13)
    || (highcard === 14 && lowcard === 11 && origSeqArr[1] === 13 && origSeqArr[3] === 12)
    || (highcard === 13 && lowcard === 11 && origSeqArr[0] === 14 && origSeqArr[3] === 12)
    || (highcard === 14 && lowcard === 12 && origSeqArr[1] === 13 && origSeqArr[4] === 11)
  ) {
    // Q,J - A,K || A,J - K,Q || K,J - A,Q || A,Q - K,J
    obj.bothHoleCards = true
    obj.holeCardsAreTopPossible = true
  } else if (highcard === origSeqArr[0] && lowcard === origSeqArr[1]) {
    // 10,9 - 8,7
    obj.bothHoleCards = true
    obj.holeCardsAreTop = true
  } else if ((highcard === 14 && origSeqArr.includes(13) && origSeqArr.includes(12) && origSeqArr.includes(11))
    || (highcard === 13 && origSeqArr.includes(14) && origSeqArr.includes(12) && origSeqArr.includes(11))
    || (highcard === 12 && origSeqArr.includes(14) && origSeqArr.includes(13) && origSeqArr.includes(11))
    || (highcard === 11 && origSeqArr.includes(14) && origSeqArr.includes(13) && origSeqArr.includes(11))
  ) {
    // A - K,Q,J || J - A,K,Q etc
    obj.oneHoleCard = true
    obj.holeCardIsTopPossible = true
  } else if ((lowcard === 1 && highcard === 2 && (fullSeqArr.includes(3) && fullSeqArr.includes(4) && fullSeqArr.includes(5)))
      || (lowcard === 1 && highcard === 3 && (fullSeqArr.includes(2) && fullSeqArr.includes(4) && fullSeqArr.includes(5)))
      || (lowcard === 1 && highcard === 4 && (fullSeqArr.includes(2) && fullSeqArr.includes(3) && fullSeqArr.includes(5)))
  ) {
    // fullSeqArr is used otherwise the use of || would be overwhelming if used with origSeqArr
    // A,2 - 3,4,5
    // A,3 - 2,4,5
    // A,4 - 2,3,5
    obj.bothHoleCards = true
    obj.holeCardsAreBottomAndMiddle = true
  } else if ((lowcard === 1 && origSeqArr.includes(2) && origSeqArr.includes(3) && origSeqArr.includes(4))
    || (lowcard === 1 && origSeqArr.includes(3) && origSeqArr.includes(4) && origSeqArr.includes(5))
    || (lowcard === 1 && origSeqArr.includes(2) && origSeqArr.includes(4) && origSeqArr.includes(5))
    || (lowcard === 1 && origSeqArr.includes(2) && origSeqArr.includes(3) && origSeqArr.includes(5))
  ) {
    // A,3 - 2,5,3
    // A,9 - 3,4,5
    // A,9 - 2,4,5
    // A,9 - 2,3,5
    obj.oneHoleCard = true
    obj.holeCardIsBottom = true
  } else if ((highcard === fullSeqArr[0]) && (lowcard === fullSeqArr[2] || lowcard === fullSeqArr[3])) {
    // 9,6 - 8,7 || 9,7 - 8,6 || 9,6 - 8,5
    obj.holeCardsAreTopAndMiddle = true
    obj.bothHoleCards = true
  } else if ((highcard === fullSeqArr[0]) && lowcard === fullSeqArr[4]) {
    // 9,5 - 8,7,6
    obj.holeCardsAreTopAndBottom = true
    obj.bothHoleCards = true
  } else if (highcard === fullSeqArr[0] || lowcard === fullSeqArr[0]) {
    // K,9 - 8,7,6,5
    obj.oneHoleCard = true
    obj.holeCardIsTopEnd = true
  } else if ((highcard !== fullSeqArr[0] && lowcard !== fullSeqArr[4] && fullSeqArr.includes(highcard)
    && fullSeqArr.includes(lowcard))
  ) {
    // 8,6 - 9,7,5 || 8,7 - 9,6,5 || 7,6 - 9,8,5
    obj.holeCardsAreMiddle = true
    obj.bothHoleCards = true
  } else if (highcard === fullSeqArr[3] && lowcard === fullSeqArr[4]) {
    // 6,5 - 9,8,7
    obj.bothHoleCards = true
    obj.holeCardsAreBottom = true
  } else if ((highcard === fullSeqArr[1] || highcard === fullSeqArr[2] || highcard === fullSeqArr[3])
    && (lowcard === fullSeqArr[2] || lowcard === fullSeqArr[3] || lowcard === fullSeqArr[4])
  ) {
    // 7,4 - 3,8,5
    obj.bothHoleCards = true
    obj.holeCardsAreMiddleAndBottom = true
  } else if ((highcard === fullSeqArr[1] || highcard === fullSeqArr[2] || highcard === fullSeqArr[3])
    || (lowcard === fullSeqArr[1] || lowcard === fullSeqArr[2] || lowcard === fullSeqArr[3])
  ) {
    // 8 - 9,7,6,5
    obj.oneHoleCard = true
    obj.holeCardIsMiddle = true
  } else if ((highcard === fullSeqArr[4] && !fullSeqArr.includes(lowcard))
    || (lowcard === fullSeqArr[4] && !fullSeqArr.includes(highcard))) {
    // one hole card makes the bottom of the straight
    obj.oneHoleCard = true
    obj.holeCardIsBottom = true
  }

  return obj
}

function isStraightDrawOpenEnded(obj) {
  // test if original sequence is consecutive values with a fill card on the top or bottom. If so, it is open ended
  let topCard = obj.origSeqArr[0]
  let startCard = topCard + 1
  let bottomCard = obj.origSeqArr[obj.origSeqArr.length - 1]
  if (topCard === 14) {
    return false
  }
  if (bottomCard === 1) {
    return false
  }
  let isDescConsec = obj.origSeqArr.reduce((acc, curVal) => {
    let testVal = acc - 1
    return (acc === false || testVal !== curVal)
      ? false
      : curVal
  }, startCard);
  if (isDescConsec !== false) {
    return true
  }
  return false
}


function isStraightDrawConsecutive(obj) {
  // test if original sequence is consecutive values.
  let startCard = obj.origSeqArr[0] + 1
  let isDescConsec = obj.origSeqArr.reduce((acc, curVal) => {
    let testVal = acc - 1
    return (acc === false || testVal !== curVal)
      ? false
      : curVal
  }, startCard);
  if (isDescConsec !== false) {
    return true
  }
  return false
}

export function initStraightObj() {
  return {
    "numOuts": 0,
    "isStraightDraw": false,
    "isOpenEnded" : false,
    "isSingleCardDraw": false,
    "isTwoCardDraw": false,
    "isStraight": false,
    "isThreatAssessment": false,
    "isPlayingTheBoard": false
  }
}

// rankArr must be in descending order
export function Assessment(
  rankArr,
  isFlushStraightDrawAssessment,
  flushSuit,
  isThreatAssessment,
  gameObj,
  holeCardsObj,
  analysisObj
) {

  // single one way - A,K,Q,J or 4,3,2,A
  // single open ended 8,7,6,5
  // single gapped - 10,9, 7,6
  // open ended - 3,4,5,6
  // double gapped - 7,9, 11 or 7,8, 11
  // open ended gapped - 3,4, 6,7, 9,10
  // runner runner 3,4,5
  // runner runner one way A, 2, 3 or A, K, Q

  let seqObj
  if (isThreatAssessment) {
    seqObj = getSequences(rankArr, gameObj, isThreatAssessment, {}, isFlushStraightDrawAssessment, analysisObj)
    //console.log("threatObj", seqObj)
  } else {
    if (isFlushStraightDrawAssessment) {
      //let tmp = 1
    }
    seqObj = getSequences(rankArr, gameObj, isThreatAssessment, holeCardsObj, isFlushStraightDrawAssessment, analysisObj)
    if (seqObj.isStraightDraw) {
      seqObj.outsArr = []
      for(let i in seqObj.fillCardArr) {
        if (!isFlushStraightDrawAssessment && seqObj.numOuts > 0) {
          seqObj.outsArr.push({ rank: seqObj.fillCardArr[i], suit: "spade" })
          seqObj.outsArr.push({ rank: seqObj.fillCardArr[i], suit: "diamond" })
          seqObj.outsArr.push({ rank: seqObj.fillCardArr[i], suit: "club" })
          seqObj.outsArr.push({ rank: seqObj.fillCardArr[i], suit: "heart" })
        } else if (isFlushStraightDrawAssessment && seqObj.numOuts > 0) {
          seqObj.outsArr.push({rank: seqObj.fillCardArr[i], suit: flushSuit})
        }
      }
    }
    seqObj.isFlushStraightDrawAsssessment = isFlushStraightDrawAssessment
    //console.log("seqObj", seqObj)
  }

  return seqObj

}