import actionsbase from "./actionsbase"

export default class highcard extends actionsbase {

  mngAllIn() {
    let actObj = {}
    if (this.hopeFlushScore >= this.hopeFlushScoreTh && this.gameObj.street === 'flop') {
      actObj = {action:'call'}
    } else if (this.hopeStraightScore >= this.hopeStraightScoreTh && this.threatFlushScore < this.threatFlushScoreTh) {
      actObj = {action:'call'}
    } else if (this.hopeDrawThCrossed && !this.threatDrawThCrossed) {
      actObj.action = 'call'
    } else {
      actObj.action = 'fold'
    }
    return actObj
  }

  // START FLOP
  //
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.safeCall && this.hasAHopeDraw) {
        actObj.action = 'call'
      } else if (this.handScore >= this.adjHighcardScoreTh && !this.threatDrawThCrossed) {
        actObj = { action: 'call'  }
      }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.hopeDrawThCrossed) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      } else {
        actObj.action = 'check'
      }
    } else { //canReraise || canCall
      actObj.action = 'fold'
      if (this.hasAHopeDraw && this.safeCall) {
        actObj.action = 'call'
      } else if (this.hopeDrawThCrossed && this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }
  //
  // END FLOP

  // START TURN
  //
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.handScore >= this.adjHighcardScoreTh && !this.threatDrawThCrossed) {
        actObj = { action: 'call'  }
      } else if (this.hopeDrawThCrossed && this.safeCall) {
        actObj = { action: 'call'  }
      }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.hopeDrawThCrossed) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      } else {
        actObj.action = 'check'
      }
    } else { //canReraise || canCall
      actObj.action = 'fold'
      if (this.hasAHopeDraw && this.safeCall) {
        actObj.action = 'call'
      }

    }
    return actObj
  }
  //
  // END TURN
  
  // START RIVER
  //
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (!this.threatDrawThCrossed && this.holeCardsObj.hasAce && this.safeCall) {
        actObj = { action: 'call' }
      }
    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj.action = 'check'
    } else { //canReraise || canCall
      actObj.action = 'fold'
      if (this.holeCardsObj.hasAce && !this.threatDrawThCrossed && this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }
  //
  // END RIVER

}

