import preflop from "./streetactions/preflop"
import highcard from "./streetactions/highcard"
import pair from "./streetactions/pair"
import twopair from "./streetactions/twopair"
import threeofakind from "./streetactions/threeofakind"
import straight from "./streetactions/straight"
import flush from "./streetactions/flush"
import fullhouse from "./streetactions/fullhouse"
import fourofakind from "./streetactions/fourofakind"
import straightflush from "./streetactions/straightflush"

const decisionDataModule = require("../helpers/decisionData")
const Util = require("../helpers/util")
const Chips = require("../helpers/chips")
const Debug = require("../helpers/debug")

const debugFile = 0
const debugPlayer = 'computer'
const debugFinalizeActionResultObj = 0

export function get(analysisObj, gameObj) {

  let street = gameObj.street
  let actionResultObj = {}
  let ddObj = decisionDataModule.getDecisionDataObj(gameObj, analysisObj)

  let isFirstToAct
  let condSec = Util.getHumanCategory(gameObj) + " "
  let actionObj

  if (street === 'preflop') {

    // small blind goes first preflop
    isFirstToAct = gameObj.bigBlindPlayer === 'computer' ? false : true
    ddObj.handEvalObj = analysisObj.holeCardsObj
    actionObj = new preflop(gameObj, analysisObj, ddObj, isFirstToAct)
    if (gameObj.isHumanHandActionDone) {
      condSec+= 'allIn preflop'
      actionResultObj = actionObj.mngAllIn()
    }

  } else {

    isFirstToAct = gameObj.bigBlindPlayer === 'computer' ? true : false
    let handEvalModules = require('./handEvalModules')
    if (analysisObj.rankObj.straightflush) {
      ddObj.handEvalObj = handEvalModules.evalStraightFlush(gameObj, analysisObj)
      actionObj = new straightflush(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.fourofakind) {
      ddObj.handEvalObj = handEvalModules.evalFourOfAKind(gameObj, analysisObj)
      actionObj = new fourofakind(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.fullhouse) {

      if (gameObj.street === 'river') {
        //let tmp = 1
      }
      ddObj.handEvalObj = handEvalModules.evalFullHouse(gameObj, analysisObj)
      actionObj = new fullhouse(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.flush) {
      ddObj.handEvalObj = handEvalModules.evalFlush(gameObj, analysisObj)
      actionObj = new flush(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.straight) {
      ddObj.handEvalObj = handEvalModules.evalStraight(gameObj, analysisObj)
      actionObj = new straight(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.threeofakind) {
      ddObj.handEvalObj = handEvalModules.evalThreeOfAKind(gameObj, analysisObj)
      actionObj = new threeofakind(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.twopair) {
      ddObj.handEvalObj = handEvalModules.evalTwoPair(gameObj, analysisObj)
      actionObj = new twopair(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.pair) {
      ddObj.handEvalObj = handEvalModules.evalPair(gameObj, analysisObj, 'singlepair')
      actionObj = new pair(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else if (analysisObj.rankObj.highcard) {
      ddObj.handEvalObj = handEvalModules.evalHighcard(gameObj, analysisObj)
      if (gameObj.street === 'turn') {
        //let tmp = 1
      }
      actionObj = new highcard(gameObj, analysisObj, ddObj, isFirstToAct)
      if (gameObj.isHumanHandActionDone) {
        actionResultObj = actionObj.mngAllIn()
      }
    } else {
      throw new Error("invalid hand eval")
    }
  }

  // allIn handled above
  if (gameObj.isHumanHandActionDone) {
    analysisObj = buildStreetActionArr(gameObj, analysisObj, 'allIn', ddObj, actionObj)
    if (typeof actionResultObj === 'undefined' || typeof actionResultObj.action === 'undefined') {
      let errStr = gameObj.street + " isFirstToAct: " + isFirstToAct + " actionResultObj: " + JSON.stringify(actionResultObj)
      if (analysisObj.rankObj.besthand) {
        errStr+= " " + analysisObj.rankObj.besthand
      }
      throw new Error("A invalid actionResultObj " +  errStr)
    }
    actionResultObj = finalizeActionResultObj(actionResultObj, analysisObj, gameObj, ddObj)
    if (typeof actionResultObj === 'undefined' || typeof actionResultObj.action === 'undefined' || typeof actionResultObj.betAmount === 'undefined') {
      let errStr = gameObj.street + " isFirstToAct: " + isFirstToAct + " betAmount: " + JSON.stringify(actionResultObj)
      if (analysisObj.rankObj.besthand) {
        errStr+=analysisObj.rankObj.besthand
      }
      throw new Error("B invalid actionResultObj " +  errStr)
    }
    return actionResultObj
  }

  ///////////////////////////////////////////
  //
  if (isFirstToAct) {
    // computer is first to act
    if (street === 'preflop') {
      condSec+= 'preflopFirstToAct'
      //console.log(condSec)
      actionResultObj = actionObj.preflopFirstToAct()
    } else if (street === 'flop') {
      //console.log("A flop")
      condSec+= 'flopFirstToAct'
      actionResultObj = actionObj.flopFirstToAct()
    } else if (street === 'turn') {
      condSec+= 'turnFirstToAct'
      //console.log("B turn")
      actionResultObj = actionObj.turnFirstToAct()
    } else {
      condSec+= 'riverFirstToAct'
      //console.log("C river")
      actionResultObj = actionObj.riverFirstToAct()
    }
  } else if (!isFirstToAct) {
    // computer bet and human reraised
    if (street === 'preflop') {
      condSec+= 'preflopNotFirstToAct'
      //console.log(condSec)
      actionResultObj = actionObj.preflopNotFirstToAct()
    } else if (street === 'flop') {
      condSec+= 'flopNotFirstToAct'
      //console.log("D " + street)
      actionResultObj = actionObj.flopNotFirstToAct()
    } else if (street === 'turn') {
      condSec+= 'turnNotFirstToAct'
      //console.log("E " + street)
      actionResultObj = actionObj.turnNotFirstToAct()
    } else {
      condSec+= 'riverNotFirstToAct'
      //console.log("F " + street)
      actionResultObj = actionObj.riverNotFirstToAct()
    }
  }
  if (typeof actionResultObj === 'undefined' || typeof actionResultObj.action === 'undefined') {
    throw new Error("B invalid actionResultObj " + gameObj.street + " isFirstToAct: " + isFirstToAct + " " + analysisObj.rankObj.besthand)
  }
  //
  ////////////////

  analysisObj = buildStreetActionArr(gameObj, analysisObj, condSec, ddObj, actionObj)
  if (typeof actionResultObj === 'undefined' || typeof actionResultObj.action === 'undefined') {
    let errStr = gameObj.street + " isFirstToAct: " + isFirstToAct + " actionResultObj: " + JSON.stringify(actionResultObj)
    if (analysisObj.rankObj.besthand) {
      errStr+=analysisObj.rankObj.besthand
    }
    throw new Error("C invalid actionResultObj " +  errStr)
  }
  actionResultObj = finalizeActionResultObj(actionResultObj, analysisObj, gameObj, ddObj)
  if (typeof actionResultObj === 'undefined' || typeof actionResultObj.action === 'undefined' || typeof actionResultObj.betAmount === 'undefined') {
    let errStr = gameObj.street + " isFirstToAct: " + isFirstToAct + " actionResultObj: " + JSON.stringify(actionResultObj)
    if (analysisObj.rankObj.besthand) {
      errStr+=analysisObj.rankObj.besthand
    }
    throw new Error("D invalid actionResultObj " +  errStr)
  }

  return actionResultObj

}

// set validate and set bet amount if needed
function finalizeActionResultObj(actionResultObj, analysisObj, gameObj, ddObj) {

  let debugFunc = debugFinalizeActionResultObj | debugFile

  let betObj = gameObj.betObj

  if (debugFunc)Debug.log(gameObj, debugPlayer, "START finalizeActionResultObj()", actionResultObj)

  if (typeof actionResultObj.betAmount === 'undefined') {
    if (actionResultObj.action === 'allIn') {
      actionResultObj.betAmount = betObj.maxRaiseAmount
      if (debugFunc)Debug.log(gameObj, debugPlayer, "finalizeActionResultObj() A actionResultObj", actionResultObj)
    } else if (actionResultObj.action === 'check' || actionResultObj.action === 'fold') {
      actionResultObj.betAmount = 0
      if (debugFunc)Debug.log(gameObj, debugPlayer, "finalizeActionResultObj() B actionResultObj", actionResultObj)
    } else if (actionResultObj.action === 'call') {
      actionResultObj.betAmount = betObj.minCallAmount
      if (debugFunc)Debug.log(gameObj, debugPlayer, "finalizeActionResultObj() C actionResultObj", actionResultObj)
    } else if (actionResultObj.action === 'smallBlindCall') {
      actionResultObj.betAmount = betObj.preflopSmallBlindCallAmount
      if (debugFunc)Debug.log(gameObj, debugPlayer, "finalizeActionResultObj() D actionResultObj", actionResultObj)
    }
  }
  if (gameObj.playerBetTurn === 'computer') {
    //let t = 1
  }

  if (gameObj.computerChips > 0 && (actionResultObj.action === 'raise' || actionResultObj.action === 'allIn')) {
    // make sure computer isn't betting more chips than human has and other limits
    actionResultObj.betAmount = Chips.limitBetAmount(actionResultObj.betAmount, gameObj)
    if (debugFunc)Debug.log(gameObj, debugPlayer, "finalizeActionResultObj() E betAmount", actionResultObj.betAmount)

  }
  if (gameObj.street === 'river' || gameObj.street === 'turn') {
    //let tmp = 1
  }

  actionResultObj.analysisObj = analysisObj
  actionResultObj.analysisObj.ddObj = ddObj

  if (debugFunc)Debug.log(gameObj, debugPlayer, "END finalizeActionResultObj()", actionResultObj.action, actionResultObj.betAmount)

  return actionResultObj
}

function buildStreetActionArr(gameObj, analysisObj, condSec, ddObj, actionObj) {
  if (!analysisObj.streetActionArr) {
    analysisObj.streetActionArr = []
  }
  let bestHand = analysisObj.holeCardsObj.highcard.rank + analysisObj.holeCardsObj.highcard.suit + "_" +
    analysisObj.holeCardsObj.lowcard.rank + analysisObj.holeCardsObj.lowcard.suit
  if (analysisObj.rankObj.besthand) {
    bestHand = analysisObj.rankObj.besthand
  }

  let str =condSec + " " + bestHand
  if (ddObj.threatObj) {
    str+="<br/>HUMAN "
  }
  let drawDeadStrtScore = false
  let drawDeadFlushScore = false
  if (ddObj.threatObj && ddObj.threatObj.drawDeadStrtScore) {
    drawDeadStrtScore = true
    str+=" DD straight: " + ddObj.threatObj.drawDeadStrtScore
  }
  if (ddObj.threatObj && ddObj.threatObj.drawDeadFlushScore) {
    drawDeadFlushScore = true
    str += " DD flush:" + ddObj.threatObj.drawDeadFlushScore
  }
  if (ddObj.threatObj && !drawDeadFlushScore) {
    str += " flush:" + ddObj.threatObj.flushScore
  }
  if (ddObj.threatObj && !drawDeadStrtScore) {
    str+=" straight: " + ddObj.threatObj.straightScore
  }
  if (ddObj.threatObj && ddObj.threatObj.pairedBoardScore) {
    str+=" boardPaired:" + ddObj.threatObj.pairedBoardScore
  }
  if (ddObj.hopeObj) {
    str += "<br/> COMPU flush:" + ddObj.hopeObj.flushScore +
      " straight: " + (typeof ddObj.hopeObj.straightScore === 'undefined' ? 'made' : ddObj.hopeObj.straightScore)
  }
  str+= '<br/>Computer hand score: ' + ddObj.handEvalObj.score
  if (gameObj.street !== 'preflop') {
    // preflop uses an adjusting array of holecard score values
    str += " must be >=: "
    let handName = ddObj.handEvalObj.handName
    if (handName === 'highcard') {
      str += actionObj.adjHighcardScoreTh + " orig:" + actionObj.highcardScoreTh
    } else if (handName === 'pair') {
      str += actionObj.adjPairScoreTh + " orig:" + actionObj.pairScoreTh
    } else if (handName === 'two pair') {
      str += actionObj.adjTwopairScoreTh + " orig:" + actionObj.twopairScoreTh
    } else if (handName === 'set' || handName === 'trips' || handName === 'board set') {
      str += actionObj.adjThreeofakindScoreTh + " orig:" + actionObj.threeofakindScoreTh
    } else if (handName === 'straight') {
      str += actionObj.adjStraightScoreTh + " orig:" + actionObj.straightScoreTh
    } else if (handName === 'flush') {
      str += actionObj.adjFlushScoreTh + " orig:" + actionObj.flushScoreTh
    } else if (handName === 'full house') {
      str += actionObj.adjFullhouseScoreTh + " orig:" + actionObj.fullhouseScoreTh
    } else if (handName === '4 of a kind') {
      str += actionObj.adjFourofakindScoreTh + " orig:" + actionObj.fourofakindScoreTh
    } else if (handName === 'straight flush') {
      str += actionObj.adjStraightflushScoreTh + " orig:" + actionObj.straightScoreTh
    }
  }

  analysisObj.streetActionArr.push(str)
  return analysisObj
}



