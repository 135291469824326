import actionsbase from "./actionsbase"

export default class threeofakind extends actionsbase {
  
  mngAllIn() {
    let actObj = {}

    if (this.boardRankObj.threeofakind) {
      actObj = this.playingTheBoard()
      return actObj
    } else if (this.boardRankObj.threeofakind) {
      // three of a kind on the board
      actObj.action = 'fold'
    } else if (this.threatFlushScore >= this.threatFlushScoreTh) {
      // This folds if 4 of the same suit are on the board at the turn or river
      actObj.action = 'fold'
    } else {
      // if wrong, hope for a fullhouse by the river
      actObj.action = 'call'
    }
    return actObj
  }

  playingTheBoard() {
    if (this.street === 'flop') {
      //let tmp = 1
    }
    let obj = {}
    let minCallPercOfStack = this.computerMinCallAmountPercOfStack
    let minCallPercOfPot = this.computerMinCallAmountPercOfPot
    let humanHandAggressionScore = this.humanHandAggressionScore
    let humanCategoryIndex = this.humanCategoryIndex
    let smallBet = minCallPercOfStack <= .02 && minCallPercOfPot <= .2
    let tightHumanMildHand = humanHandAggressionScore <= 500 && humanCategoryIndex <= 100
    let maniac = humanCategoryIndex >= 400
    if (this.holeCardsObj.hasAce && smallBet && (tightHumanMildHand || maniac)) {
      // board has 3 of a kind, compu holecard A
      // small bet against either tight human with mild hand aggression or a loose player
      obj = {action: 'call' }
    } else if (this.holeCardsObj.hasKing && minCallPercOfStack <= .01 && (tightHumanMildHand || maniac)) {
      // board has 3 of a kind, comp holecard K
      // small bet against either tight human with mild hand aggression or a loose player
      obj = {action: 'call' }
    } else {
      obj.action = 'fold'
    }
    return obj
  }

  // given the size of the bet, the size of pot, the player category, the hand aggression level
  // is making a bet when the likelihood of losing the right thing to do?
  // 3 of a kind on the board, 4 suited on the board, two pair on the board, pair on the board, 4 card open ended straight on the board...
  dangerDecision() {

    let minCallPercOfStack = this.computerMinCallAmountPercOfStack
    let minCallPercOfPot = this.computerMinCallAmountPercOfPot
    let smallBet = minCallPercOfStack <= .02 && minCallPercOfPot <= .2
    let humanCategoryIndex = this.humanCategoryIndex
    let humanHandAggressionScore = this.humanHandAggressionScore
    let tightHumanMildHand = humanHandAggressionScore <= 500 && humanCategoryIndex <= 100
    let maniac = humanCategoryIndex >= 400

    if (this.street === 'turn' ) {
      return {action:'call'}
    } else if (this.street === 'river' && smallBet && (tightHumanMildHand || maniac)) {
      return {action:'call'}
    }
    return {action:'fold'}

  }


// START FLOP
//
  flopFirstToAct() {
    let actObj = {}

    if (this.canRaise || this.canCheck) {
      if (this.handScore < this.adjThreeofakindScoreTh) {
        actObj = { action: 'check' }
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'raise', betAmount:this.bestRaiseAmount }
      } else if (this.handScore === this.adjThreeofakindScoreTh) {
        actObj = {action: 'check'}
      } else {
        actObj = {action: 'check'}
      }
    } else { //canReraise || canCall
      actObj = {action: 'fold'}
      if (this.boardRankObj.threeofakind) {
        actObj = this.playingTheBoard()
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        if (this.humanHandAggressionScore >= 500 && this.humanCategoryIndex >= 400) {
          // let the maniac keep betting at computer
          actObj = {action:"call"}
        } else {
          actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.boardRankObj.threeofakind) {
        actObj = { action: 'check' }
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        actObj = {action: 'check'}
      }
    } else { //canReraise || canCall
      actObj.action = 'fold'
      if (this.boardRankObj.threeofakind) {
        actObj = this.playingTheBoard()
      } if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn'}
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        if (this.humanHandAggressionScore >= 500 && this.humanCategoryIndex >= 400) {
          // let the maniac keep betting at computer
          actObj = {action:"call"}
        } else {
          actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      }
    }
    return actObj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj.action = 'check'
      if (this.boardRankObj.threeofakind) {
        actObj = { action: 'check' }
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = {action:'check'}
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn'}
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        actObj = {action: 'raise', betAmount:this.bestRaiseAmount}
      }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.boardRankObj.threeofakind) {
        actObj = this.playingTheBoard()
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = this.dangerDecision()
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        if (this.humanHandAggressionScore >= 500 && this.humanCategoryIndex >= 400) {
          // let the maniac keep betting at computer
          actObj = {action:"call"}
        } else {
          actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.boardRankObj.threeofakind) {
        actObj = { action: 'check' }
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = {action:'check'}
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'allIn'}
      } else {
        actObj = {action: 'raise', betAmount:this.bestRaiseAmount}
      }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.boardRankObj.threeofakind) {
        actObj = this.playingTheBoard()
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = this.dangerDecision()
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        if (this.humanHandAggressionScore >= 500 && this.humanCategoryIndex >= 400) {
          // let the maniac keep betting at computer
          actObj = {action:"call"}
        } else {
          actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
        }
      }
    }
    return actObj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.boardRankObj.threeofakind) {
        actObj = { action: 'check' }
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = {action:'check'}
      } else if (this.threatDrawThCrossed) {
        actObj = { action: 'check'}
      } else {
        actObj = {action: 'raise', betAmount:this.minRaiseAmount}
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold'}
      if (this.handScore === 100) {
        actObj = this.playingTheBoard()
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = this.dangerDecision()
      } else if (this.threatDrawThCrossed) {
        actObj = { action: 'call', betAmount:this.minCallAmount }
      } else if (this.handScore >= 900) {
        actObj = {action:"allIn"}
      }
    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'fold'}
      if (this.boardRankObj.threeofakind) {
        actObj = { action: 'check' }
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = this.dangerDecision()
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'check' }
      } else if (this.handScore >= 900) {
        actObj = { action: 'raise', betAmount:this.bestRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold'}
      if (this.boardRankObj.threeofakind) {
        actObj = this.playingTheBoard()
      } else if (this.threatStraightScore === 1000 || this.threatFlushScore === 1000) {
        actObj = this.dangerDecision()
      } else if (this.handScore >= this.adjThreeofakindScoreTh && this.threatDrawThCrossed) {
        actObj = { action: 'call'  }
      } else if (this.handScore >= this.adjThreeofakindScoreTh) {
        actObj = {action:"allIn"}
      }
    }
    return actObj
  }
//
// END RIVER
  
}