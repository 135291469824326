const Util = require("../helpers/util")
const Debug = require("../helpers/debug")

const debugFile = 0
const debugGetBetObj = 0
const debugGetMinCallAmount = 0
const debugGetMinRaiseAmount = 0
const debugGetMaxRaiseAmount = 0
const debugGetDisplayBetAmount = 0
const debugLimitBetAmount = 0
const debugMaximizeBetAmount = 0
let debugPlayer = 'computer'

export function getBetObj(gameObj) {

  let debugFunc = debugGetBetObj | debugFile

  let betObj = {
    minCallAmount: 0,
    minCallAmountIsMax: false,// if after making min call there are no more chips, than this is true
    minCallAmountIsSmallBlind: false,
    preflopSmallBlindCallAmount: gameObj.preflopSmallBlindCallAmount,
    preflopSmallBlindMinRaiseAmount: gameObj.preflopSmallBlindMinRaiseAmount,
    minRaiseAmount: 0,
    minRaiseAmountIsMax: false,
    maxRaiseAmount: 0,
    action: ''
  }

  try {
    if (debugFunc) Debug.log(gameObj, debugPlayer, "START Chips playerBetTurn:", gameObj.playerBetTurn)
    betObj = getMinCallAmount(betObj, gameObj)
    betObj = getMinRaiseAmount(betObj, gameObj)
    betObj = getMaxRaiseAmount(betObj, gameObj)
    if (debugFunc) Debug.log(gameObj, debugPlayer, "END Chips playerBetTurn", gameObj.playerBetTurn)
  } catch(err) {
    throw new Error ("getBetObj" + JSON.stringify(err.message))
  }

  return betObj

}


// This is for getting what the minimum to call is
export function getMinCallAmount(betObj, gameObj) {

  let debugFunc = debugGetMinCallAmount | debugFile

  if (debugFunc)Debug.log(gameObj, debugPlayer, "START getMinCallAmount:")

  betObj.minCallAmountIsSmallBlind = false
  betObj.minCallAmountIsMax = false

  if (gameObj.isHumanHandActionDone && gameObj.isComputerHandActionDone) {
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount HandActionDone")
    return betObj
  }

  let minCallAmount = 0
  let lastRow = Util.getLastRowForStreet(gameObj)
  if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount lastRow:", lastRow)

  // preflop, the small blind goes first
  if (gameObj.street === "preflop" && gameObj.playerBetTurn === gameObj.smallBlindPlayer
    && lastRow && lastRow.action === 'smallBlind'
  ) {
    // smallBlind may opt to call and bring his small blind up to the size of the big blind
    minCallAmount = betObj.preflopSmallBlindCallAmount
    betObj.minCallAmountIsSmallBlind = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount A1:", minCallAmount)
  } else if (gameObj.street === 'preflop' && gameObj.playerBetTurn === gameObj.bigBlindPlayer
    && lastRow && lastRow.action === 'smallBlindCall'
  ) {
    // if the small blind made ONLY a min call amount, then there is no minCallAmount big blind can raise big blind
    minCallAmount = 0
    if (debugFunc) Debug.log(gameObj, debugPlayer, "getMinCallAmount A2:", minCallAmount)
  } else if (lastRow && (lastRow.action === 'raise' || lastRow.action === 'allIn')) {
    minCallAmount = lastRow.betAmount
    if (debugFunc) Debug.log(gameObj, debugPlayer, "getMinCallAmount B1:", minCallAmount)
  } else if (lastRow === false) {
    // first to act on the street, nothing to call
    minCallAmount = 0
    if (debugFunc) Debug.log(gameObj, debugPlayer, "getMinCallAmount B2:", minCallAmount)
  } else if (lastRow && lastRow.street === gameObj.street && lastRow.action === 'check') {
    minCallAmount = 0
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount C:", minCallAmount)
  } else {
    minCallAmount = gameObj.bigBlind
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount D:", minCallAmount)
  }

  let secondToLastRow = Util.getSecondToLastRow(gameObj)
  if (lastRow.action === 'smallBlind' && secondToLastRow.action === 'allIn') {
    // if actor went all in with big blind, small blind call will be previous bet amount minus small blind
    minCallAmount = secondToLastRow.betAmount - gameObj.smallBlind
    betObj.minCallAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount minCallAmountIsMax E:", minCallAmount)
  } else {
    if (gameObj.playerBetTurn === 'human' && gameObj.humanChips < minCallAmount) {
      minCallAmount = gameObj.humanChips
      betObj.minCallAmountIsMax = true
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount minCallAmountIsMax F:", minCallAmount)
    } else if (gameObj.playerBetTurn === 'computer' && gameObj.computerChips < minCallAmount) {
      minCallAmount = gameObj.computerChips
      betObj.minCallAmountIsMax = true
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinCallAmount minCallAmountIsMax G:", minCallAmount)
    }
  }
  if (debugFunc)Debug.log(gameObj, debugPlayer, "END getMinCallAmount() H:", minCallAmount)
  betObj.minCallAmount = minCallAmount
  return betObj

}

// On the flop, if the first bet is 8bb, then a raise would have to be at least 16bb.
// A re-raise would then have a minimum legal size of 24bb
// The minRaiseAmount returned should include any 'call' component of the raise.
// eg. opponent bets 20, compu calls 20 and raises 20, so minRaiseAmount would be 40
export function getMinRaiseAmount(betObj, gameObj) {

  let debugFunc = debugGetMinRaiseAmount | debugFile
  if (debugFunc)Debug.log(gameObj, debugPlayer, "START getMinRaiseAmount()")

  betObj.minRaiseAmountIsMax = false

  if (gameObj.isHumanHandActionDone && gameObj.isComputerHandActionDone) {
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount() HandActionDone")
    return betObj
  }

  if (betObj.minCallAmountIsMax) {
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount() minCallAmountIsMax")
    return betObj
  }

  let minCallAmount = betObj.minCallAmount
  let minRaiseAmount = minCallAmount
  minRaiseAmount+= gameObj.bigBlind
  if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount A:", minRaiseAmount, "minCallAmount", minCallAmount)

// no betting more chips than opponent has
  if (gameObj.playerBetTurn === 'computer' && minRaiseAmount > gameObj.humanChips) {
    minRaiseAmount = gameObj.humanChips
    betObj.minRaiseAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount B:", minRaiseAmount)
  } else if (gameObj.playerBetTurn === 'human' && minRaiseAmount > gameObj.computerChips) {
    minRaiseAmount = gameObj.computerChips
    betObj.minRaiseAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount C:", minRaiseAmount)
// no betting more chips than owned
  } else if (gameObj.playerBetTurn === 'computer' && minRaiseAmount > gameObj.computerChips) {
    minRaiseAmount = gameObj.computerChips
    betObj.minRaiseAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount D:", minRaiseAmount)
  } else if (gameObj.playerBetTurn === 'human' && minRaiseAmount > gameObj.humanChips) {
    minRaiseAmount = gameObj.humanChips
    betObj.minRaiseAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMinRaiseAmount E:", minRaiseAmount)
  }


  if (minRaiseAmount === gameObj.bigBlind && gameObj.previousBetType !== 'smallBlindCall') {
    //betObj.minRaiseAmountIsMax = true
    if (debugFunc)Debug.log(gameObj, debugPlayer, "REMOVED getMinRaiseAmount F:", minRaiseAmount, "minRaiseAmountIsMax: true")
  }

  if (debugFunc)Debug.log(gameObj, debugPlayer, "END getMinRaiseAmount G:", minRaiseAmount, "minRaiseAmountIsMax:", JSON.stringify(betObj.minRaiseAmountIsMax))
  betObj.minRaiseAmount = minRaiseAmount
  return betObj
}

export function getMaxRaiseAmount(betObj, gameObj) {

  let debugFunc = debugGetMaxRaiseAmount | debugFile
  if (debugFunc) Debug.log(gameObj, debugPlayer, "START getMaxRaiseAmount()" )

  if (gameObj.isHumanHandActionDone && gameObj.isComputerHandActionDone) {
    if (debugFunc) Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() HandActionDone" )
    return betObj
  }

  // if chips are already maxed out
  if (betObj.minCallAmountIsMax) {
    betObj.maxRaiseAmount = betObj.minCallAmount
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() minCallAmountIsMax betObj.maxRaiseAmount", betObj.maxRaiseAmount)
    return betObj
  } else if (betObj.minRaiseAmountIsMax) {
    betObj.maxRaiseAmount = betObj.minRaiseAmount
    if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() minRaiseAmountIsMax betObj.maxRaiseAmount", betObj.maxRaiseAmount)
    return betObj
  }

  let maxRaiseAmount = betObj.minCallAmount
  if (gameObj.playerBetTurn === 'human') {
    if (gameObj.humanChips >= gameObj.computerChips) {
      maxRaiseAmount+= gameObj.computerChips
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() A:", maxRaiseAmount)
    } else if (gameObj.humanChips < gameObj.computerChips) {
      maxRaiseAmount = gameObj.humanChips
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() B:", maxRaiseAmount)
    }
  } else if (gameObj.playerBetTurn === 'computer') {
    if (gameObj.computerChips >= gameObj.humanChips) {
      maxRaiseAmount+= gameObj.humanChips
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() C:", maxRaiseAmount)
    } else if (gameObj.computerChips < gameObj.humanChips) {
      maxRaiseAmount = gameObj.computerChips
      if (debugFunc)Debug.log(gameObj, debugPlayer, "getMaxRaiseAmount() D:", maxRaiseAmount)
    }
  }
  if (debugFunc)Debug.log(gameObj, debugPlayer, "END getMaxRaiseAmount() E:", maxRaiseAmount)
  betObj.maxRaiseAmount = maxRaiseAmount

  return betObj

}

// at the end of its invocation of method bets, computer will call this so that human may make use of it
export function getDisplayBetAmount(gameObj) {

  let debugFunc = debugGetDisplayBetAmount | debugFile
  let betObj = gameObj.betObj
  if(debugFunc){
    Debug.log(gameObj, debugPlayer, "START getDisplayBetAmount")
  }

  if (gameObj.playerBetTurn === 'computer' || gameObj.playerBetTurn === 'waitcomputer') {
    // displayBetAmount will need to be set in state at end of computer's turn
    //return 0
  }

  let displayBetAmount
  if (gameObj.isHumanHandActionDone && gameObj.isComputerHandActionDone) {
    if(debugFunc)Debug.log(gameObj, debugPlayer, "HandActionDone")
    return 0
  }

  if (betObj.minCallAmountIsMax) {
    if(debugFunc)Debug.log(gameObj, debugPlayer, "minCallAmountIsMax")
    return 0
  }

  let lastRow = Util.getLastRowForStreet(gameObj)
  if (lastRow && lastRow.actor === 'computer' && lastRow.action === 'raise') {
    displayBetAmount = lastRow.betAmount
    displayBetAmount+= gameObj.bigBlind
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount A:", displayBetAmount)
  } else if (gameObj.street === 'preflop' && gameObj.previousBetType === 'smallBlind') {
    displayBetAmount = betObj.preflopSmallBlindCallAmount
    displayBetAmount+= gameObj.bigBlind
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount B1:", displayBetAmount)
  } else if (gameObj.street === 'preflop' && gameObj.previousBetType === 'smallBlindCall') {
    displayBetAmount = gameObj.bigBlind
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount B2:", displayBetAmount)
  } else {
    displayBetAmount = betObj.minCallAmount
    displayBetAmount+= gameObj.bigBlind
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount C:", displayBetAmount)
  }

  // no betting more chips than owned
  if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount humanChips:", gameObj.humanChips)
  if (gameObj.playerBetTurn === 'human' && displayBetAmount > gameObj.humanChips) {
    displayBetAmount = gameObj.humanChips
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount E:", displayBetAmount)
  }
  // no betting more chips than opponent has
  if (displayBetAmount > gameObj.computerChips && gameObj.humanChips > gameObj.computerChips) {
    displayBetAmount = gameObj.computerChips
    if(debugFunc)Debug.log(gameObj, debugPlayer, "getDisplayBetAmount F:", displayBetAmount)
  }

  if (debugFunc)Debug.log(gameObj, debugPlayer, "END getDisplayBetAmount G:", displayBetAmount)
  return displayBetAmount

}

// no bets higher than the amount of chips self or other has
export function limitBetAmount(betAmount, gameObj) {

  let debugFunc =  debugLimitBetAmount | debugFile
  let betObj = gameObj.betObj
  if (debugFunc)Debug.log(gameObj, debugPlayer, "A limitBetAmount() betAmount:", betAmount)

  if (betAmount > betObj.maxRaiseAmount) {
    betAmount = betObj.maxRaiseAmount
  } else if (betAmount > gameObj.computerChips) {
    betAmount = gameObj.computerChips
  }

  if (debugFunc)Debug.log(gameObj, debugPlayer, "B limitBetAmount() betAmount:", betAmount)

  return betAmount

}

// If after betting there are less than 5 big blinds in computer chips, go all in
export function maximizeBetAmount(actObj, gameObj){

  let debugFunc =  debugMaximizeBetAmount | debugFile
  if (debugFunc)Debug.log(gameObj, debugPlayer, "START maximizeBetAmount")

  if (gameObj.computerChips === 0 || gameObj.playerBetTurn === 'human' || gameObj.humanChips === 0
    // || actObj.action === 'fold' || actObj.action === 'check'
  ) {
    if (debugFunc)Debug.log(gameObj, debugPlayer, "maximizeBetAmount A")
    return actObj
  }
  // if the bet leaves behind only 5 big blinds or the bet is 80 percent of chip stack, go all in
  if (gameObj.computerChips > 0 && gameObj.computerChips - actObj.betAmount > 0) {
    if (((gameObj.computerChips - actObj.betAmount) / gameObj.bigBlind < 4) || ((actObj.betAmount/gameObj.computerChips > .8))) {
      actObj.betAmount = gameObj.computerChips
      if (actObj.action === 'raise') {
        actObj.action = 'allIn'
        if (debugFunc)Debug.log(gameObj, debugPlayer, "maximizeBetAmount B")
      } else if (actObj.action === 'call') {
        if (debugFunc)Debug.log(gameObj, debugPlayer, "maximizeBetAmount C")
        actObj.action = 'raise'
      }
    }
  }
  if (debugFunc)Debug.log(gameObj, debugPlayer, "END maximizeBetAmount")

  return actObj

}