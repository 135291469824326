// export function log(...val) {
//   if (window.dev) {
//     let str = ''
//     for(let i in val) {
//       str+=val[i] + " "
//     }
//     console.log("log:" + str)
//   }
//
// }

function isObject(val) {
  if (
    typeof val === 'object' &&
    !Array.isArray(val) &&
    val !== null
  ) {
    return true
  }
  return false
}

export function log(gameObj, ...arr) {

  let debugPlayer = arr[0]
  let str = debugPlayer + " "
  let playerBetTurn = gameObj.playerBetTurn
  if (debugPlayer === 'human' && playerBetTurn === 'human') {
    for(let i = 1; i < arr.length; i++) {
      if (isObject(arr[i]) || Array.isArray(arr[i])) {
        for(let j in arr[i]) {
          str += JSON.stringify(arr[i][j], null, 2)
        }
      } else {
        str += arr[i] + " "
      }
    }
    console.log(str)
  } else if (debugPlayer === 'computer' && playerBetTurn === 'computer') {
    for(let i = 1; i < arr.length; i++) {
      if (isObject(arr[i]) || Array.isArray(arr[i])) {
       // str+=JSON.stringify(arr[i])
        for(let j in arr[i]) {
          str += JSON.stringify(arr[i][j], null, 2)
        }
      } else {
        str += arr[i] + " "
      }
    }
    console.log(str)
  } else if (debugPlayer === 'both' && debugPlayer !== 'waithuman' && debugPlayer !== 'waitcomputer') {
    str = gameObj.playerBetTurn + " "
    for(let i = 1; i < arr.length; i++) {
      if (isObject(arr[i]) || Array.isArray(arr[i])) {
        //str+=JSON.stringify(arr[i])
        for(let j in arr[i]) {
          str += j + JSON.stringify(arr[i][j], null, 2)
        }
      } else {
        str += arr[i] + " "
      }
    }
    console.log(str)
  }

}