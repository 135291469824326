import actionsbase from "./actionsbase"

export default class flush extends actionsbase {

  mngAllIn() {
    let actObj = {}
    if (this.handScore >= this.adjFlushScoreTh) {
      actObj = {action: 'call'}
    } else {
      actObj.action = 'fold'
    }
    return actObj
  }

// START FLOP
//
// on the flop cannot be from playing the board, so computer has it
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action:'raise', betAmount:this.bestRaiseAmount}
      } else {
        actObj = { action: 'check' }
      }
    } else {
      if (this.handScore >= 700) {
        actObj.action = 'allIn'
      } else {
        actObj = { action: 'call'  }
      }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action:'raise', betAmount:this.bestRaiseAmount}
      } else {
        actObj = { action: 'check' }
      }
    } else {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj.action = 'allIn'
      } else {
        actObj = { action: 'call'  }
      }
    }
    return actObj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjFlushScoreTh ) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else {
        actObj.action = 'check'
      }
    } else {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action: 'call' }
      } else {
        actObj.action = 'fold'
      }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
    } else {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action: 'call' }
      } else {
        actObj.action = 'fold'
      }
    }
    return actObj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action:'raise', betAmount:this.bestRaiseAmount}
      } else {
        actObj.action = 'check'
      }
    } else {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = { action: 'allIn' }
      } else {
        actObj.action = 'fold'
      }
    }
    return actObj
  }

  riverNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      if (this.handScore >= this.adjFlushScoreTh) {
        actObj = {action:'raise', betAmount:this.bestRaiseAmount}
      } else {
        actObj.action = 'check'
      }
    } else {
      if (this.threatPairedBoardScore >= 700) {
        actObj.action = 'call'
      } else if (this.handScore >= this.adjFlushScoreTh) {
        actObj = { action: 'allIn' }
      } else {
        actObj.action = 'fold'
      }
    }
    return actObj
  }
//
// END RIVER

}