export function getHands(winningHand) {
  let computerHoleCardsArr = []
  let humanHoleCardsArr = []
  let boardCardsArr = []
  if (winningHand === 'pair') {
    computerHoleCardsArr = mockPair()
    boardCardsArr = mockBoardForPair()
    humanHoleCardsArr = mockLoseToPair()
  } else if (winningHand === 'twopair') {
    computerHoleCardsArr = mockTwoPair()
    boardCardsArr = mockBoardForTwoPair()
    humanHoleCardsArr = mockLoseToTwoPair()
  } else if (winningHand === 'bigpair') {
    computerHoleCardsArr = mockBigPair()
    boardCardsArr = mockBoardForBigPair()
    humanHoleCardsArr = mockLoseToBigPair()
  } else if (winningHand === 'bigpairholecards') {
    computerHoleCardsArr = mockBigPairHoleCards()
    boardCardsArr = mockBoardBigPairHoleCards()
    humanHoleCardsArr = mockLoseToBigPairHoleCards()
  } else if (winningHand === 'boardpaired') {
    computerHoleCardsArr = mockHoleCardsBoardPaired()
    boardCardsArr = mockBoardPaired()
    humanHoleCardsArr = mockHoleCardsLoseToBoardPaired()
  } else if (winningHand === 'straight') {
    computerHoleCardsArr = mockStraight()
    boardCardsArr = mockBoardStraight()
    humanHoleCardsArr = mockLoseToStraight()
  } else if (winningHand === 'straightboard') {
    computerHoleCardsArr = mockStraightBoard()
    boardCardsArr = mockBoardStraightBoard()
    humanHoleCardsArr = mockLoseToStraightBoard()
  } else if (winningHand === 'babystraight') {
    computerHoleCardsArr = mockBabyStraight()
    boardCardsArr = mockBoardBabyStraight()
    humanHoleCardsArr = mockLoseToBabyStraight()
  } else if (winningHand === 'sixhighstraight') {
    computerHoleCardsArr = mockSixHighStraight()
    boardCardsArr = mockBoardSixHighStraight()
    humanHoleCardsArr = mockLoseToSixHighStraight()
  } else if (winningHand === 'straightdrawsinglegappedA') {
    // 7,6,4,3
    computerHoleCardsArr = mockStraightDrawSingleGappedA()
    boardCardsArr = mockBoardStraightDrawSingleGappedA()
    humanHoleCardsArr = mockLoseToStraightDrawSingleGappedA()
  } else if (winningHand === 'A2-KQ345-AJ') {
    computerHoleCardsArr = mockStraightDrawTopAndBottom()
    boardCardsArr = mockBoardStraightDrawTopAndBottom()
    humanHoleCardsArr =  mockLoseToStraightDrawTopAndBottom()
  } else if (winningHand === 'straightdrawsinglegappedB') {
    // 7,5,4,3
    computerHoleCardsArr = mockStraightDrawSingleGappedB()
    boardCardsArr = mockBoardStraightDrawSingleGappedB()
    humanHoleCardsArr = mockLoseToStraightDrawSingleGappedB()
  } else if (winningHand === 'straightdrawsdoublegappedopenendedB') {
    // open ended 8,6,5,4,2 - needs a 3 or a 7
    computerHoleCardsArr = mockStraightDrawDoubleGappedOpenEndedB()
    boardCardsArr = mockBoardStraightDrawDoubleGappedOpenEndedB()
    humanHoleCardsArr = mockLoseToStraightDrawDoubleGappedOpenEndedB()
  } else if (winningHand === 'doublegappedrunnerrunner') {
    // open ended  7,5,3 - needs a 4 and a 6
    computerHoleCardsArr = mockStraightDrawDoubleGappedRunnerRunner()
    boardCardsArr = mockBoardStraightDrawDoubleGappedRunnerRunner()
    humanHoleCardsArr = mockLoseToStraightDrawDoubleGappedRunnerRunner()
  } else if (winningHand === 'bottomflushdraw') {
    computerHoleCardsArr = mockBottomFlushDraw()
    boardCardsArr = mockBoardBottomFlushDraw()
    humanHoleCardsArr = mockLoseToBottomFlushDraw()
  } else if (winningHand === 'topflushdraw') {
    computerHoleCardsArr = mockTopFlushDraw()
    boardCardsArr = mockBoardTopFlushDraw()
    humanHoleCardsArr = mockLoseToTopFlushDraw()
  } else if (winningHand === 'straightflushdrawopenended') {
    computerHoleCardsArr = mockStraightFlushDrawOpenEnded()
    boardCardsArr = mockBoardStraightFlushDrawOpenEnded()
    humanHoleCardsArr = mockLoseToStraightFlushDrawOpenEnded()
  } else if (winningHand === 'topstraightflushdrawopenended') {
    computerHoleCardsArr = mockTopStraightFlushDrawOpenEnded()
    boardCardsArr = mockBoardTopStraightFlushDrawOpenEnded()
    humanHoleCardsArr = mockLoseToTopStraightFlushDrawOpenEnded()
  } else if (winningHand === 'madeflush') {
    computerHoleCardsArr = mockMadeFlush()
    boardCardsArr = mockBoardMadeFlush()
    humanHoleCardsArr = mockLoseToMadeFlush()
  } else if (winningHand === 'weakholecards') {
    computerHoleCardsArr = mockWeakHolecards()
    boardCardsArr = mockBoardWeakHolecards()
    humanHoleCardsArr = mockLoseToWeakHolecards()
  } else if (winningHand === 'straighflush') {
    computerHoleCardsArr = mockStraightFlush()
    boardCardsArr = mockBoardStraightFlush()
    humanHoleCardsArr = mockLoseToStraightFlush()
  } else if (winningHand === 'acetenholecards') {
    computerHoleCardsArr = mockAceTenHolecards()
    boardCardsArr = mockBoardAceTenHolecards()
    humanHoleCardsArr = mockLoseToAceTenHolecards()
  } else if (winningHand === 1) {
    humanHoleCardsArr = mockHuman()
    computerHoleCardsArr = mockComputer()
    boardCardsArr = mockBoard()
  } else if (winningHand === 'pocketaces') {
    humanHoleCardsArr = mockHumanWithoutPocketAces()
    computerHoleCardsArr = mockComputerWithPocketAces()
    boardCardsArr = mockBoardPocketAces()
  } else if (winningHand === 'pair2') {
    humanHoleCardsArr = mockHumanPair2()
    computerHoleCardsArr = mockComputerPair2()
    boardCardsArr = mockBoardPair2()
  } else if (winningHand === 'pair3') {
    humanHoleCardsArr = mockHumanPair3()
    computerHoleCardsArr = mockComputerPair3()
    boardCardsArr = mockBoardPair3()
  } else if (winningHand === 'pair4') {
    humanHoleCardsArr = mockHumanPair4()
    computerHoleCardsArr = mockComputerPair4()
    boardCardsArr = mockBoardPair4()
  } else if (winningHand === 'pair5') {
    humanHoleCardsArr = mockHumanPair5()
    computerHoleCardsArr = mockComputerPair5()
    boardCardsArr = mockBoardPair5()
  } else if (winningHand === 'pair6') {
    humanHoleCardsArr = mockHumanPair6()
    computerHoleCardsArr = mockComputerPair6()
    boardCardsArr = mockBoardPair6()
  } else if (winningHand === 'pair7') {
    humanHoleCardsArr = mockHumanPair7()
    computerHoleCardsArr = mockComputerPair7()
    boardCardsArr = mockBoardPair7()
  } else if (winningHand === 'pair8') {
    humanHoleCardsArr = mockHumanPair8()
    computerHoleCardsArr = mockComputerPair8()
    boardCardsArr = mockBoardPair8()
  } else if (winningHand === 'Pair9') {
    humanHoleCardsArr = mockHumanPair9()
    computerHoleCardsArr = mockComputerPair9()
    boardCardsArr = mockBoardPair9()
  } else if (winningHand === 'topnonaceflushcard') {
    humanHoleCardsArr = mockTopNonAceFlushCardHuman()
    computerHoleCardsArr = mockTopNonAceFlushCardComputer()
    boardCardsArr = mockTopNonAceFlushCardBoard()
  } else if (winningHand === 'twoovercardsandopenendedstraightandflushdraw') {
    humanHoleCardsArr = mockHuman10()
    computerHoleCardsArr = mockComputer10()
    boardCardsArr = mockBoard10()
  } else if (winningHand === 'highcardK') {
    humanHoleCardsArr = mockHighcardKHuman()
    computerHoleCardsArr = mockHighcardKComputer()
    boardCardsArr = mockHighcardKBoard()
  } else if (winningHand === 'weakhighcard') {
    humanHoleCardsArr = mockWeakHighcardHuman()
    computerHoleCardsArr = mockWeakHighcardComputer()
    boardCardsArr = mockWeakHighcardBoard()
  } else if (winningHand === 'straightdrawtopandbottomace') {
    computerHoleCardsArr = mockStraightDrawTopAndBottomAce()
    boardCardsArr = mockBoardStraightDrawTopAndBottomAce()
    humanHoleCardsArr = mockLoseToStraightDrawTopAndBottomAce()
  } else if (winningHand === 1) {
    humanHoleCardsArr = mockHuman()
    computerHoleCardsArr = mockComputer()
    boardCardsArr = mockBoard()
  } else if (winningHand === '3,4, 6,7, 9,10') {
    ///////// 3,4, 6,7, 9,10 - a 5 or an 8 will make the straight
    computerHoleCardsArr = mockStraightDrawDoubleGappedOpenEnded()
    boardCardsArr = mockBoardStraightDrawDoubleGappedOpenEnded()
    humanHoleCardsArr = mockLoseToStraightDrawDoubleGappedOpenEnded()
  } else if (winningHand === '10,4, 6,7, 9,3') {
    computerHoleCardsArr = mockStraightDrawDoubleGappedOpenEnded2()
    boardCardsArr = mockBoardStraightDrawDoubleGappedOpenEnded2()
    humanHoleCardsArr = mockLoseToStraightDrawDoubleGappedOpenEnded2()
  } else if (winningHand === 'topfloppedflush') {
    humanHoleCardsArr = mockTopFloppedFlushHuman()
    computerHoleCardsArr = mockTopFloppedFlushComputer()
    boardCardsArr = mockTopFloppedFlushBoard()
  } else if (winningHand === 'fullhouseA') {
    humanHoleCardsArr = mockFullHouseHumanA()
    computerHoleCardsArr = mockFullHouseComputerA()
    boardCardsArr = mockFullHouseBoardA()
  } else if (winningHand === 'fullhouseboard3ofakind') {
      humanHoleCardsArr = mockFullHouseHumanB()
      computerHoleCardsArr = mockFullHouseComputerB()
      boardCardsArr = mockFullHouseBoardB()
  } else if (winningHand === 'straightA') {
    humanHoleCardsArr = mockStraightHumanA()
    computerHoleCardsArr = mockStraightComputerA()
    boardCardsArr = mockStraightBoardA()
  } else if (winningHand === 'weakflush') {
    humanHoleCardsArr = mockWeakFlushHuman()
    computerHoleCardsArr = mockWeakFlushComputer()
    boardCardsArr = mockWeakFlushBoard()
  } else if (winningHand === 'strongflush') {
    humanHoleCardsArr = mockStrongFlushHuman()
    computerHoleCardsArr = mockStrongFlushComputer()
    boardCardsArr = mockStrongFlushBoard()
  } else if (winningHand === 'straight1') {
    humanHoleCardsArr = mockStraight1Human()
    computerHoleCardsArr = mockStraight1Computer()
    boardCardsArr = mockStraight1Board()
  } else if (winningHand === 'straight2') {
    humanHoleCardsArr = mockStraight2Human()
    computerHoleCardsArr = mockStraight2Computer()
    boardCardsArr = mockStraight2Board()
  } else if (winningHand === 'pair1') {
    humanHoleCardsArr = mockPair1Human()
    computerHoleCardsArr = mockPair1Computer()
    boardCardsArr = mockPair1Board()
  } else if (winningHand === 'x') {
    humanHoleCardsArr = mockXHuman()
    computerHoleCardsArr = mockXComputer()
    boardCardsArr = mockXBoard()
  } else if (winningHand === 'z') {
    humanHoleCardsArr = mockZHuman()
    computerHoleCardsArr = mockZComputer()
    boardCardsArr = mockZBoard()
  } else if (winningHand === 'qq') {
    humanHoleCardsArr = mockQQHuman()
    computerHoleCardsArr = mockQQComputer()
    boardCardsArr = mockQQBoard()
  } else if (winningHand === '66') {
    humanHoleCardsArr = mock66Human()
    computerHoleCardsArr = mock66Computer()
    boardCardsArr = mock66Board()
  } else if (winningHand === 'aa') {
    humanHoleCardsArr = mockAAHuman()
    computerHoleCardsArr = mockAAComputer()
    boardCardsArr = mockAABoard()
  } else if (winningHand === 'aq') {
    humanHoleCardsArr = mockAQHuman()
    computerHoleCardsArr = mockAQComputer()
    boardCardsArr = mockAQBoard()
  } else if (winningHand === 'aj') {
    humanHoleCardsArr = mockAJHuman()
    computerHoleCardsArr = mockAJComputer()
    boardCardsArr = mockAJBoard()
  } else if (winningHand === 'flushx') {
    humanHoleCardsArr = mockFlushXHuman()
    computerHoleCardsArr = mockFlushXComputer()
    boardCardsArr = mockFlushXBoard()
  } else if (winningHand === 'straightx') {
    humanHoleCardsArr = mockStraightXHuman()
    computerHoleCardsArr = mockStraightXComputer()
    boardCardsArr = mockStraightXBoard()
  } else if (winningHand === '99') {
    humanHoleCardsArr = mock99Human()
    computerHoleCardsArr = mock99Computer()
    boardCardsArr = mock99Board()
  } else if (winningHand === 'a') {
    humanHoleCardsArr = mockAHuman()
    computerHoleCardsArr = mockAComputer()
    boardCardsArr = mockABoard()
  } else if (winningHand === 'b') {
    humanHoleCardsArr = mockBHuman()
    computerHoleCardsArr = mockBComputer()
    boardCardsArr = mockBBoard()
  } else if (winningHand === 'c') {
    humanHoleCardsArr = mockCHuman()
    computerHoleCardsArr = mockCComputer()
    boardCardsArr = mockCBoard()
  } else if (winningHand === 'samesuitflushdraw') {
    humanHoleCardsArr = mockHumanSameSuitFlushDraw()
    computerHoleCardsArr = mockComputerSameSuitFlushDraw()
    boardCardsArr = mockBoardSameSuitFlushDraw()
  } else if (winningHand === 'duelingflushdraws') {
    humanHoleCardsArr = mockHumanDuelingFlushDraw()
    computerHoleCardsArr = mockComputerDuelingFlushDraw()
    boardCardsArr = mockBoardDuelingFlushDraw()
  } else if (winningHand === 'board3ofakind') {
    humanHoleCardsArr = mockHumanBoard3OfAKind()
    computerHoleCardsArr = mockComputerBoard3OfAKind()
    boardCardsArr = mockBoard3OfAKind()
  } else if (winningHand === '3ofakind') {
    humanHoleCardsArr = mockHuman3OfAKind()
    computerHoleCardsArr = mockComputer3OfAKind()
    boardCardsArr = mock3OfAKind()
  } else if (winningHand === 'tripsthreatflushdraw') {
    humanHoleCardsArr = mockHumanTrips()
    computerHoleCardsArr = mockComputerTrips()
    boardCardsArr = mockBoardTrips()
  } else if (winningHand === 'tie') {
    humanHoleCardsArr = mockHumanTie()
    computerHoleCardsArr = mockComputerTie()
    boardCardsArr = mockBoardTie()
  } else if (winningHand === 'board4ofakind') {
    humanHoleCardsArr = mockHuman4OfAKindBoard()
    computerHoleCardsArr = mockComputer4OfAKindBoard()
    boardCardsArr = mock4OfAKindBoard()
  } else if (winningHand === '4ofakind') {
    humanHoleCardsArr = mockHuman4OfAKind()
    computerHoleCardsArr = mockComputer4OfAKind()
    boardCardsArr = mockBoard4OfAKind()
  }

  return {computerHoleCardsArr:computerHoleCardsArr, boardCardsArr:boardCardsArr, humanHoleCardsArr:humanHoleCardsArr}
}

///////// board makes 4 of a kind
function mockHuman4OfAKindBoard() {
  return [{rank:9, suit:"heart"}, {rank:6, suit:"heart"}]
}
function mockComputer4OfAKindBoard() {
  return [{"rank":14,"suit":"diamond"},{"rank":11,"suit":"heart"}]
}
function mock4OfAKindBoard(){
  return [{"rank":12,"suit":"spade"},{"rank":12,"suit":"club"},{rank:12, suit: "diamond"}, {rank:12, suit:"heart"},{"rank":9,"suit":"club"}]
}
/////////

///////// computer makes 4 of a kind
function mockHuman4OfAKind() {
  return [{rank:9, suit:"heart"}, {rank:6, suit:"heart"}]
}
function mockComputer4OfAKind() {
  return [{rank:12, suit: "diamond"}, {rank:12, suit:"heart"}]
}
function mockBoard4OfAKind(){
  return [{"rank":12,"suit":"spade"},{"rank":12,"suit":"club"},{"rank":13,"suit":"diamond"},{"rank":11,"suit":"heart"},{"rank":9,"suit":"club"}]
}
/////////

// tie
function mockHumanTie() {
  return [{rank:13, suit:"heart"}, {rank:12, suit:"diamond"}]
}
function mockComputerTie() {
  return [{rank:13, suit: "spade"}, {rank:12, suit:"spade"}]
}
function mockBoardTie(){
  return [{"rank":2,"suit":"heart"},{"rank":4,"suit":"club"},{"rank":7,"suit":"heart"},{"rank":11,"suit":"heart"},{"rank":9,"suit":"spade"}]
}

///////// trips - flush draw
function mockHumanTrips() {
  return [{rank:13, suit:"heart"}, {rank:12, suit:"diamond"}]
}
function mockComputerTrips() {
  return [{rank:14, suit: "spade"}, {rank:5, suit:"spade"}]
}
function mockBoardTrips(){
  return [{"rank":14,"suit":"heart"},{"rank":14,"suit":"club"},{"rank":12,"suit":"heart"},{"rank":6,"suit":"heart"},{"rank":9,"suit":"heart"}]
}
/////////

///////// board makes 3 of a kind
function mockHumanBoard3OfAKind() {
  return [{rank:13, suit:"heart"}, {rank:12, suit:"heart"}]
}
function mockComputerBoard3OfAKind() {
  return [{rank:14, suit: "spade"}, {rank:5, suit:"spade"}]
}
function mockBoard3OfAKind(){
  return [{"rank":12,"suit":"spade"},{"rank":12,"suit":"club"},{"rank":12,"suit":"diamond"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"club"}]
}
/////////

/////////  3 of a kind
function mockHuman3OfAKind() {
  return [{rank:13, suit:"heart"}, {rank:12, suit:"heart"}]
}
function mockComputer3OfAKind() {
  return [{rank:14, suit: "spade"}, {rank:5, suit:"spade"}]
}
function mock3OfAKind(){
  return [{"rank":14,"suit":"club"},{"rank":4,"suit":"club"},{"rank":3,"suit":"diamond"},{"rank":14,"suit":"heart"},{"rank":9,"suit":"club"}]
}
/////////

///////// dueling flush draws
function mockHumanDuelingFlushDraw() {
  return [{rank:13, suit:"heart"}, {rank:12, suit:"heart"}]
}
function mockBoardDuelingFlushDraw() {
  return [{"rank":11,"suit":"spade"},{"rank":10,"suit":"heart"},{"rank":6,"suit":"heart"},{"rank":3,"suit":"spade"},{"rank":3,"suit":"club"}]
}
function mockComputerDuelingFlushDraw(){
  return [{rank:14, suit: "spade"}, {rank:5, suit:"spade"}]
}
/////////

///////// both have same suit flush draw
function mockHumanSameSuitFlushDraw() {
  return [{rank:13, suit:"spade"}, {rank:12, suit:"spade"}]
}
function mockBoardSameSuitFlushDraw() {
  return [{"rank":11,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":6,"suit":"heart"},{"rank":2,"suit":"heart"},{"rank":3,"suit":"spade"}]
}
function mockComputerSameSuitFlushDraw(){
  return [{rank:14, suit: "spade"}, {rank:5, suit:"spade"}]
}
/////////

function mockCHuman() {return [{rank:9, suit:'diamond'},{rank:4, suit:'diamond'}]}
function mockCComputer() {return [{rank:9, suit:'spade'},{rank:8, suit:'spade'}]}
function mockCBoard() {return [{rank:10, suit:'spade'}, {rank:7, suit:'diamond'}, {rank:6, suit:'club'}, {rank:12, suit:'diamond'}, {rank:7, suit:'club'}]}


function mockBHuman() {return [{rank:10, suit:'spade'},{rank:4, suit:'heart'}]}
function mockBComputer() {return [{rank:13, suit:'heart'},{rank:4, suit:'spade'}]}
function mockBBoard() {return [{rank:10, suit:'diamond'}, {rank:14, suit:'heart'}, {rank:2, suit:'heart'}, {rank:7, suit:'club'}, {rank:6, suit:'heart'}]}

function mockAHuman() {return [{rank:5, suit:'spade'},{rank:2, suit:'diamond'}]}
function mockAComputer() {return [{rank:14, suit:'club'},{rank:3, suit:'spade'}]}
function mockABoard() {return [{rank:2, suit:'club'}, {rank:5, suit:'heart'}, {rank:3, suit:'diamond'}, {rank:11, suit:'club'}, {rank:3, suit:'heart'}]}

function mock99Human() {return [{rank:3, suit:'heart'},{rank:3, suit:'spade'}]}
function mock99Computer() {return [{rank:9, suit:'spade'},{rank:9, suit:'heart'}]}
function mock99Board() {return [{rank:6, suit:'heart'}, {rank:5, suit:'spade'}, {rank:8, suit:'spade'}, {rank:5, suit:'diamond'}, {rank:11, suit:'spade'}]}

function mockStraightXHuman() {return [{rank:12, suit:'heart'},{rank:11, suit:'heart'}]}
function mockStraightXComputer() {return [{rank:11, suit:'spade'},{rank:10, suit:'club'}]}
function mockStraightXBoard() {return [{rank:5, suit:'club'}, {rank:9, suit:'diamond'}, {rank:12, suit:'spade'}, {rank:13, suit:'heart'}, {rank:10, suit:'heart'}]}


function mockFlushXHuman() {return [{rank:11, suit:'heart'},{rank:9, suit:'heart'}]}
function mockFlushXComputer() {return [{rank:14, suit:'club'},{rank:10, suit:'diamond'}]}
function mockFlushXBoard() {return [{rank:7, suit:'heart'}, {rank:6, suit:'heart'}, {rank:11, suit:'club'}, {rank:2, suit:'heart'}, {rank:10, suit:'spade'}]}

function mockAJHuman() {return [{rank:10, suit:'spade'},{rank:4, suit:'spade'}]}
function mockAJComputer() {return [{rank:14, suit:'diamond'},{rank:11, suit:'diamond'}]}
function mockAJBoard() {return [{rank:5, suit:'spade'}, {rank:14, suit:'spade'}, {rank:6, suit:'heart'}, {rank:9, suit:'spade'}, {rank:3, suit:'heart'}]}

function mockAQHuman() {return [{rank:14, suit:'spade'},{rank:12, suit:'heart'}]}
function mockAQComputer() {return [{rank:14, suit:'heart'},{rank:12, suit:'diamond'}]}
function mockAQBoard() {return [{rank:11, suit:'club'}, {rank:9, suit:'club'}, {rank:9, suit:'heart'}, {rank:14, suit:'diamond'}, {rank:4, suit:'spade'}]}

function mockAAHuman() {return [{rank:12, suit:'heart'},{rank:10, suit:'heart'}]}
function mockAAComputer() {return [{rank:14, suit:'club'},{rank:14, suit:'diamond'}]}
function mockAABoard() {return [{rank:9, suit:'heart'}, {rank:9, suit:'diamond'}, {rank:14, suit:'heart'}, {rank:14, suit:'spade'}, {rank:5, suit:'spade'}]}


function mock66Human() {return [{rank:11, suit:'diamond'},{rank:10, suit:'diamond'}]}
function mock66Computer() {return [{rank:6, suit:'heart'},{rank:6, suit:'diamond'}]}
function mock66Board() {return [{rank:11, suit:'spade'}, {rank:14, suit:'spade'}, {rank:10, suit:'spade'}, {rank:10, suit:'club'}, {rank:2, suit:'club'}]}

function mockQQHuman() {return [{rank:9, suit:'spade'},{rank:7, suit:'heart'}]}
function mockQQComputer() {return [{rank:12, suit:'spade'},{rank:12, suit:'club'}]}
function mockQQBoard() {return [{rank:2, suit:'heart'}, {rank:13, suit:'heart'}, {rank:11, suit:'heart'}, {rank:7, suit:'spade'}, {rank:9, suit:'heart'}]}

function mockZHuman() {return [{rank:12, suit:'spade'},{rank:6, suit:'spade'}]}
function mockZComputer() {return [{rank:8, suit:'heart'},{rank:7, suit:'diamond'}]}
function mockZBoard() {return [{rank:12, suit:'heart'}, {rank:4, suit:'diamond'}, {rank:14, suit:'diamond'}, {rank:10, suit:'diamond'}, {rank:11, suit:'club'}]}

function mockXHuman() {return [{rank:14, suit:'diamond'},{rank:12, suit:'spade'}]}
function mockXComputer() {return [{rank:4, suit:'spade'},{rank:3, suit:'heart'}]}
function mockXBoard() {return [{rank:10, suit:'diamond'}, {rank:3, suit:'spade'}, {rank:10, suit:'heart'}, {rank:3, suit:'club'}, {rank:12, suit:'heart'}]}

function mockPair1Human() {return [{rank:13, suit:'spade'},{rank:10, suit:'heart'}]}
function mockPair1Computer() {return [{rank:12, suit:'spade'},{rank:7, suit:'club'}]}
function mockPair1Board() {return [{rank:7, suit:'diamond'}, {rank:6, suit:'spade'}, {rank:5, suit:'diamond'}, {rank:5, suit:'spade'}, {rank:9, suit:'heart'}]}

function mockStraight2Human() {return [{rank:14, suit:'heart'},{rank:7, suit:'diamond'}]}
function mockStraight2Computer() {return [{rank:8, suit:'club'},{rank:4, suit:'heart'}]}
function mockStraight2Board() {return [{rank:3, suit:'heart'}, {rank:5, suit:'spade'}, {rank:5, suit:'heart'}, {rank:7, suit:'club'}, {rank:6, suit:'spade'}]}

function mockStraight1Human() {return [{rank:10, suit:'spade'},{rank:5, suit:'heart'}]}
function mockStraight1Computer() {return [{rank:5, suit:'spade'},{rank:2, suit:'spade'}]}
function mockStraight1Board() {return [{rank:9, suit:'diamond'}, {rank:3, suit:'club'}, {rank:14, suit:'heart'}, {rank:13, suit:'heart'}, {rank:14, suit:'diamond'}]}

function mockWeakFlushHuman() {return [{rank:14, suit:'heart'},{rank:6, suit:'heart'}]}
function mockWeakFlushComputer() {return [{rank:8, suit:'club'},{rank:4, suit:'club'}]}
function mockWeakFlushBoard() {return [{rank:5, suit:'spade'}, {rank:3, suit:'club'}, {rank:9, suit:'diamond'}, {rank:5, suit:'club'}, {rank:10, suit:'club'}]}

function mockStrongFlushHuman() {return [{rank:13, suit:'heart'},{rank:6, suit:'heart'}]}
function mockStrongFlushComputer() {return [{rank:14, suit:'club'},{rank:4, suit:'club'}]}
function mockStrongFlushBoard() {return [{rank:5, suit:'club'}, {rank:3, suit:'club'}, {rank:9, suit:'diamond'}, {rank:5, suit:'diamond'}, {rank:10, suit:'club'}]}

function mockStraightHumanA() {return [{rank:14, suit:'club'},{rank:12, suit:'club'}]}
function mockStraightComputerA() {return [{rank:13, suit:'spade'},{rank:12, suit:'heart'}]}
function mockStraightBoardA() {return [{rank:11, suit:'diamond'}, {rank:10, suit:'spade'}, {rank:9, suit:'heart'}, {rank:4, suit:'heart'}, {rank:5, suit:'heart'}]}

function mockFullHouseHumanA() {return [{rank:14, suit:'club'},{rank:12, suit:'club'}]}
function mockFullHouseComputerA() {return [{rank:13, suit:'spade'},{rank:8, suit:'heart'}]}
function mockFullHouseBoardA() {return [{rank:13, suit:'diamond'}, {rank:8, suit:'spade'}, {rank:13, suit:'heart'}, {rank:9, suit:'heart'}, {rank:5, suit:'heart'}]}

function mockFullHouseHumanB() {return [{rank:14, suit:'club'},{rank:12, suit:'club'}]}
function mockFullHouseComputerB() {return [{rank:14, suit:'spade'},{rank:2, suit:'heart'}]}
function mockFullHouseBoardB() {return [{rank:13, suit:'diamond'}, {rank:13, suit:'spade'}, {rank:13, suit:'heart'}, {rank:8, suit:'heart'}, {rank:8, suit:'diamond'}]}

function mockHuman() {return [{rank:14, suit:'club'},{rank:12, suit:'club'}]}
function mockComputer() {return [{rank:13, suit:'spade'},{rank:8, suit:'heart'}]}
function mockBoard() {return [{rank:12, suit:'diamond'}, {rank:12, suit:'spade'}, {rank:13, suit:'diamond'}, {rank:9, suit:'heart'}, {rank:5, suit:'heart'}]}


function mockWeakHighcardHuman() {return [{rank:12, suit:'heart'},{rank:10, suit:'diamond'}]}
function mockWeakHighcardComputer() {return [{rank:4, suit:'spade'},{rank:3, suit:'heart'}]}
function mockWeakHighcardBoard() {return [{rank:8, suit:'spade'}, {rank:10, suit:'club'}, {rank:7, suit:'heart'}, {rank:11, suit:'heart'}, {rank:2, suit:'heart'}]}

function mockHighcardKHuman() {return [{rank:12, suit:'heart'},{rank:10, suit:'diamond'}]}
function mockHighcardKComputer() {return [{rank:13, suit:'spade'},{rank:9, suit:'spade'}]}
function mockHighcardKBoard() {return [{rank:8, suit:'spade'}, {rank:5, suit:'club'}, {rank:7, suit:'heart'}, {rank:2, suit:'heart'}, {rank:4, suit:'heart'}]}

// two overcards and open ended straight and flush draw
function mockHuman10() {return [{rank:13, suit:'heart'},{rank:6, suit:'diamond'}]}
function mockComputer10() {return [{rank:11, suit:'spade'},{rank:10, suit:'spade'}]}
function mockBoard10() {return [{rank:9, suit:'spade'}, {rank:8, suit:'spade'}, {rank:5, suit:'heart'}, {rank:4, suit:'heart'}, {rank:2, suit:'heart'}]}

// topnonaceflushcard
function mockTopNonAceFlushCardHuman() {return [{rank:8, suit:'heart'},{rank:10, suit:'diamond'}]}
function mockTopNonAceFlushCardComputer() {return [{rank:11, suit:'spade'},{rank:9, suit:'diamond'}]}
function mockTopNonAceFlushCardBoard() {return [{rank:14, suit:'spade'}, {rank:13, suit:'spade'}, {rank:12, suit:'spade'}, {rank:6, suit:'diamond'}, {rank:9, suit:'spade'}]}

// top flopped flush
function mockTopFloppedFlushHuman() {return [{rank:13, suit:'heart'},{rank:10, suit:'heart'}]}
function mockTopFloppedFlushComputer() {return [{rank:11, suit:'spade'},{rank:9, suit:'spade'}]}
function mockTopFloppedFlushBoard() {return [{rank:14, suit:'spade'}, {rank:13, suit:'spade'}, {rank:12, suit:'spade'}, {rank:6, suit:'diamond'}, {rank:9, suit:'diamond'}]}


// 9 board paired
function mockHumanPair9() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair9() {return [{rank:12, suit:'heart'},{rank:11, suit:'spade'}]}
function mockBoardPair9() {return [{rank:8, suit:'heart'}, {rank:8, suit:'club'}, {rank:10, suit:'heart'}, {rank:3, suit:'heart'}, {rank:6, suit:'heart'}]}

// 8 Weak middle pair - Holecard pairs with the board in position 2 and holecard kicker is position 2 or less HC: 8,7 Board: 8,10,11
function mockHumanPair8() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair8() {return [{rank:8, suit:'heart'},{rank:7, suit:'spade'}]}
function mockBoardPair8() {return [{rank:2, suit:'heart'}, {rank:8, suit:'club'}, {rank:14, suit:'heart'}, {rank:3, suit:'heart'}, {rank:6, suit:'heart'}]}

// 7 Strong middle pair - Holecard pairs with the board in position 2 and holecard kicker is position 1 HC: 2,14 Board: 2,6,9
function mockHumanPair7() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair7() {return [{rank:8, suit:'heart'},{rank:11, suit:'spade'}]}
function mockBoardPair7() {return [{rank:2, suit:'heart'}, {rank:8, suit:'club'}, {rank:14, suit:'heart'}, {rank:3, suit:'heart'}, {rank:6, suit:'heart'}]}

// 6 Weak top pair - Holecard pairs position 1 of any rank and holecard kicker is position 2 or less. HC: 9,7 Board: 9,4,2
function mockHumanPair6() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair6() {return [{rank:9, suit:'spade'},{rank:7, suit:'spade'}]}
function mockBoardPair6() {return [{rank:9, suit:'heart'}, {rank:5, suit:'club'}, {rank:13, suit:'heart'}, {rank:3, suit:'heart'}, {rank:2, suit:'heart'}]}

// 5 Strong top pair - Holecard pairs with the board and is top pair and holecard kicker is position 1. HC: K,9 Board: 9,4,2
function mockHumanPair5() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair5() {return [{rank:13, suit:'spade'},{rank:9, suit:'spade'}]}
function mockBoardPair5() {return [{rank:9, suit:'heart'}, {rank:5, suit:'club'}, {rank:7, suit:'heart'}, {rank:3, suit:'heart'}, {rank:2, suit:'heart'}]}

// 4 Strong top pair - Holecards are a pair with a rank >= 10 and position 1 HC: 10,10 Board: 8,4,2
function mockHumanPair4() {return [{rank:11, suit:'heart'},{rank:9, suit:'diamond'}]}
function mockComputerPair4() {return [{rank:10, suit:'spade'},{rank:10, suit:'spade'}]}
function mockBoardPair4() {return [{rank:8, suit:'heart'}, {rank:5, suit:'club'}, {rank:7, suit:'heart'}, {rank:9, suit:'heart'}, {rank:3, suit:'heart'}]}

// pair3 Weak ace - Holecard Ace pairs with the board and holecard kicker is position 2 or less to non-paired board cards. HC: A,7 Board: A,5,8
function mockHumanPair3() {return [{rank:11, suit:'heart'},{rank:10, suit:'diamond'}]}
function mockComputerPair3() {return [{rank:14, suit:'spade'},{rank:7, suit:'spade'}]}
function mockBoardPair3() {return [{rank:14, suit:'heart'}, {rank:5, suit:'club'}, {rank:8, suit:'heart'}, {rank:9, suit:'heart'}, {rank:3, suit:'heart'}]}

// pair2 Strong ace - Holecard Ace pairs with the board and holecard kicker has a rank > 9 and is position 1 HC: A,10 Board: A,8,4
// -- if human has A,8 and computer has A,10, computer has outs to improve to a better two pair
function mockHumanPair2() {return [{rank:11, suit:'heart'},{rank:10, suit:'diamond'}]}
function mockComputerPair2() {return [{rank:14, suit:'spade'},{rank:10, suit:'spade'}]}
function mockBoardPair2() {return [{rank:14, suit:'heart'}, {rank:5, suit:'club'}, {rank:4, suit:'heart'}, {rank:9, suit:'heart'}, {rank:3, suit:'heart'}]}

function mockHumanWithoutPocketAces() {return [{rank:14, suit:'heart'},{rank:12, suit:'diamond'}]}
function mockComputerWithPocketAces() {return [{rank:14, suit:'spade'},{rank:14, suit:'diamond'}]}
function mockBoardPocketAces() {return [{rank:13, suit:'spade'}, {rank:5, suit:'club'}, {rank:4, suit:'heart'}, {rank:9, suit:'heart'}, {rank:10, suit:'heart'}]}

////////// ace ten holecards
function mockAceTenHolecards() {
  return [{rank:14, suit:"spade"}, {rank:10, suit:"diamond"}]
}
function mockBoardAceTenHolecards() {
  return [{"rank":7,"suit":"spade"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":5,"suit":"spade"}]
}
function mockLoseToAceTenHolecards(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"club"}]
}
/////////

//////////
function mockMadeFlush() {
  return [{rank:14, suit:"spade"}, {rank:4, suit:"spade"}]
}
function mockBoardMadeFlush() {
  return [{"rank":7,"suit":"spade"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":5,"suit":"spade"}]
}
function mockLoseToMadeFlush(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"club"}]
}
/////////

/////////
function mockTopFlushDraw() {
  return [{rank:14, suit:"spade"}, {rank:2, suit:"heart"}]
}
function mockBoardTopFlushDraw() {
  return [{"rank":3,"suit":"spade"},{"rank":4,"suit":"spade"},{"rank":9,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":5,"suit":"spade"}]
}
function mockLoseToTopFlushDraw(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"club"}]
}
/////////

///////// 3,4,5,6
function mockStraightFlushDrawOpenEnded() {
  return [{rank:5, suit:"spade"}, {rank:4, suit:"spade"}]
}
function mockBoardStraightFlushDrawOpenEnded() {
  return [{"rank":3,"suit":"spade"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"heart"},{"rank":10,"suit":"diamond"},{"rank":7,"suit":"spade"}]
}
function mockLoseToStraightFlushDrawOpenEnded(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"club"}]
}
/////////

///////// K,Q,J,10 with A on the board
function mockTopStraightFlushDrawOpenEnded() {
  return [{rank:13, suit:"spade"}, {rank:12, suit:"spade"}]
}
function mockBoardTopStraightFlushDrawOpenEnded() {
  return [{"rank":11,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":6,"suit":"heart"},{"rank":2,"suit":"diamond"},{"rank":14,"suit":"spade"}]
}
function mockLoseToTopStraightFlushDrawOpenEnded(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"club"}]
}
/////////

/////////
/////////
function mockStraightFlush() {
  return [{rank:7, suit:"heart"}, {rank:3, suit:"heart"}]
}
function mockBoardStraightFlush() {
  return [{"rank":4,"suit":"heart"},{"rank":5,"suit":"heart"},{"rank":6,"suit":"heart"},{"rank":5,"suit":"spade"},{"rank":14,"suit":"heart"}]
}
function mockLoseToStraightFlush() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////
/////////

/////////
function mockBottomFlushDraw() {
  return [{rank:3, suit:"spade"}, {rank:4, suit:"spade"}]
}
function mockBoardBottomFlushDraw() {
  return [{"rank":7,"suit":"spade"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"spade"},{"rank":10,"suit":"spade"},{"rank":5,"suit":"diamond"}]
}
function mockLoseToBottomFlushDraw(){
  return [{rank:13, suit: "heart"}, {rank:5, suit:"heart"}]
}
/////////

// Requires 6 cards - 4 board and 2 hole cards, so only applies to computer 11,10, 8,7, 5,4 - 9 or 6
///////// 3,4, 6,7, 9,10 - a 5 or an 8 will make the straight
function mockStraightDrawDoubleGappedOpenEnded() {
  return [{rank:3, suit:"spade"}, {rank:4, suit:"heart"}]
}
function mockBoardStraightDrawDoubleGappedOpenEnded() {
  return [{"rank":7,"suit":"heart"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"diamond"},{"rank":10,"suit":"heart"},{"rank":13,"suit":"diamond"}]
}
function mockLoseToStraightDrawDoubleGappedOpenEnded() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

///////// 10,4, 6,7, 9,3 - a 5 or an 8 will make the straight
function mockStraightDrawDoubleGappedOpenEnded2() {
  return [{rank:10, suit:"spade"}, {rank:4, suit:"heart"}]
}
function mockBoardStraightDrawDoubleGappedOpenEnded2() {
  return [{"rank":7,"suit":"heart"},{"rank":6,"suit":"spade"},{"rank":9,"suit":"diamond"},{"rank":3,"suit":"heart"},{"rank":13,"suit":"diamond"}]
}
function mockLoseToStraightDrawDoubleGappedOpenEnded2() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

// A2-KQ345-AJ
function mockStraightDrawTopAndBottom() {
  return [{rank:14, suit:"spade"}, {rank:2, suit:"heart"}]
}
function mockBoardStraightDrawTopAndBottom() {
  return [{"rank":13,"suit":"heart"},{"rank":12,"suit":"spade"},{"rank":3,"suit":"diamond"},{"rank":4,"suit":"heart"},{"rank":5,"suit":"diamond"}]
}
function mockLoseToStraightDrawTopAndBottom() {
  return [{rank:14, suit: "heart"}, {rank:11, suit:"spade"}]
}
/////////

///////// 3,4, 6,7, 9,10 - a 5 or an 8 will make the straight
function mockStraightDrawTopAndBottomAce() {
  return [{rank:3, suit:"spade"}, {rank:2, suit:"heart"}]
}
function mockBoardStraightDrawTopAndBottomAce() {
  return [{"rank":13,"suit":"heart"},{"rank":12,"suit":"spade"},{"rank":14,"suit":"diamond"},{"rank":4,"suit":"heart"},{"rank":10,"suit":"diamond"}]
}
function mockLoseToStraightDrawTopAndBottomAce() {
  return [{rank:11, suit: "heart"}, {rank:7, suit:"spade"}]
}
/////////

///////// open ended 8,6,5,4,2 - needs a 3 or a 7
function mockStraightDrawDoubleGappedOpenEndedB() {
  return [{rank:8, suit:"spade"}, {rank:6, suit:"heart"}]
}
function mockBoardStraightDrawDoubleGappedOpenEndedB() {
  return [{"rank":5,"suit":"heart"},{"rank":4,"suit":"spade"},{"rank":10,"suit":"diamond"},{"rank":2,"suit":"heart"},{"rank":13,"suit":"diamond"}]
}
function mockLoseToStraightDrawDoubleGappedOpenEndedB() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

///////// 7,5,3
function mockStraightDrawDoubleGappedRunnerRunner() {
  return [{rank:7, suit:"spade"}, {rank:3, suit:"heart"}]
}
function mockBoardStraightDrawDoubleGappedRunnerRunner() {
  return [{"rank":5,"suit":"heart"},{"rank":9,"suit":"spade"},{"rank":14,"suit":"diamond"},{"rank":12,"suit":"heart"},{"rank":5,"suit":"diamond"}]
}
function mockLoseToStraightDrawDoubleGappedRunnerRunner() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
//////////

///////// 7,6,4,3
function mockStraightDrawSingleGappedA() {
  return [{rank:3, suit:"spade"}, {rank:4, suit:"heart"}]
}
function mockBoardStraightDrawSingleGappedA() {
  return [{"rank":7,"suit":"heart"},{"rank":6,"suit":"spade"},{"rank":11,"suit":"diamond"},{"rank":4,"suit":"heart"},{"rank":13,"suit":"diamond"}]
}
function mockLoseToStraightDrawSingleGappedA() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

//

///////// 7,5,4,3
function mockStraightDrawSingleGappedB() {
  return [{rank:3, suit:"spade"}, {rank:4, suit:"heart"}]
}
function mockBoardStraightDrawSingleGappedB() {
  return [{"rank":7,"suit":"heart"},{"rank":5,"suit":"spade"},{"rank":11,"suit":"diamond"},{"rank":9,"suit":"heart"},{"rank":5,"suit":"diamond"}]
}
function mockLoseToStraightDrawSingleGappedB() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

/////////
function mockBabyStraight() {
  return [{rank:3, suit:"spade"}, {rank:14, suit:"heart"}]
}
function mockBoardBabyStraight() {
  return [{"rank":2,"suit":"heart"},{"rank":7,"suit":"spade"},{"rank":9,"suit":"diamond"},{"rank":4,"suit":"heart"},{"rank":5,"suit":"diamond"}]
}
function mockLoseToBabyStraight() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

/////////
function mockSixHighStraight() {
  return [{rank:3, suit:"spade"}, {rank:14, suit:"heart"}]
}
function mockBoardSixHighStraight() {
  return [{"rank":2,"suit":"heart"},{"rank":5,"suit":"spade"},{"rank":6,"suit":"diamond"},{"rank":4,"suit":"heart"},{"rank":9,"suit":"diamond"}]
}
function mockLoseToSixHighStraight() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

/////////
function mockStraight() {
  return [{rank:2, suit:"spade"}, {rank:3, suit:"heart"}]
}
function mockBoardStraight() {
  return [{"rank":4,"suit":"heart"},{"rank":7,"suit":"spade"},{"rank":9,"suit":"diamond"},{"rank":5,"suit":"heart"},{"rank":6,"suit":"diamond"}]
}
function mockLoseToStraight() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////

/////////
function mockStraightBoard() {
  return [{rank:2, suit:"spade"}, {rank:3, suit:"heart"}]
}
function mockBoardStraightBoard() {
  return [{"rank":5,"suit":"heart"},{"rank":6,"suit":"spade"},{"rank":7,"suit":"diamond"},{"rank":8,"suit":"heart"},{"rank":9,"suit":"diamond"}]
}
function mockLoseToStraightBoard() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
/////////


/////////
function mockBigPair() {
  return [{rank:14, suit:"spade"}, {rank:11, suit:"heart"}]
}
function mockLoseToBigPair() {
  return [{rank:13, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardForBigPair() {
  return [{"rank":14,"suit":"heart"},{"rank":13,"suit":"spade"},{"rank":2,"suit":"diamond"},{"rank":10,"suit":"heart"},{"rank":9,"suit":"diamond"}]
}
/////////

////////
function mockPair() {
  return [{rank:9, suit:"spade"}, {rank:10, suit:"heart"}]
}
function mockLoseToPair() {
  return [{rank:3, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardForPair() {
  return [{"rank":9,"suit":"heart"},{"rank":12,"suit":"spade"},{"rank":2,"suit":"diamond"},{"rank":6,"suit":"heart"},{"rank":8,"suit":"diamond"}]
}
/////////

////////
function mockTwoPair() {
  return [{rank:9, suit:"spade"}, {rank:10, suit:"heart"}]
}
function mockLoseToTwoPair() {
  return [{rank:3, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardForTwoPair() {
  return [{"rank":9,"suit":"heart"},{"rank":10,"suit":"spade"},{"rank":2,"suit":"diamond"},{"rank":6,"suit":"heart"},{"rank":8,"suit":"diamond"}]
}
/////////

////////
function mockWeakHolecards() {
  return [{rank:2, suit:"spade"}, {rank:7, suit:"heart"}]
}
function mockLoseToWeakHolecards() {
  return [{rank:3, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardWeakHolecards() {
  return [{"rank":7,"suit":"heart"},{"rank":12,"suit":"spade"},{"rank":2,"suit":"diamond"},{"rank":9,"suit":"heart"},{"rank":8,"suit":"diamond"}]
}
////////

function mockHoleCardsBoardPaired() {
  return [{"rank":12,"suit":"spade"}, {rank:10, suit:"heart"}]
}
function mockHoleCardsLoseToBoardPaired() {
  return [{rank:3, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardPaired() {
  return [{"rank":9,"suit":"heart"},{rank:9, suit:"spade"},{"rank":2,"suit":"diamond"},{"rank":6,"suit":"heart"},{"rank":8,"suit":"diamond"}]
}


function mockBigPairHoleCards() {
  return [{rank:14, suit:"spade"}, {rank:14, suit:"heart"}]
}
function mockLoseToBigPairHoleCards() {
  return [{rank:3, suit: "heart"}, {rank:5, suit:"spade"}]
}
function mockBoardBigPairHoleCards() {
  return [{"rank":12,"suit":"spade"},{"rank":2,"suit":"diamond"},{"rank":6,"suit":"heart"},{"rank":9,"suit":"heart"},{"rank":8,"suit":"diamond"}]
}

