export default function MockFunctions ({humanHoleCardsArr, computerHoleCardsArr, boardCardsArr, on, gameObj}) {

  if (!on) {
    return null
  }
  let r = "" //Math.floor(Math.random() * 10000);
  let mockHuman = 'function mockHuman' + r + "() {"
  mockHuman+= "return [" +
    "{rank:" + humanHoleCardsArr[0].rank + ", suit:'" + humanHoleCardsArr[0].suit + "'}," +
    "{rank:" + humanHoleCardsArr[1].rank + ", suit:'" + humanHoleCardsArr[1].suit + "'}"
  mockHuman+= "]}"
  let mockHumanJson = "[" +
    "{rank:" + humanHoleCardsArr[0].rank + ", suit:'" + humanHoleCardsArr[0].suit + "'}," +
    "{rank:" + humanHoleCardsArr[1].rank + ", suit:'" + humanHoleCardsArr[1].suit + "'}"
  mockHumanJson+= "]"

  let mockComputer = 'function mockComputer' + r + "() {"
  mockComputer+= "return [" +
    "{rank:" + computerHoleCardsArr[0].rank + ", suit:'" + computerHoleCardsArr[0].suit + "'}," +
    "{rank:" + computerHoleCardsArr[1].rank + ", suit:'" + computerHoleCardsArr[1].suit + "'}"
  mockComputer+= "]}"
  let mockComputerJson = "[" +
    "{rank:" + computerHoleCardsArr[0].rank + ", suit:'" + computerHoleCardsArr[0].suit + "'}," +
    "{rank:" + computerHoleCardsArr[1].rank + ", suit:'" + computerHoleCardsArr[1].suit + "'}"
  mockComputerJson+= "]"

  let mockBoard = 'function mockBoard' + r + "() {"
  mockBoard+= "return [" +
    "{rank:" + boardCardsArr[0].rank + ", suit:'" + boardCardsArr[0].suit + "'}, " +
    "{rank:" + boardCardsArr[1].rank + ", suit:'" + boardCardsArr[1].suit + "'}, " +
    "{rank:" + boardCardsArr[2].rank + ", suit:'" + boardCardsArr[2].suit + "'}, " +
    "{rank:" + boardCardsArr[3].rank + ", suit:'" + boardCardsArr[3].suit + "'}, " +
    "{rank:" + boardCardsArr[4].rank + ", suit:'" + boardCardsArr[4].suit + "'}"
  mockBoard+= "]}"
  let mockBoardJson = "[" +
    "{rank:" + boardCardsArr[0].rank + ", suit:'" + boardCardsArr[0].suit + "'}, " +
    "{rank:" + boardCardsArr[1].rank + ", suit:'" + boardCardsArr[1].suit + "'}, " +
    "{rank:" + boardCardsArr[2].rank + ", suit:'" + boardCardsArr[2].suit + "'}, " +
    "{rank:" + boardCardsArr[3].rank + ", suit:'" + boardCardsArr[3].suit + "'}, " +
    "{rank:" + boardCardsArr[4].rank + ", suit:'" + boardCardsArr[4].suit + "'}"
  mockBoardJson+= "]"


  if (gameObj.street === 'preflop') {
    //console.log(mockHuman, mockComputer, mockBoard)
    //console.log('humanHoleCardsArr = ' + mockHumanJson, 'computerHoleCardsArr = ' + mockComputerJson, 'boardCardsArr =' + mockBoardJson)
  }
  return (<div className={"cb"} key={gameObj.numHands}>
    <br />
    <div className={"mockFunctionsCont"}>
      <div className={"mockFunctionCont"}>{mockHuman}</div>
      <div className={"mockFunctionCont"}>{mockComputer}</div>
      <div className={"cb"}/>
      <div className={"mockFunctionCont"}>{mockBoard}</div>
      <br />
      <div className={"mockFunctionCont"}>humanHoleCardsArr = {mockHumanJson}</div>
      <div className={"mockFunctionCont"}>computerHoleCardsArr = {mockComputerJson}</div>
      <div className={"cb"}/>
      <div className={"mockFunctionCont"}>boardCardsArr = {mockBoardJson}</div>
      <br/>
    </div>
    </div>
    )
}