import actionsbase from "./actionsbase"

export default class pair extends actionsbase {

  mngAllIn() {
    let actObj = {action:'fold'}
    if (!this.threatDrawThCrossed) {
      if (this.handScore >= this.adjPairScoreTh) {
        actObj.action = 'call'
      }
    }
    return actObj
  }


// START FLOP
//
  flopFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjPairScoreTh && (this.hopeFlushScore >= this.hopeFlushScoreTh || this.hopeStraightScore >= this.hopeStraightScoreTh)) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh && (this.threatFlushScore < this.threatFlushScoreTh && this.threatStraightScore < this.threatStraightScoreTh)
      ) {
        actObj = {action: "raise", betAmount:this.bestRaiseAmount}
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      let handScore = this.handScore
      let adjPairScoreTh = this.adjPairScoreTh
      if (handScore >= adjPairScoreTh && this.hasAHopeDraw) {
        actObj = { action: 'allIn' }
      } else if (handScore >= adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (handScore >= adjPairScoreTh || this.safeCall) {
        actObj = {action:'call'}
      }
    }
    return actObj
  }

  flopNotFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjPairScoreTh && (this.hasAHopeDraw || this.noThreatDraw)) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.minRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjPairScoreTh && this.hasAHopeDraw) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh || this.safeCall) {
        actObj = {action: 'call'}
      }
    }
    return actObj
  }
//
// END FLOP

// START TURN
//
  turnFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check'}
      if (this.handScore >= this.adjPairScoreTh && this.hasAHopeDraw) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount  }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjPairScoreTh && this.hasAHopeDraw) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh  && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjPairScoreTh || this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }

  turnNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjPairScoreTh && (this.hopeFlushScore >= this.hopeFlushScoreTh || this.hopeStraightScore >= this.hopeStraightScoreTh)
      ) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh && (this.threatFlushScore < this.threatFlushScoreTh
        && this.threatStraightScore < this.threatStraightScoreTh)
      ) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = {action:'fold'}
      if (this.handScore >= this.adjPairScoreTh && this.hasAHopeDraw) {
        actObj = { action: 'allIn' }
      } else if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      } else if (this.handScore >= this.adjPairScoreTh || this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }
//
// END TURN

// START RIVER
//
  riverFirstToAct() {
    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: this.bestRaiseAmount }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold'}
      if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'call'}
      } else if (this.handScore >= this.adjPairScoreTh || this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }

  riverNotFirstToAct() {

    let actObj = {}
    if (this.canRaise || this.canCheck) {
      actObj = { action: 'check' }
      if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'raise', betAmount: (this.continuationBet ? this.continuationBet : this.minRaiseAmount * 2) }
      }
    } else { //canReraise || canCall
      actObj = { action: 'fold' }
      if (this.handScore >= this.adjPairScoreTh && this.noThreatDraw) {
        actObj = { action: 'call'  }
      } else if (this.handScore >= this.adjPairScoreTh || this.safeCall) {
        actObj.action = 'call'
      }
    }
    return actObj
  }

}

