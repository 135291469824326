import React, { useCallback, useEffect, useRef, useState } from 'react'
import TableHeadsUp from "../components/table_headsup";
import ActionMsg from "../components/actionmsg";
import HeadsUpScoreboard from './headsup_scoreboard'
import MockFunctions from './mock_functions'
import Analysis from './headsup_analysis'
import { ErrorBoundary } from '../components/errorboundary'

const Analyze = require('../helpers/analyze.js');
const Util = require("../helpers/util");
const Chips = require("../helpers/chips");
//const Debug = require("../helpers/debug")
const Bet = require("../helpers/bet")
const Table = require("../helpers/table")

const Main = ({
  initGameObj,
  // dev,
  newTourn,
  firstGameObj,
  initUseWait,
  initUseComputerWait
}) => {

  // // The small blind acts first pre-flop and the big blind acts first on the flop, turn, and river.
  let [gameObj, setGameObj] = useState(firstGameObj)
  let [useWait] = useState(initUseWait)
  let [useComputerWait] = useState(initUseComputerWait)
  let [analysisObj, setAnalysisObj] = useState({ })
  let [disabledBtnObj, setDisabledBtnObj] = useState({})

  // styles
  // let currentBetterBg = { backgroundColor: "green" }
  // let nextBetterBg = { backgroundColor: "#c5dee4" }
  let [tournEndStyle, setTournEndStyle] = useState({ display: "none" })
  let [tournEndMsg, setTournEndMsg] = useState("")
  let smallBlindCallPreflopBtnStyleTmp = {display:'none'}
  let callBtnStyleTmp = {display:'inline-block'}
  if (gameObj.smallBlindPlayer === 'human' && gameObj.previousBetAmount === 0 && gameObj.street === 'preflop') {
    smallBlindCallPreflopBtnStyleTmp = {display:'inline-block'}
    callBtnStyleTmp = {display:'none'}
  }
  let [smallBlindCallPreflopBtnStyle, setSmallBlindCallPreflopBtnStyle] = useState(smallBlindCallPreflopBtnStyleTmp)
  let [callBtnStyle, setCallBtnStyle] = useState(callBtnStyleTmp)

  function setDev() {
    if (dev) {
      setDevBool(false)
    } else {
      setDevBool(true)
    }
  }

  let [dev, setDevBool] = useState(false)

  let streetHistoryContCssClass = 'streetHistoryCont'
  if (dev) {
    streetHistoryContCssClass = 'streetHistoryContDev'
  }


  function incRaiseAmount () {
    let displayBetAmount = gameObj.displayBetAmount
    let bigBlind = gameObj.bigBlind
    let smallBlind = gameObj.smallBlind
    let value = displayBetAmount + bigBlind
    let maxRaiseAmount = gameObj.betObj.maxRaiseAmount
    // if incrementing when human is small blind, add small blind if max is reached
    if (maxRaiseAmount - value === smallBlind) {
      value+= smallBlind
    }
    if (value <= gameObj.humanChips && value <= maxRaiseAmount) {
      let tmpGameObj = {...gameObj, displayBetAmount: value}
      setGameObj(tmpGameObj)
      initDisabledBtnObj(false, tmpGameObj, false)
    }
  }

  function decRaiseAmount () {
    let value = gameObj.displayBetAmount - gameObj.bigBlind
    let minBet = gameObj.betObj.minRaiseAmount
    if (gameObj.street === "preflop" && gameObj.playerBetTurn === 'human' && gameObj.smallBlindPlayer === 'human'
      && gameObj.previousBetAmount === 0
    ) {
      //value = minBet + gameObj.smallBlind
    }
    if (value >= minBet) {
      let tmpGameObj = {...gameObj, displayBetAmount: value}
      setGameObj(tmpGameObj)
      initDisabledBtnObj(false, tmpGameObj, false)
    }
  }

  const initDisabledBtnObj = useCallback((disableAll, gameObj) => {

    function canCheck(gameObj) {
      let lastRow = Util.getLastRowForStreet(gameObj)
      if (lastRow === false) {
        return true
      } else if (lastRow.action === 'check' || lastRow.action === 'smallBlindCall') {
        return true
      }
      return false
    }

    // small blind 10 calls with 10, action to big blind who can raise or check, but not call
    function canCall(gameObj) {
      let lastRow = Util.getLastRowForStreet(gameObj)
      // if preflop the small blind with a small blind of 10 calls with a bet of 10, bigBlind cannot "call" and must
      // either bet or check
      if (gameObj.street === "preflop" && gameObj.bigBlindPlayer === gameObj.playerBetTurn && lastRow.action === "smallBlindCall" ) {
        return false
      }
      // can call small blind
      if (gameObj.street === "preflop" && gameObj.smallBlindPlayer === gameObj.playerBetTurn && gameObj.previousBetType === "smallBlind" ) {
        return true
      }
      // big blind is first to act post preflop, so if there was no previous bet, there's nothing for big blind to call,
      // must check or bet
      if (!lastRow.action) {
        return false
      }
      // you can't call after opponent checks or calls
      if (lastRow.action === "check" || lastRow.action === "call") {
        return false
      }

      if (gameObj.playerBetTurn === 'human' && gameObj.humanChips === 0) {
        return false
      }

      return true
    }

    function canBetPot(gameObj) {

      if (gameObj.previousBetType === 'allIn') {
        return false
      }
      if (gameObj.humanChips < gameObj.pot || gameObj.computerChips < gameObj.pot) {
        return false
      }
      return true
    }

    function canRaise(gameObj) {
      if (gameObj.previousBetType === 'allIn') {
        return false
      }
      if (gameObj.computerChips === 0 || gameObj.humanChips === 0) {
        return false
      }
      let lastRow = Util.getLastRowForStreet(gameObj)
      if (lastRow.action === 'raise' && lastRow.actor !== 'human' && lastRow.betAmount === gameObj.humanChips) {
        return false
      }
      if (gameObj.betObj.minRaiseAmount === gameObj.betObj.minCallAmount) {
        return false
      }
      return true
    }

    function canIncRaise(gameObj) {

      if (gameObj.previousBetType === 'allIn') {
        return false
      }
      let displayBetAmount = gameObj.displayBetAmount
      let humanChips = gameObj.humanChips
      let bool = displayBetAmount + gameObj.bigBlind <= humanChips
      return bool
    }

    function canDecRaise(gameObj) {
      if (gameObj.previousBetType === 'allIn') {
        return false
      }
      if (gameObj.displayBetAmount <= gameObj.bigBlind || gameObj.displayBetAmount <= gameObj.betObj.minRaiseAmount) {
        return false
      }
      if (gameObj.street === "preflop" && gameObj.displayBetAmount === gameObj.preflopSmallBlindMinRaiseAmount) {
        return false
      }
      if (gameObj.playerBetTurn === 'human' && gameObj.humanChips === 0) {
        return false
      } else if (gameObj.playerBetTurn === 'computer' && gameObj.computerChips === 0) {
        return false
      }
      return true
    }

    function canAllIn(gameObj) {
      if (gameObj.previousBetType === 'allIn') {
        return false
      }
      if (gameObj.playerBetTurn === 'human' && gameObj.humanChips === 0) {
        return false
      } else if (gameObj.playerBetTurn === 'computer' && gameObj.computerChips === 0) {
        return false
      }
      return true
    }

    function canFold(gameObj) {
      let lastRow = Util.getLastRowForStreet(gameObj)
      if (lastRow.action === 'smallBlind' && lastRow.actor === 'human') {
        return true
      } else if (gameObj.previousBetType === 'smallBlindCall') {
        return false
      } else if (gameObj.street === 'preflop' && gameObj.previousBetType === '') {
        return false
      } else if (gameObj.previousBetType === '' || gameObj.previousBetType === 'check' || gameObj.previousBetType === 'call') {
        return false
      }
      return true
    }

    let obj = {}
    if (disableAll || gameObj.playerBetTurn === 'waitcomputer' || gameObj.playerBetTurn === 'waithuman'
    ) {
      obj.foldBtnDisabled = "disabled"
      obj.callBtnDisabled = "disabled"
      obj.raiseBtnDisabled = "disabled"
      obj.incRaiseBtnDisabled = "disabled"
      obj.decRaiseBtnDisabled = "disabled"
      obj.betPotBtnDisabled = "disabled"
      obj.allInBtnDisabled = "disabled"
      obj.checkBtnDisabled = "disabled"
      obj.checkBtnDisabledClass = "checkBtnDisabledClass"
      obj.callBtnDisabledClass = "callBtnDisabledClass"
      obj.betPotBtnDisabledClass = "betPotBtnDisabledClass"
      obj.decRaiseBtnDisabledClass = "decRaiseBtnDisabledClass"
      obj.incRaiseBtnDisabledClass = "incRaiseBtnDisabledClass"
      obj.allInBtnDisabledClass = "allInBtnDisabledClass"
      obj.raiseBtnDisabledClass = "raiseBtnDisabledClass"
      obj.foldBtnDisabledClass = "foldBtnDisabledClass"
    } else {
      obj.checkBtnDisabled = ""
      obj.foldBtnDisabled = ""
      obj.callBtnDisabled = ""
      obj.raiseBtnDisabled = ""
      obj.incRaiseBtnDisabled = ""
      obj.decRaiseBtnDisabled = ""
      obj.betPotBtnDisabled = ""
      obj.allInBtnDisabled = ""
      obj.callBtnDisabledClass = ""
      obj.betPotBtnDisabledClass = ""
      obj.checkBtnDisabledClass = ""
      obj.decRaiseBtnDisabledClass = ""
      obj.incRaiseBtnDisabledClass = ""
      obj.allInBtnDisabledClass = ""
      obj.raiseBtnDisabledClass = ""
      obj.foldBtnDisabledClass = ""
      if (!canCheck(gameObj)) {
        obj.checkBtnDisabled = "disabled"
        obj.checkBtnDisabledClass = "checkBtnDisabledClass"
      }
      if (!canCall(gameObj)) {
        obj.callBtnDisabled = "disabled"
        obj.callBtnDisabledClass = "callBtnDisabledClass"
      }
      if (!canBetPot(gameObj)) {
        obj.betPotBtnDisabled = "disabled"
        obj.betPotBtnDisabledClass = "betPotBtnDisabledClass"
      }
      if (!canDecRaise(gameObj)) {
        obj.decRaiseBtnDisabled = "disabled"
        obj.decRaiseBtnDisabledClass = "decRaiseBtnDisabledClass"
      }
      if (!canIncRaise(gameObj)) {
        obj.incRaiseBtnDisabled = "disabled"
        obj.incRaiseBtnDisabledClass = "incRaiseBtnDisabledClass"
      }
      if (!canAllIn(gameObj)) {
        obj.allInBtnDisabled = "disabled"
        obj.allInBtnDisabledClass = "allInBtnDisabledClass"
      }
      if (!canRaise(gameObj)) {
        obj.raiseBtnDisabled = "disabled"
        obj.raiseBtnDisabledClass = "raiseBtnDisabledClass"
      }
      if (!canFold(gameObj)) {
        obj.foldBtnDisabled = "disabled"
        obj.foldBtnDisabledClass = "foldBtnDisabledClass"
      }
    }
    setDisabledBtnObj(obj)
  }, [])

  // a button calls this in addition to forwardToNext()
  const goComputer = useCallback((tmpGameObj, doSetState) => {
    let debugFunc = 0
    if (typeof doSetState === 'undefined') {
      doSetState = true
    }
    if (debugFunc)console.log("goComputer doSetState", doSetState)

    // the buttons that call this func won't be passing in params
    if (!tmpGameObj) {
      if (debugFunc)console.log("goComputer tmpGameObj", tmpGameObj)
      if (debugFunc)console.log("goComputer !tmpGameObj so using gameObj:", gameObj)
      tmpGameObj = gameObj
    }
    if (debugFunc)console.log("A goComputer tmpGameObj", tmpGameObj, "gameObj", gameObj)
    // if using useWait = true, when the button clicks Go Computer, playerBetTurn comes in as 'waitcomputer', otherwise
    // if not using useWait, it comes in as 'computer'
    tmpGameObj.playerBetTurn = 'computer'
    tmpGameObj.betObj = Chips.getBetObj(tmpGameObj)
    if (doSetState) {
      initDisabledBtnObj(true, tmpGameObj)
      setGameObj(tmpGameObj)
      if (debugFunc)console.log("B goComputer setGameObj", tmpGameObj)
    }

    return tmpGameObj

  }, [initDisabledBtnObj, setGameObj, gameObj])

  // a button calls this in addition to forwardToNext()
  const goHuman = useCallback((tmpGameObj, doSetState) => {

    let debugFunc = 0
    if (typeof doSetState === 'undefined') {
      doSetState = true
      if (debugFunc)console.log("goHuman doSetState", doSetState)
    }
    // the buttons that call this func won't be passing in params
    if (!tmpGameObj) {
      tmpGameObj = gameObj
      if (debugFunc)console.log("goHuman tmpGameObj not set using gameObj:", gameObj)
    }
    // if using useWait = true, when the button clicks Go Human, playerBetTurn comes in as 'waithuman', otherwise
    // if not using useWait, it comes in as 'human'
    tmpGameObj.playerBetTurn = 'human'
    tmpGameObj.betObj = Chips.getBetObj(tmpGameObj)
    tmpGameObj.displayBetAmount = Chips.getDisplayBetAmount(tmpGameObj)
    if (doSetState) {
      initDisabledBtnObj(false, tmpGameObj)
      setGameObj(tmpGameObj)
      if (debugFunc)console.log("goHuman setting gameObj into state", tmpGameObj.streetHistoryArr)
    }

    return tmpGameObj

  }, [initDisabledBtnObj, setGameObj, gameObj])

  const getNextStreet = useCallback( (prevalue) => {
    let value
    if (!prevalue) {
      value = 'preflop'
    } else if (prevalue === 'preflop') {
      value = 'flop'
    } else if (prevalue === 'flop') {
      value = 'turn'
    } else if (prevalue === 'turn') {
      value = 'river'
    } else if (prevalue === 'river') {
      value = 'done'
    }
    return value
  }, [])

  // all 'action' methods (check, fold, raise, etc) should call this (or setNextStreet which will then call this)
  // when hand is done or tournament is done or both players are all in, this gets called
  const forwardToNext = useCallback( (tmpGameObj) => {
    let debugFunc = 0
    if (debugFunc)console.log("forwardToNext A tmpGameObj", tmpGameObj)
    if (tmpGameObj.street === 'preflop' && tmpGameObj.previousBetType === 'smallBlind') {
      tmpGameObj = Table.dealCards(tmpGameObj)
    }
    if (tmpGameObj.street !== 'done' && tmpGameObj.isHumanHandActionDone && tmpGameObj.isComputerHandActionDone) {
      // forward to end of river when both are all in
      let value = getNextStreet(tmpGameObj.street)
      let playerBetTurn = 'computer'
      if (tmpGameObj.playerBetTurn === 'waitcomputer' || tmpGameObj.playerBetTurn === 'computer') {
        playerBetTurn = 'human'
      }
      if (value === 'done') {
        tmpGameObj = {...tmpGameObj,
          readyForNextHand: false,
          isHumanHandActionDone:false,
          isComputerHandActionDone:false,
          street: value
        }
        setGameObj(tmpGameObj)
      } else {
        let nextStreetObj = {
          street: value,
          playerBetTurn: playerBetTurn,
          readyForNextHand: false
        }
        tmpGameObj = { ...tmpGameObj, ...nextStreetObj }
        setGameObj(tmpGameObj)
      }
    } else if (tmpGameObj.street === "done" && tmpGameObj.readyForNextHand === false) {
      tmpGameObj = Table.calcWinner(tmpGameObj)
      if (debugFunc)console.log("forwardToNext winner calced A", tmpGameObj)
      let humanTournWins = tmpGameObj.humanTournWins
      if (tmpGameObj.gameOver && tmpGameObj.tournCurrentNum === tmpGameObj.tournMaxNum) {
        setTournEndStyle({display:'block'})
        let msg = "Tournament has ended. Human:" + humanTournWins + " out of " + tmpGameObj.tournMaxNum + "."
        setTournEndMsg(msg)
      }
      if (debugFunc)console.log("forwardToNext winner calced B", tmpGameObj)
      setGameObj(tmpGameObj)
      initDisabledBtnObj(true, null, false)
    } else if (tmpGameObj.previousBetType === 'folded') {
      initDisabledBtnObj(true, tmpGameObj, false)
      setGameObj(tmpGameObj)
    } else if (useComputerWait && (tmpGameObj.previousBetType === 'check' || tmpGameObj.previousBetType === 'call')
      && (tmpGameObj.playerBetTurn === 'computer' || tmpGameObj.playerBetTurn === 'waitcomputer')) {
      if (debugFunc)console.log("forwardToNext B tmpGameObj", tmpGameObj)
      tmpGameObj = goComputer(tmpGameObj)
    } else if (!useWait && !useComputerWait && tmpGameObj.playerBetTurn === 'computer') {
      if (debugFunc)console.log("forwardToNext C tmpGameObj", tmpGameObj)
      tmpGameObj = goComputer(tmpGameObj)
    } else if (!useWait && tmpGameObj.playerBetTurn === 'human') {
      if (debugFunc)console.log("forwardToNext D tmpGameObj", tmpGameObj)
      tmpGameObj = goHuman(tmpGameObj)
    } else {
      if (tmpGameObj.playerBetTurn !== 'human') {
        initDisabledBtnObj(true, tmpGameObj, false)
      }
      if (debugFunc)console.log("forwardToNext E tmpGameObj", tmpGameObj)
      setGameObj(tmpGameObj)
    }
    return tmpGameObj
  }, [useWait, goComputer, goHuman, initDisabledBtnObj, setGameObj, getNextStreet, useComputerWait])

  const setNextStreet = useCallback( (prevalue, tmpGameObj) => {

    let debugFunc = 0

    let nextStreet = getNextStreet(prevalue)
    let playerBetTurn
    if (nextStreet === 'preflop' && tmpGameObj.previousBetType === 'smallBlind') {
      playerBetTurn = tmpGameObj.smallBlindPlayer
    } else {
      playerBetTurn = tmpGameObj.bigBlindPlayer
    }
    if (useComputerWait && (playerBetTurn === 'computer' || playerBetTurn === 'waitcomputer')) {
      if (tmpGameObj.street === 'preflop' && tmpGameObj.smallBlindPlayer === 'computer' && tmpGameObj.previousBetType === 'smallBlind') {
        // don't 'wait' for computer to make smallBlindCall and subsequent action, just do it
        playerBetTurn = 'computer'
        if (debugFunc)console.log("A setNextStreet")
      } else if (tmpGameObj.previousBetType === 'check' || tmpGameObj.previousBetType === 'call' || tmpGameObj.previousBetType === 'smallBlind') {
        // so skip 'wait' when human checking or calling
        playerBetTurn = 'computer'
        if (debugFunc)console.log("B setNextStreet")
      } else {
        playerBetTurn = 'waitcomputer'
      }
    } else if (useWait && playerBetTurn === 'computer') {
      playerBetTurn = 'waitcomputer'
    } else if (useWait && playerBetTurn === 'human') {
      playerBetTurn = 'waithuman'
    }

    let nextStreetObj = {
      previousBetAmount: 0,
      street: nextStreet,
      msg:"",
      playerBetTurn: playerBetTurn,
      totalStreets: ++tmpGameObj.totalStreets,
      numStreets: ++tmpGameObj.numStreets
    }
    tmpGameObj = {...tmpGameObj, ...nextStreetObj}
    if (debugFunc)console.log("setNextStreet ", tmpGameObj)
    forwardToNext(tmpGameObj)

  }, [forwardToNext, getNextStreet, useWait, useComputerWait])

  const nextHand = useCallback((newMatch, newGameObj, tmpGameObj) => {
    tmpGameObj = Table.nextHand(newMatch, newGameObj, tmpGameObj)
    setNextStreet("", tmpGameObj)
    if (tmpGameObj.smallBlindPlayer === 'human') {
      setSmallBlindCallPreflopBtnStyle({ display: 'inline-block' })
      setCallBtnStyle({ display: 'none' })
    } else{
      setSmallBlindCallPreflopBtnStyle({ display: 'none' })
      setCallBtnStyle({ display: 'inline-block' })
    }
  }, [setNextStreet])

  const begin = useRef(0);
  const initialized = useRef(0);
  useEffect(() => {
    if (initialized.current === 0) {
      initialized.current = 1
      nextHand(true, gameObj, null)
      begin.current = 1
      document.title = 'Texas Holdem Poker'
    }
  }, [initialized, gameObj, nextHand])

  const nextMatch = useCallback((tmpGameObj) => {
    let newGameObj = initGameObj(false, tmpGameObj)
    nextHand(true, newGameObj, null)
  }, [initGameObj, nextHand])

  function check(tmpGameObj) {
    let [doSetNextStreet, doForwardToNext, returnedGameObj] = Bet.check(tmpGameObj, useWait, useComputerWait)
    if (doSetNextStreet) {
      setNextStreet(tmpGameObj.street, returnedGameObj)
    } else if (doForwardToNext){
      forwardToNext(returnedGameObj)
    }
  }

  function fold (tmpGameObj) {
    tmpGameObj = Bet.fold(tmpGameObj)
    forwardToNext(tmpGameObj)
  }

  // handle small blind making min call bet to match big blind and handle any raise for the same player and action seperately
  // forwardAction is used if there is no raise in addition to making a small blind call
  function smallBlindCall(tmpGameObj) {
    tmpGameObj = Bet.smallBlindCall(tmpGameObj)
    // show call btn and hide min call btn
    if (tmpGameObj.playerBetTurn === 'human') {
      setSmallBlindCallPreflopBtnStyle ({display:'none'})
      setCallBtnStyle({display:'inline-block'})
    }
    return tmpGameObj
  }

  function call(bet, tmpGameObj) {
    tmpGameObj = Bet.call(bet, tmpGameObj)
    setNextStreet(gameObj.street, tmpGameObj)
  }

  function raise(betType, bet, tmpGameObj) {
    tmpGameObj = Bet.raise(betType, bet, tmpGameObj, useWait, useComputerWait)
    tmpGameObj = forwardToNext(tmpGameObj)
    return tmpGameObj
  }

  function playerAction(obj, tmpGameObj) {

    let debugFunc = 0
    if (debugFunc)console.log("START playerAction ", tmpGameObj.playerBetTurn, tmpGameObj.street, obj, "streetHistoryArr", tmpGameObj.streetHistoryArr)
    let bet = obj.betAmount ? obj.betAmount : 0
    // handle small blind first to act preflop matching big blind with min call separate from raise
    if (obj.action === 'smallBlindCall') {
      tmpGameObj = smallBlindCall(tmpGameObj)
      // don't useWait or useComputerWait unless debugging as it should get through these actions without stopping
      let nextPlayer = Util.getPlayerBetTurn(tmpGameObj.playerBetTurn, false, false)
      //let nextPlayer= tmpGameObj.playerBetTurn === 'human' ? 'computer' : 'human'
      tmpGameObj.playerBetTurn = nextPlayer
      if (debugFunc){
        console.log("playerAction smallBlindCall and forwarding action to " + nextPlayer, tmpGameObj.playerBetTurn)
      }
      forwardToNext(tmpGameObj)
    } else if (tmpGameObj.playerBetTurn === tmpGameObj.smallBlindPlayer && tmpGameObj.previousBetType === 'smallBlind') {
      // if small blind is raising instead of just small blind calling, do small blind call before raise
      if ((obj.action === 'allIn' || obj.action === 'raise') && bet > tmpGameObj.smallBlind) {
        // if the allIn or raise is the same amount as the small blind, skip the small blind call method so as to keep
        // the allIn/raise designation
        tmpGameObj = smallBlindCall(tmpGameObj)
        if (debugFunc) {
          console.log("playerAction smallBlindCall prior to action:" + obj.action)
          console.log("playerAction smallBlindCall and raise. gameObj returned from smallBlindCall():" + tmpGameObj)
        }
        bet = bet - tmpGameObj.smallBlind
      }
      if (debugFunc)console.log("small blind deducted from bet results in new bet amount:" +bet)
    } else {
      if (debugFunc)console.log("playerAction using tmpGameObj in state for action: " + obj.action + " and player: ", tmpGameObj.playerBetTurn)
    }

    if (obj.action === 'allIn') {
      raise('allIn', bet, tmpGameObj)
    } else if (obj.action === 'raise') {
      raise('raise', bet, tmpGameObj)
    } else if (obj.action === 'call') {
      call(bet, tmpGameObj)
    } else if (obj.action === 'check') {
      check(tmpGameObj)
    } else if (obj.action === 'fold') {
      fold(tmpGameObj)
    } else if (obj.action !== 'smallBlindCall') {
      let str = "action:" + obj.action + " betAmount:" + obj.betAmount
      str+= obj.analysisObj && obj.analysisObj.streetActionArr ? " streetAction: " + obj.analysisObj.streetActionArr.pop() : ""
      throw new Error("playerAction invalid obj:" + str)
    }
    if (debugFunc)console.log("END playerAction")

  }

  if (begin.current === 0) {
    return null
  } else if (begin.current === 1) {

    //console.log("gameObj", gameObj)
    if (gameObj.street !== 'done' && gameObj.isHumanHandActionDone && gameObj.isComputerHandActionDone) {
      //console.log("A forwardtonext", gameObj)
      forwardToNext(gameObj)
      //console.log("B forwardtonext", gameObj)
    } else if (gameObj.street === "done" && gameObj.readyForNextHand === false) {
      //console.log("C forwardtonext", gameObj)
      forwardToNext(gameObj)
      //console.log("D forwardtonext", gameObj)
    } else if (gameObj.playerBetTurn === "computer" && gameObj.readyForNextHand === false) {
      if (gameObj.street === 'preflop') {
        let preflopObj = Analyze.preflop(gameObj)
        playerAction(preflopObj, gameObj)
        //console.log("preflopObj", preflopObj)
        setAnalysisObj(preflopObj.analysisObj)
      } else if (gameObj.street !== "done") {
        let postflopObj = Analyze.postFlop(gameObj, analysisObj)
        //console.log("postflopObj", postflopObj)
        playerAction(postflopObj, gameObj)
        setAnalysisObj(postflopObj.analysisObj)
      }
    }

    let debugDiff = gameObj.computerChips + gameObj.humanChips + gameObj.pot - (gameObj.startingChips * 2)
    let debugStyle = {display:'none'}
    if (debugDiff !== 0){
      debugStyle = {display:'block'}
    }

    // when in non-dev mode, show last row first (beneath the bet buttons)
    let finalStreetHistoryArr = []
    if (!dev) {
      let l = gameObj.streetHistoryArr.length - 1
      for(let i = l; i >= 0; i--) {
        let obj = gameObj.streetHistoryArr[i]
        finalStreetHistoryArr.push({...obj})
      }
    } else {
      finalStreetHistoryArr = [...gameObj.streetHistoryArr]
    }


    if (gameObj.betObj.minCallAmount > 0) {
      //callBtnStyle = {...callBtnStyle, position:'relative',top:'-10px'}
    }
    let msgStyle = {display:'none'}
    if (gameObj.msg !== '') {
      msgStyle = {display:'block'}
    }
    let sumBets = 0
    let sumHumanBets = 0
    let sumComputerBets = 0
    let headsupScreenCont = dev ? "headsupScreenContDev" : "headsupScreenCont"
    let keyPrefix = "tournNum_" + gameObj.tournCurrentNum
    return (
      <div key={keyPrefix} className={headsupScreenCont}>
        <ErrorBoundary>
        <HeadsUpScoreboard gameObj={gameObj}/>
        <div className="cb"></div>
        <TableHeadsUp
          humanHoleCardsArr={gameObj.humanHoleCardsArr}
          computerHoleCardsArr={gameObj.computerHoleCardsArr}
          boardCardsArr={gameObj.boardCardsArr}
          gameObj = {gameObj}
          dev = {dev}
          setDev = {setDev}
        />
        <div key={"numHands_" + gameObj.numHands + "_" + debugDiff} style={debugStyle} className="debug">{debugDiff}</div>
          <ActionMsg
            gameObj = {gameObj}
            nextHand = {nextHand}
            nextMatch = {nextMatch}
            goComputer = {goComputer}
            goHuman = {goHuman}
          />
          <div className="tournEnd" style={tournEndStyle}>{tournEndMsg} &nbsp;
            <button
              className = "btn newTournamentBtn"
              onClick={() => newTourn()}>New Tournament
            </button>
          </div>
          <div className={"cb"}/>
          <div key={gameObj.playerBetTurn + "_" + gameObj.street + "_" + gameObj.numHands + "_" + gameObj.numStreets} className="betButtonsCont">

            {/* MSG */}
            <div key={'msg' + gameObj.msg + "_" + gameObj.pot} style={msgStyle} className={"msg"} dangerouslySetInnerHTML={{ __html: gameObj.msg }}/>

            {/*CHECK*/}
            <button disabled={disabledBtnObj.checkBtnDisabled}
                    className={'btn betBtn checkBtn ' + disabledBtnObj.checkBtnDisabledClass}
                    onClick={() => playerAction({action:'check'}, gameObj)}>Check
            </button>
            {/*FOLD*/}
            <button disabled={disabledBtnObj.foldBtnDisabled}
                    className={'btn betBtn foldBtn ' + disabledBtnObj.foldBtnDisabledClass}
                    onClick={() => fold(gameObj)}>Fold
            </button>
            {/*CALL*/}
            <button key = {gameObj.betObj.minCallAmount + "_minCallBtnKey"}
                    disabled={disabledBtnObj.callBtnDisabled}
                    className={'btn betBtn callBtn ' + disabledBtnObj.callBtnDisabledClass}
                    style={callBtnStyle}
                    onClick={() => playerAction({
                        action:'call',
                        betAmount: gameObj.betObj.minCallAmount
                      }, gameObj)
                    }
            >Call <div className="callAmountCont">{gameObj.betObj.minCallAmount > 0 ? gameObj.betObj.minCallAmount : '000'}</div>
            </button>
            {/*MIN CALL*/}
            <button disabled={disabledBtnObj.callBtnDisabled}
                    className={'btn betBtn callBtn ' + disabledBtnObj.callBtnDisabledClass}
                    style={smallBlindCallPreflopBtnStyle}
                    onClick={
                      () => playerAction({
                        action:'smallBlindCall', betAmount:
                        gameObj.betObj.preflopSmallBlindCallAmount
                      }, gameObj)
                    }
            >Call <div className="callAmountCont">{gameObj.betObj.preflopSmallBlindCallAmount}</div>
            </button>
            {/*BET POT*/}
            <button disabled={disabledBtnObj.betPotBtnDisabled}
                    className={'btn betBtn betPotBtn ' + disabledBtnObj.betPotBtnDisabledClass}
                    onClick={() => playerAction({action:'raise', betAmount:gameObj.pot}, gameObj)}>Pot
            </button>
          </div>
          <div key = {gameObj.betObj.minRaiseAmount +"_raiseBtnKey"} className={"raiseBtnCont"}  >
            {/*RAISE*/}
            <button disabled={disabledBtnObj.raiseBtnDisabled}
                    className={'btn betBtn raiseBtn ' + disabledBtnObj.raiseBtnDisabledClass}
                    onClick={() => playerAction({action:'raise', betAmount:gameObj.displayBetAmount}, gameObj)}
            >Bet <div className={"raiseBetAmountText"}>{gameObj.displayBetAmount}</div>
            </button>
            {/*INC RAISE*/}
            <button disabled={disabledBtnObj.incRaiseBtnDisabled}
                    key={"inc_" + gameObj.displayBetAmount}
                    className={'btn betBtn incRaiseBtn ' + disabledBtnObj.incRaiseBtnDisabledClass}
                    onClick={() => incRaiseAmount()}>+{gameObj.bigBlind}
            </button>
            {/*DEC RAISE*/}
            <button disabled={disabledBtnObj.decRaiseBtnDisabled}
                    key={"dec_" + gameObj.displayBetAmount}
                    className={'btn betBtn decRaiseBtn ' + disabledBtnObj.decRaiseBtnDisabledClass}
                    onClick={() => decRaiseAmount()}>-{gameObj.bigBlind}
            </button>
            {/*ALL IN*/}
            <button disabled={disabledBtnObj.allInBtnDisabled}
                    className={'btn betBtn allInBtn ' + disabledBtnObj.allInBtnDisabledClass}
                    onClick={() => playerAction({action:'allIn', betAmount:gameObj.betObj.maxRaiseAmount}, gameObj)}>All In
            </button>
          </div>


        {/* display of hand by street and actor with a summary row. In non-dev mode, appears below bet buttons.
         in dev mode, appears top right of screen */}
        <div className={"cb"}/>
        <div key={keyPrefix + '_streetHistory' + gameObj.streetHistoryArr.length} className={streetHistoryContCssClass}>
        <table className="streetHistoryContTable"><tbody>
          {finalStreetHistoryArr.map((obj, i) => {
            sumBets+=obj.betAmount
            sumHumanBets+=(obj.actor === 'human' ? obj.betAmount : 0)
            sumComputerBets+=(obj.actor === 'computer' ? obj.betAmount : 0)
            return <tr key={i.toString() + "_tr"}>
              <td key={i.toString() + "_actor"} className="historyMsg" dangerouslySetInnerHTML={{ __html: obj.actor}} />
              <td key={i.toString() + "_street"} className="historyMsg" dangerouslySetInnerHTML={{ __html: obj.street}} />
              <td key={i.toString() + "_action"} className="historyMsg" dangerouslySetInnerHTML={{ __html: obj.action}} />
              <td key={i.toString() + "_betAmount"} className="historyMsg" dangerouslySetInnerHTML={{ __html: obj.betAmount}} />
            </tr>
          })}
          <tr key={sumBets.toString() + "_tr"}>
            <td key={sumBets.toString() + "_empty"} className={'historyMsg'}></td>
            <td key={sumBets.toString() + "_sumHumanBets"} className={'historyMsg'}>Human: {sumHumanBets}</td>
            <td key={sumBets.toString() + "_sumComputerBets"} className={'historyMsg'}>Computer: {sumComputerBets}</td>
            <td key={sumBets.toString() + "_sumBets"} className={'historyMsg'}>Pot: {sumBets}</td>
          </tr>
        </tbody>
        </table>

        </div>
      <Analysis
        analysisObj = {analysisObj}
        gameObj = {gameObj}
        dev = {dev}
      />
      <MockFunctions
        humanHoleCardsArr={gameObj.humanHoleCardsArr}
        computerHoleCardsArr={gameObj.computerHoleCardsArr}
        boardCardsArr={gameObj.boardCardsArr}
        on={dev}
        gameObj = {gameObj}
        />
      </ErrorBoundary>
      </div>
    );

  }

}

export default Main