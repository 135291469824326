export default function HeadsUpScoreboard ({gameObj}) {

  let currentTournNum = gameObj.tournCurrentNum
  let maxTournNum = gameObj.tournMaxNum
  let key = gameObj.tournCurrentNum + "_" + gameObj.numHands + "_" + gameObj.humanTournWins + "_" + gameObj.computerTournWins
  // let bigBlind = gameObj.tmpBigBlind ? gameObj.tmpBigBlind : gameObj.bigBlind
  // let smallBlind = gameObj.tmpSmallBlind ? gameObj.tmpSmallBlind : gameObj.smallBlind

  return <div key={key} className="scoreContainer">
      <div className="homeIconCont"><a
        href='/'><img
        className="homeIconImg"
        src='/home-outline_black_50x50.png'
        alt='Home Page'
      /></a>
      </div>
      {/*<div className="level">Blinds: {smallBlind},{bigBlind}</div>*/}
      <div className="matchWinLoss">Match Hands. Human:{gameObj.wins} Computer:{gameObj.loses}</div>
      <div className="tournData">Tourn# {currentTournNum} of {maxTournNum}. Human:{gameObj.humanTournWins} Computer:{gameObj.computerTournWins}</div>
      {/*<div className="score"><span>Score:{score}</span></div>*/}

      <div className="cb"></div>

    </div>

}
