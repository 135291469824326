import {useState} from 'react'
let Util = require('../helpers/util')

export default function Analysis ({
  analysisObj,
  gameObj,
  dev
}) {

  let [viewStreetHistory, setViewStreetHistory] = useState(true)
  let [viewBetHistory, setViewBetHistory] = useState(false)

  if (dev === false) {
    return null
  }

  let holeCardsObj = gameObj.holeCardsObj

  function toggleView() {
    if (viewStreetHistory) {
      setViewStreetHistory(false)
      setViewBetHistory(true)
    } else if (viewBetHistory){
      setViewStreetHistory(true)
      setViewBetHistory(false)
    }
  }
  let handAnalyticsContStyle = {display:'block'}
  //let handAnalyticsBtnStyle


  let betHistoryContStyle = {display:'none'}
  let betHistoryBtnStyle = {backgroundColor:"#ffffff"}
  let streetHistoryContStyle = {display:'none'}
  let streetHistoryBtnStyle = {backgroundColor:"#ffffff"}
  if (viewStreetHistory){
    streetHistoryContStyle = {display:'block'}
    betHistoryBtnStyle = {backgroundColor:"#cccccc"}
  } else {
    betHistoryContStyle = {display:'block'}
    streetHistoryBtnStyle = {backgroundColor:"#cccccc"}
  }

  if (analysisObj && analysisObj.holeCardsObj && Object.entries(analysisObj.holeCardsObj).length > 0) {
    // post preflop, analysisObj has position of holecards in relation to the board
    holeCardsObj = analysisObj.holeCardsObj
  }

  //console.log("analysisObj", analysisObj)
  //console.log("analysisObj.streetActionArr", analysisObj.streetActionArr)

  function isObject(obj) {
    return obj && typeof obj === 'object' && obj.constructor === Object;
  }

  function getJson(obj, displayIndex) {
    let property = obj[0]
    if (displayIndex === false) {
      property = ''
    }
    if (typeof obj[1] === 'undefined') {
      return null
    }
    let value = obj[1]
    if (obj[1] === true) {
      value = "<span class='greenTrue'>" + obj[1] + "</span>"
    } else {
      value = JSON.stringify(value, null, 1)
    }
    let r = (Array.isArray(obj[1]) || isObject(obj[1]) ? "<pre class='jsonpre'>" + property : property + ": ")
      + value
      + (Array.isArray(obj[1]) || isObject(obj[1]) ? "</pre>" : "")
    return r
  }

  // function getFlatJson(obj) {
  //   if (typeof obj === 'undefined' || typeof obj[1] === 'undefined') {
  //     return null
  //   }
  //   let str = ''
  //   Object.entries(obj[1]).forEach((tmp, i) => {
  //     let value = ''
  //     if (tmp[1] === true) {
  //       value = "<span class='greenTrue'>" + tmp[1] + "</span>"
  //     } else {
  //       value = JSON.stringify(tmp[1], null, 1)
  //     }
  //     let r = (Array.isArray(tmp[1]) || isObject(tmp[1]) ? "<pre class='jsonpre'>" + tmp[0] : tmp[0] + ": ")
  //       + value
  //       + (Array.isArray(tmp[1]) || isObject(tmp[1]) ? "</pre>" : "")
  //     str+=r + "\n"
  //   })
  //   return str
  // }

  let dataLabel = ' '
  if (analysisObj.streetAction) {
    dataLabel+= analysisObj.conditionalBetSection
    dataLabel+= analysisObj.streetAction
  }

  let humanCategoryIndex = Util.getHumanCategoryIndex(gameObj)
  let humanCategory = Util.humanCategoryObj[humanCategoryIndex]

  return (
    <>
      <div key={"condhistory_" + gameObj.numStreets} className={"dataCont"} style={streetHistoryContStyle}>
        <div className={"dataStartLabel"}>START Street Action Conditional History</div>
        <ul className={"dataUl"}>
          {analysisObj.streetActionArr && Object.entries(analysisObj.streetActionArr).map((obj, i) => {
            return <li key={i} className="historyMsg" dangerouslySetInnerHTML={{ __html: obj[1] }}/>
          })}
        </ul>
        <div className={"dataStartLabel"}>END Street Action Conditional History</div>
        <br />
        <div className={"dataStartLabel"}>START {gameObj.street}<br/> {dataLabel}</div>

        <ul className={"dataUl"}>
          <li className="liTitle dataMsgTitle">Bet Data</li>
          {analysisObj.ddObj && Object.entries(analysisObj.ddObj).map((arr, i) => {
            return <div
              key={i + "_playerBetHistory_" + arr.length} className="betDataRow"
              dangerouslySetInnerHTML={{ __html: getJson(arr, true) }}/>
          })}
        </ul>

        <div className={"dataStartLabel"}>END {gameObj.street} {dataLabel}</div>
      </div>

      <div className={"cb"} />
      <br />

      <div key = {"betStats_" + gameObj.numHands} style={betHistoryContStyle} className='betHistoryTableCont'>
        <table className="betHistoryTable">
          <tbody>
          <tr>
            <td colSpan='2'>human '{humanCategory}'</td>
          </tr>
          {Object.entries(gameObj.humanHistoryObj).map((arr, i) => {
            return (<tr key={arr[0] + "_" + arr[1]}>
              <td className="betHistoryTd">
                <div
                  key={"humanBetHistory_" + i}
                  dangerouslySetInnerHTML={{ __html: getJson(arr) }}/>
              </td>
            </tr>)
          })}
          {/*<tr><td className="betHistoryTd">numStreets: {gameObj.numStreets}</td></tr>*/}
          {/*<tr><td className="betHistoryTd">numHands: {gameObj.numHands}</td></tr>*/}
          </tbody>
        </table>
        <table className="betHistoryTable">
          <tbody>
          <tr>
            <td colSpan='2'>computer</td>
          </tr>
          {Object.entries(gameObj.computerHistoryObj).map((arr, i) => {
            return (<tr key={arr[0] + "_" + arr[1]}>
              <td className="betHistoryTd">
                <div
                  key={"computerBetHistory_" + i}
                  dangerouslySetInnerHTML={{ __html: getJson(arr) }}/>
              </td>
            </tr>)
          })}
          {/*<tr><td className="betHistoryTd">numStreets: {gameObj.numStreets}</td></tr>*/}
          {/*<tr><td className="betHistoryTd">numHands: {gameObj.numHands}</td></tr>*/}
          </tbody>
        </table>
      </div>

      <div className={"cb"} />

      <div className={"tabCont"}>
        <div style ={betHistoryBtnStyle} className={"betHistoryBtn"} onClick={() => toggleView()}>Bet History</div>
        <div style ={streetHistoryBtnStyle} className={"condHistoryBtn"} onClick={() => toggleView()}>Street History</div>
      </div>
      <div className={"cb"} />

      <div style={handAnalyticsContStyle} className='analysisCont' key={'analysis_' + analysisObj.length}>

        <div className={'cb'}/>

        <div className={"flushObj"}>
          <div className="analysisTitle">Computer Flush Draw</div>
          {analysisObj.drawObj && analysisObj.drawObj.flushObj && Object.entries(analysisObj.drawObj.flushObj).map((obj, i) => {
            return <div
              key={i + "_flushObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj) }}
            />
          })}
        </div>

        <div className={"threatFlushObj"}>
          <div className="analysisTitle">Board Flush Draw</div>
          {analysisObj.drawObj && analysisObj.drawObj.threatFlushObj && Object.entries(analysisObj.drawObj.threatFlushObj).map((obj, i) => {
            return <div
              key={i + "_threatflushObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj) }}
            />
          })}
        </div>

        <div className={"straightObj"}>
          <div className="analysisTitle">Computer Straight Draw</div>
          {analysisObj.drawObj && analysisObj.drawObj.straightObj && Object.entries(analysisObj.drawObj.straightObj).map((obj, i) => {
            return <div key={i + "_straightObj_" + Object.entries(obj[0]).length}>
              <div
                key={i + "_" + String(obj[1])}
                dangerouslySetInnerHTML={{ __html: getJson(obj) }}/>
            </div>
          })}
        </div>

        <div className={"threatStraightObj"}>
          <div className="analysisTitle">Board Straight Draw</div>
          {analysisObj.drawObj && analysisObj.drawObj.threatStraightObj && Object.entries(analysisObj.drawObj.threatStraightObj).map((obj, i) => {
            return <div key={i + "_threatStraightObj" + Object.entries(obj[0]).length}>
              <div key={String(obj[1])}
                   dangerouslySetInnerHTML={{ __html: getJson(obj) }}/>
            </div>
          })}
        </div>

        <div className={"handObj"} key={holeCardsObj.highcard.position + "_position"}>
          <div className="analysisTitle">Computer Hole Cards</div>
          {Object.entries(holeCardsObj).map((obj, i) => {
            return <div
              key={i + "_holeCardsObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj, true) }}
            />
          })}
          {analysisObj.handEvalObj ? <div className={'cb'}><br/><b>Computer Hand Eval</b></div> : ''}
          {analysisObj.handEvalObj && Object.entries(analysisObj.handEvalObj).map((obj, i) => {
            return <div
              key={i + "_handEvalObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj, true) }}
            />
          })}
          {analysisObj.bestcardsArr ? <div className={'cb'}><br/><b>Computer Hand</b></div> : ''}
          {analysisObj.bestcardsArr && Object.entries(analysisObj.bestcardsArr).map((obj, i) => {
            return <div
              key={i + "_bestcardsArr_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj, false) }}
            />
          })}
          {analysisObj.rankObj && Object.entries(analysisObj.rankObj).map((obj, i) => {
            return <div
              key={i + "_rankObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj) }}
            />
          })}
          <br/>
          {analysisObj.drawObj && analysisObj.drawObj.drawProbability
            ? <div><b>Draw Probability:</b> {analysisObj.drawObj.drawProbability}</div>
            : ""}
          {analysisObj.drawObj && analysisObj.drawObj.outsArr && analysisObj.drawObj.outsArr.length
            ? <div><b>Outs:</b> {analysisObj.drawObj.outsArr.length}</div>
            : ""}
          {analysisObj.drawObj && analysisObj.drawObj.outsArr && Object.entries(analysisObj.drawObj.outsArr).map((obj, i) => {
            return <div
              key={i + "_outsArr_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj, false) }}
            />
          })}

        </div>

        <div className="playerHistory">
          <div className="analysisTitle">Board Hand</div>
          {analysisObj.boardObj && Object.entries(analysisObj.boardObj.bestcardsArr).map((obj, i) => {
            return <div
              key={i + "_commHandObjbestcardsArr_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj, false) }}
            />
          })}
          {analysisObj.boardObj && Object.entries(analysisObj.boardObj.rankObj).map((obj, i) => {
            return <div
              key={i + "_boardObjRankObj_" + obj.length}
              dangerouslySetInnerHTML={{ __html: getJson(obj) }}
            />
          })}

        </div>

        <div className={'cb'}></div>

      </div>

    </>
  )
}