import React, { useState } from 'react'
import { capitalizeFirstLetter } from '../helpers/util'

const Util = require('../helpers/util')

// THE BLOCK BELOW THE GAME TABLE ("Flop: Action to Human. &darr;)

export default function ActionMsg({
  gameObj,
  nextHand,
  nextMatch,
  goComputer,
  goHuman
}) {

  let [actionMsg, setActionMsg] = useState("")
  let [actionMsgHtml, setActionMsgHtml] = useState("")
  if (gameObj.playerBetTurn === 'computer' && gameObj.street!== 'done' && gameObj.readyForNextHand !== true) {
    return null
  }
  let tmpActionMsgHtml
  let tmpActionMsg = Util.getActionMsg(gameObj)
  let street = capitalizeFirstLetter(gameObj.street)
  if (tmpActionMsg === 'Action to Human') {
    tmpActionMsgHtml = street + ": <span class='actionToHuman'>Action to Human.<span class='downArrowSymbol'>&darr;</span></span>"
  } else if (tmpActionMsg === 'Action to Computer') {
    tmpActionMsgHtml = street + ": Action to Computer. <span class='rightArrowSymbol'>&rarr;</span>"
  } else {
    tmpActionMsgHtml = tmpActionMsg
  }
  if (actionMsgHtml !== tmpActionMsgHtml) {
    setActionMsg(tmpActionMsg)
    setActionMsgHtml(tmpActionMsgHtml)
  }

  let actionMsgColor = "actionMsg"+ gameObj.street
  let nextHandBtnStyle = {display:'none'}
  if (gameObj.readyForNextHand && gameObj.gameOver !== 1) {
    nextHandBtnStyle = {display:'block'}
  }
  let nextMatchBtnStyle = { display: "none" }
  if (gameObj.gameOver && gameObj.tournCurrentNum < gameObj.tournMaxNum) {
    nextMatchBtnStyle = { display: "block" }
  }
  let playerBetTurn = gameObj.playerBetTurn
  let goHumanBtnStyle = {display:'block'}
  let goComputerBtnStyle = {display:'block'}
  if (playerBetTurn === 'waithuman') {
    goHumanBtnStyle = {display:'block'}
  } else if (playerBetTurn === 'waitcomputer') {
    goComputerBtnStyle = {display:'block'}
  } else if (playerBetTurn === 'computer' || playerBetTurn === 'human') {
    goComputerBtnStyle = {display:'none'}
    goHumanBtnStyle = {display:'none'}
  } else {
    if (gameObj.gameOver === 1 || gameObj.readyForNextHand) {
      goHumanBtnStyle = { display: 'none' }
    }
    if (gameObj.gameOver === 1 || gameObj.readyForNextHand) {
      goComputerBtnStyle = { display: 'none' }
    }
  }

  return (<>
      <div className={"actionMsg " + actionMsgColor}  key={actionMsg}>
        <div className='actionMsgTextCont' dangerouslySetInnerHTML={{ __html: actionMsgHtml}} />
        <button
          className='nextHandBtn'
          style={nextHandBtnStyle}
          onClick={() => nextHand(false, null, gameObj)}>Next hand
        </button>
        <button
          style={nextMatchBtnStyle}
          className = "btn nextMatchBtn"
          onClick={() => nextMatch(gameObj)}>Next Match
        </button>
        <button
          className='btn goComputerBtn'
          style={goComputerBtnStyle}
          onClick={() => goComputer(gameObj, true)}>Go Computer
        </button>
        <button
          className='btn goHumanBtn'
          style={goHumanBtnStyle}
          onClick={() => goHuman(gameObj)}>Go Human
        </button>
      </div>
      <div className={"cb"} />
    </>

  )
}