const Mock = require('../helpers/mock_headsup_hands')
const evalHandValue = require('../helpers/handEvalModules')
const Base = require('../helpers/base')
const Util = require('../helpers/util')

export function dealCards(tmpGameObj) {

  // set random values to be used later. Done here so that re-render does not trigger different values
  let randObj = {}
  randObj.oneInTwo = Math.floor(Math.random() * 2) + 1;
  randObj.oneInThree = Math.floor(Math.random() * 3) + 1;
  randObj.oneInFour = Math.floor(Math.random() * 4) + 1;
  randObj.oneInFive = Math.floor(Math.random() * 5) + 1;
  randObj.playLoose = Math.floor(Math.random() * 5) === 0

  let humanHoleCardsArr = []
  let computerHoleCardsArr = []
  let boardCardsArr = []

  let useMock = 0
  if (!useMock) {
    let deckArr = Base.getDeckArr();
    humanHoleCardsArr = deckArr.splice(0, 2)
    computerHoleCardsArr = deckArr.splice(0, 2)
    boardCardsArr = deckArr.splice(0, 5)
  } else {
    let handObj = Mock.getHands('3ofakind')
    computerHoleCardsArr = handObj.computerHoleCardsArr
    humanHoleCardsArr = handObj.humanHoleCardsArr
    boardCardsArr = handObj.boardCardsArr

    humanHoleCardsArr = [{rank:10, suit:'diamond'},{rank:6, suit:'spade'}]
    computerHoleCardsArr = [{rank:7, suit:'spade'},{rank:2, suit:'diamond'}]
    boardCardsArr = [{rank:5, suit:'diamond'}, {rank:2, suit:'club'}, {rank:10, suit:'heart'}, {rank:6, suit:'heart'}, {rank:2, suit:'heart'}]


  }

  // order holecards highest rank first
  humanHoleCardsArr.sort(function (a, b) {
    return b.rank - a.rank;
  });
  // order holecards highest rank first
  computerHoleCardsArr.sort(function (a, b) {
    return b.rank - a.rank;
  });
  let holeCardsObj = evalHandValue.evalHoleCards(computerHoleCardsArr)
  tmpGameObj = {...tmpGameObj,
    randObj:randObj,
    humanHoleCardsArr:humanHoleCardsArr,
    computerHoleCardsArr:computerHoleCardsArr,
    boardCardsArr:boardCardsArr,
    holeCardsObj:holeCardsObj
  }
  //console.log("Table.dealCards tmpGameObj", tmpGameObj)
  return tmpGameObj
}

export function calcWinner(gameObj) {
  let playerResultArr
  let tmpMsg
  let humanChips = gameObj.humanChips
  let computerChips = gameObj.computerChips
  let loses = gameObj.loses
  let wins = gameObj.wins
  let handWinner
  let handWonAmount
  let humanHoleCardsArr = gameObj.humanHoleCardsArr
  let computerHoleCardsArr = gameObj.computerHoleCardsArr
  let boardCardsArr = gameObj.boardCardsArr
  playerResultArr = Base.setBestTexasHoldemHand([humanHoleCardsArr, computerHoleCardsArr], boardCardsArr, "done")
  playerResultArr = Base.pickWinningHand(playerResultArr)
  if (playerResultArr[0].tie) {
    // TIE
    let besthand = playerResultArr[0].besthand
    if (besthand === 'threeofakind') {
      besthand = '3 of a kind'
    } else if (besthand === 'fourofakind') {
      besthand = '4 of a kind'
    }
    tmpMsg = "<span class='capitilize'>" + besthand + "</span>.<br /> "
    tmpMsg += Base.getBestCardsString(0, playerResultArr);
    let halfPot = parseInt(gameObj.pot / 2)
    humanChips = humanChips + halfPot
    computerChips = computerChips + halfPot
    handWinner = 'Tie'
    handWonAmount = 0
  } else if (playerResultArr[1].winner) {
    // COMPUTER WINS
    let besthand = playerResultArr[1].besthand
    if (besthand === 'threeofakind') {
      besthand = '3 of a kind'
    } else if (besthand === 'fourofakind') {
      besthand = '4 of a kind'
    }
    tmpMsg = "Computer's <span class='capitilize'>" + besthand + "</span><br /> "
    tmpMsg += Base.getBestCardsString(1, playerResultArr) + "<br />Human's <span class='capitilize'>" + playerResultArr[0].besthand + "</span><br /> " + Base.getBestCardsString(0, playerResultArr)
    loses++
    computerChips = computerChips + gameObj.pot
    handWinner = 'Computer'
    //handWonAmount = gameObj.pot
    handWonAmount = Util.getHandWonAmount('computer', gameObj)
  } else if (playerResultArr[0].winner) {
    // HUMAN WINS
    let besthand = playerResultArr[0].besthand
    if (besthand === 'threeofakind') {
      besthand = '3 of a kind'
    } else if (besthand === 'fourofakind') {
      besthand = '4 of a kind'
    }
    tmpMsg = "Human's <span class='capitilize'>" + besthand + "</span><br /> "
    tmpMsg += Base.getBestCardsString(0, playerResultArr) + "<br />Computer's <span class='capitilize'>" + playerResultArr[1].besthand + "</span><br /> " + Base.getBestCardsString(1, playerResultArr)
    wins++
    humanChips = humanChips + gameObj.pot
    handWinner = 'Human'
    handWonAmount = Util.getHandWonAmount('human', gameObj)
  }
  let gameOver = 0
  let humanWonTourn = 0
  let humanTournWins = gameObj.humanTournWins
  let computerTournWins = gameObj.computerTournWins
  if (humanChips === 0) {
    gameOver = 1
    tmpMsg = "Human lost.<br/>" + tmpMsg
    computerTournWins++
  } else if (computerChips === 0) {
    gameOver = 1
    tmpMsg = "Computer lost.<br/>" + tmpMsg
    humanWonTourn = 1
    humanTournWins++
  }

  gameObj = {...gameObj,
    humanChips:humanChips,
    computerChips: computerChips,
    humanWonTourn: humanWonTourn,
    loses: loses,
    wins: wins,
    msg:tmpMsg,
    readyForNextHand:true,
    gameOver:gameOver,
    handWinner:handWinner,
    handWonAmount:handWonAmount,
    pot:0,
    humanTournWins:humanTournWins,
    computerTournWins:computerTournWins,
    tournCurrentNum: (humanTournWins + computerTournWins)
  }
  return gameObj
}

export function nextHand(newMatch, newGameObj, gameObj) {

  let debugFunc = 0
  let bigBlindPlayer
  let smallBlindPlayer
  if (newMatch) {
    // newGameObj will only be passed in when newMatch is true
    gameObj = {...newGameObj}
    bigBlindPlayer = gameObj.bigBlindPlayer
    smallBlindPlayer = gameObj.smallBlindPlayer
  }
  if (gameObj.numHands > 1 && gameObj.numStreets > 0) {
    // First hand has bb player and small blind player set in gameObj.js so don't switch them here
    bigBlindPlayer = gameObj.bigBlindPlayer === "human" ? "computer" : "human"
    smallBlindPlayer = gameObj.smallBlindPlayer === "human" ? "computer" : "human"
  }
  let humanChips = gameObj.humanChips
  let computerChips = gameObj.computerChips
  let bigBlind = gameObj.bigBlind
  let smallBlind = gameObj.smallBlind
  // these get set when actor can't pay blind
  let tmpBigBlind = bigBlind
  let tmpSmallBlind = smallBlind
  //
  let smallBlindAction = 'smallBlind'
  let bigBlindAction = 'bigBlind'
  let numHands = gameObj.numHands + 1
  let msg = ''
  let msg2 = ''
  if (false && numHands % 10 === 0) {
    bigBlind = bigBlind + bigBlind
    smallBlind = smallBlind + smallBlind
    msg = "Blinds escalate.<br/>Big blind: " + bigBlind + "<br/>Small blind: " + smallBlind
  }
  let pot = bigBlind + smallBlind
  let preflopSmallBlindCallAmount = smallBlind
  let preflopSmallBlindMinRaiseAmount = smallBlind + bigBlind

  if (debugFunc)console.log("nextHand() A smallBlind", smallBlind, "bigBlind", bigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)

  // START DEDUCT BLINDS
  //
  // if big blind is 600 and bb actor has 500. bb actor is sent all in. sb actor still has the choice to call 200 or fold
  let isHumanHandActionDone = false
  let isComputerHandActionDone = false
  if (smallBlindPlayer === 'computer' && gameObj.computerChips <= smallBlind) {
    // sb computer can only pay small blind init or less
    tmpSmallBlind = computerChips
    tmpBigBlind = computerChips
    preflopSmallBlindCallAmount = 0
    preflopSmallBlindMinRaiseAmount = 0
    computerChips = 0
    humanChips = humanChips - tmpSmallBlind
    pot = tmpSmallBlind + tmpSmallBlind
    if (debugFunc)console.log("nextHand() B smallBlind", tmpSmallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (smallBlindPlayer === 'human' && humanChips <= smallBlind) {
    // sb human can only pay small blind init or less
    tmpSmallBlind = humanChips
    tmpBigBlind = humanChips
    preflopSmallBlindCallAmount = 0
    preflopSmallBlindMinRaiseAmount = 0
    humanChips = 0
    computerChips = computerChips - tmpSmallBlind
    pot = tmpSmallBlind + tmpSmallBlind
    if (debugFunc)console.log("nextHand() C smallBlind", tmpSmallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (bigBlindPlayer === 'human' && humanChips < smallBlind) {
    // if bb human can't pay sb
    tmpBigBlind = humanChips
    tmpSmallBlind = humanChips
    preflopSmallBlindCallAmount = 0
    isHumanHandActionDone = true
    isComputerHandActionDone = true
    humanChips = 0
    computerChips = computerChips - humanChips
    pot = tmpBigBlind + tmpSmallBlind
    preflopSmallBlindMinRaiseAmount = 0
    if (debugFunc)console.log("nextHand() D smallBlind", tmpSmallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (bigBlindPlayer === 'computer' && computerChips < smallBlind) {
    // if bb computer can't pay sb
    tmpBigBlind = computerChips
    tmpSmallBlind = computerChips
    preflopSmallBlindCallAmount = 0
    isHumanHandActionDone = true
    isComputerHandActionDone = true
    computerChips = 0
    humanChips = humanChips - tmpBigBlind
    pot = tmpBigBlind + tmpSmallBlind
    preflopSmallBlindMinRaiseAmount = 0
    if (debugFunc)console.log("nextHand() E smallBlind", tmpSmallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (bigBlindPlayer === 'human' && humanChips < bigBlind) {
    // bb human can't pay bb. sb actor only to small blind call the diff
    //bigBlindAction = 'allIn'
    tmpBigBlind = humanChips
    preflopSmallBlindCallAmount = humanChips - smallBlind
    humanChips = 0
    computerChips = computerChips - smallBlind
    pot = tmpBigBlind + smallBlind
    preflopSmallBlindMinRaiseAmount = 0
    if (debugFunc)console.log("nextHand() F smallBlind", smallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (bigBlindPlayer === 'computer' && computerChips < bigBlind) {
    // bb computer can't pay bb. sb actor to only small blind call the diff
    tmpBigBlind = computerChips
    // bb 640, comp allIn 560, sb 320, so 320 - (640 - 560) = 240
    preflopSmallBlindCallAmount = computerChips - smallBlind
    computerChips = 0
    humanChips = humanChips - smallBlind
    pot = tmpBigBlind + smallBlind
    preflopSmallBlindMinRaiseAmount = 0
    if (debugFunc)console.log("nextHand() G smallBlind", smallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (smallBlindPlayer === 'computer' && computerChips < bigBlind) {
    // sb computer can make required small blind, but can't make full small blind call
    // subtract small blind from computer chips and the remainder is the most computer can call
    preflopSmallBlindCallAmount = computerChips - smallBlind// blinds:80, 40, comp chips: 60. 60 - 40 = 20
    // the remainder is also the remaining computer chips
    computerChips = computerChips - smallBlind
    preflopSmallBlindMinRaiseAmount = 0
    // deduct bb from human chips. the bb is now the smallBlind and the new preflopSmallBlindCallAmount
    humanChips = humanChips - (preflopSmallBlindCallAmount + smallBlind)
    tmpBigBlind = (preflopSmallBlindCallAmount + smallBlind)
    pot = bigBlind + smallBlind
    if (debugFunc)console.log("nextHand() H smallBlind", smallBlind, "bigBlind", bigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else if (smallBlindPlayer === 'human' && humanChips < bigBlind) {
    // sb human can make required small blind, but can't make full small blind call
    preflopSmallBlindCallAmount = humanChips - smallBlind
    humanChips = humanChips - smallBlind
    preflopSmallBlindMinRaiseAmount = 0
    computerChips = computerChips - (preflopSmallBlindCallAmount + smallBlind)
    tmpBigBlind = (preflopSmallBlindCallAmount + smallBlind)
    pot = bigBlind + smallBlind
    if (debugFunc)console.log("nextHand() I smallBlind", smallBlind, "bigBlind", tmpBigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  } else {
    bigBlindAction = 'bigBlind'
    smallBlindAction = 'smallBlind'
    if (bigBlindPlayer === 'human') {
      humanChips-=bigBlind
      computerChips-=smallBlind
    } else {
      humanChips-=smallBlind
      computerChips-=bigBlind
    }
    pot = bigBlind + smallBlind
    preflopSmallBlindCallAmount = smallBlind
    preflopSmallBlindMinRaiseAmount = smallBlind + bigBlind
    if (debugFunc)console.log("nextHand() J smallBlind", smallBlind, "bigBlind", bigBlind, "preflopSmallBlindCallAmount", preflopSmallBlindCallAmount, "preflopSmallBlindMinRaiseAmount", preflopSmallBlindMinRaiseAmount)
  }
  //
  // END DEDUCT BLINDS

  if (false && numHands % 10 === 0 && (tmpBigBlind >0 || tmpSmallBlind >0)) {
    bigBlind = bigBlind + bigBlind
    smallBlind = smallBlind + smallBlind
    msg = "Blinds escalate.<br/>Big blind: " + bigBlind + "<br/>Small blind: " + smallBlind
    if (tmpBigBlind > 0 && tmpBigBlind <= smallBlind) {
      msg += "<br />But Big Blind can only bet " + tmpBigBlind + ". Small Blind auto calls."
    } else if (tmpBigBlind > 0 && tmpBigBlind <= bigBlind && tmpBigBlind > smallBlind) {
      msg += "<br />But Big Blind can only bet " + tmpBigBlind + "."
    } else if (tmpSmallBlind > 0) {
      msg+="<br />But Small Blind can only bet " + tmpSmallBlind + ". Big Blind auto calls."
    }
  }

  // preflop, big blind auto puts its big blind into the pot and goes after small blind acts with the option to check or raise
  // if big blind has no chips after paying big blind, there is no more action for them, however, if big blind
  // paid more than the small blind, small blind can call or fold
  // if small blind is unable to make the intial put into the pot or the subsequent min call, it big blind will auto call
  if (humanChips === 0 && bigBlindPlayer === 'human') {
    msg2 = "Human allIn preflop"
    isHumanHandActionDone = true
    bigBlindAction = 'allIn'
    if (debugFunc)console.log("nextHand() short blinds A")
    if (tmpBigBlind <= smallBlind) {
      isComputerHandActionDone = true
      if (debugFunc)console.log("nextHand() short blinds B")
    } else {
      // smallBlind should have the opportunity to fold or call whatever bigblind was able to put into the pot
    }
  } else if (computerChips === 0 && bigBlindPlayer === 'computer') {
    msg2 = "Computer allIn preflop"
    isComputerHandActionDone = true
    bigBlindAction = 'allIn'
    if (debugFunc)console.log("nextHand() short blinds C")
    if (tmpBigBlind <= smallBlind) {
      isHumanHandActionDone = true
      if (debugFunc)console.log("nextHand() short blinds D")
    } else {
      // smallBlind should have the opportunity to fold or call whatever bigblind was able to put into the pot
    }
  } else if (humanChips === 0 && smallBlindPlayer === 'human') {
    msg2 = "Human allIn preflop"
    isHumanHandActionDone = true
    smallBlindAction = 'allIn'
    isComputerHandActionDone = true
    if (debugFunc)console.log("nextHand() short blinds E")
  } else if (computerChips === 0 && smallBlindPlayer === 'computer') {
    msg2 = "Computer allIn preflop"
    isHumanHandActionDone = true
    smallBlindAction = 'allIn'
    isComputerHandActionDone = true
    if (debugFunc)console.log("nextHand() short blinds F")
  }

  let tmpGameObj = Util.sumActionHistory(gameObj)
  tmpSmallBlind = tmpSmallBlind > 0 ? tmpSmallBlind : smallBlind
  tmpBigBlind = tmpBigBlind > 0 ? tmpBigBlind : bigBlind
  let bbObj = {actor: bigBlindPlayer, street: "preflop", action: bigBlindAction, betAmount:tmpBigBlind}
  let sbObj = {actor: smallBlindPlayer, street: "preflop", action: smallBlindAction, betAmount:tmpSmallBlind}
  let tmpStreetHistoryArr = [ {...bbObj}, {...sbObj} ]
  tmpGameObj = {...tmpGameObj,
    preflopSmallBlindCallAmount: preflopSmallBlindCallAmount,
    preflopSmallBlindMinRaiseAmount: preflopSmallBlindMinRaiseAmount,
    pot:pot,
    animatePotTo:pot,
    numHands:numHands,
    bigBlind:bigBlind,
    smallBlind:smallBlind,
    bigBlindPlayer:bigBlindPlayer,
    smallBlindPlayer:smallBlindPlayer,
    msg:msg,
    msg2:msg2,
    humanChips:humanChips,
    computerChips:computerChips,
    isHumanHandActionDone:isHumanHandActionDone,
    isComputerHandActionDone:isComputerHandActionDone,
    readyForNextHand: false,
    handWinner:"",
    handWonAmount:0,
    previousBetType: "smallBlind",
    playerBetTurn:smallBlindPlayer,
    streetHistoryArr:tmpStreetHistoryArr
  }

  let humanCategoryIndex = Util.getHumanCategoryIndex(tmpGameObj)
  tmpGameObj.humanCategoryIndex = humanCategoryIndex

  return tmpGameObj

}