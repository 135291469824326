import Card from "./card";

export default function Hand({handArr, player, show}) {
  let key = handArr[0].rank + "_" + handArr[1].rank + "_" + handArr[0].suit + "_" + handArr[1].suit
  return (<>
  {handArr.map((cardObj, i) => {
    return <div key={key + "_" + i}><Card
        cardObj={cardObj}
        show={show}
    /></div>
  })}

  </>)

}