import { hopeFlushDraw } from './flushAssessment'
import { threatFlushDraw } from './flushAssessment'

const Base = require('../helpers/base');
const BetData = require('../helpers/betData');
const straightDraw = require('../helpers/straightAssessment')
const evalHandValue = require("./handEvalModules")

export function preflop(
  gameObj
) {

  let betObj = gameObj.betObj
  let holeCardsObj = gameObj.holeCardsObj
  let drawObj = {
    flushObj: {},
    straightObj: {},
    threatStraightObj: {},
    threatFlushObj: {},
  }
  let analysisObj = {
    holeCardsObj:holeCardsObj,
    rankObj:{},
    drawObj:drawObj
  }
  analysisObj.rankObj.pair = holeCardsObj.paired ? true : false
  if (analysisObj.rankObj.pair) {
    analysisObj.rankObj.pairrank = holeCardsObj.highcard.rank
  }
  analysisObj.rankObj.highcard = holeCardsObj.paired ? false : true
  let actionObj = BetData.get(analysisObj, gameObj, betObj)
  return actionObj

}

export function postFlop(
  gameObj,
  prevAnalysisObj
) {

  let numCards
  switch (gameObj.street) {
    case 'flop':
      numCards = 3
      break
    case 'turn':
      numCards = 4
      break
    default:
      numCards = 5
  }
  let boardCardsArr = gameObj.boardCardsArr
  let holeCardsObj = gameObj.holeCardsObj
  let holeCardsArr = [holeCardsObj.highcard, holeCardsObj.lowcard]

  // Build computer's hand - best cards, rank etc
  //
  let drawObj = {}
  let rArr = Base.setBestTexasHoldemHand([holeCardsArr], boardCardsArr, gameObj.street, gameObj.playerBetTurn)
  let handObj = rArr[0]
  let analysisObj = {}
  analysisObj.streetActionArr = prevAnalysisObj.streetActionArr ? [...prevAnalysisObj.streetActionArr] : []
  analysisObj.boardObj = {}
  analysisObj.bestcardsArr = [...handObj.bestcardsArr]
  analysisObj.bestcardsRankArr = analysisObj.bestcardsArr.map((obj) => { return obj.rank})
  analysisObj.rankObj = { ...handObj.hasObj }
  delete handObj.bestcardsArr
  delete handObj.hasObj
  analysisObj.rankObj = { ...analysisObj.rankObj, ...handObj }
  //
  // End build player's hand

  // Build hand from board cards only
  //
  let rankArrOne = holeCardsArr.map(obj => { return obj.rank })
  let rankArrTwo = []
  let limitedboardCardsArr = []// limit community cards by street
  let limitedCommRankArr = []
  for (let index in boardCardsArr) {
    if (index < numCards) {
      rankArrTwo.push(boardCardsArr[index].rank)
      limitedboardCardsArr.push(boardCardsArr[index])
      limitedCommRankArr.push(boardCardsArr[index].rank)
    }
  }
  let allCardsRankArr = rankArrOne.concat(rankArrTwo)
  allCardsRankArr.sort((firstItem, secondItem) => secondItem - firstItem);
  limitedCommRankArr.sort((firstItem, secondItem) => secondItem - firstItem);
  analysisObj.holeCardsObj = evalHandValue.evalHoleCardPosition(holeCardsObj, limitedCommRankArr, analysisObj)
  let boardArr = Base.setBestCardsOfOneHand(limitedboardCardsArr, numCards)
  analysisObj.boardObj.bestcardsArr = [...boardArr[0].bestcardsArr]
  analysisObj.boardObj.bestcardsRankArr = analysisObj.boardObj.bestcardsArr
    .filter(obj => typeof obj!== 'undefined')
    .map((obj) => {
      return obj.rank
    })
  delete boardArr[0].bestcardsArr
  analysisObj.boardObj.rankObj = { ...boardArr[0].hasObj }
  analysisObj.boardObj.rankObj.hasAce = analysisObj.boardObj.bestcardsRankArr.includes(14)
  analysisObj.boardObj.rankObj.isBoardPaired = (analysisObj.boardObj.rankObj.threeofakind || analysisObj.boardObj.rankObj.twopair || analysisObj.boardObj.rankObj.pair) ? true : false
  delete boardArr[0].hasObj
  analysisObj.boardObj.rankObj = { ...analysisObj.boardObj.rankObj, ...boardArr[0] }
  //
  // End build board hand

  if (gameObj.street === 'turn' || typeof analysisObj.bestcardsArr[3] === 'undefined') {
    //let tmp = 1
  }
  analysisObj.rankObj.isPlayingTheBoard = getIsPlayingTheBoard(analysisObj)

  // start flush and straight draw calculations
  //
  if (gameObj.street === 'turn') {
    //let tmp = 1
  }
  const hopeFlushDrawObj = new hopeFlushDraw(holeCardsArr, limitedboardCardsArr, gameObj, analysisObj)
  drawObj.flushObj = hopeFlushDrawObj.getFlushDrawObj()
  //drawObj.flushObj = flushDraw.Assessment(holeCardsArr, limitedboardCardsArr, gameObj, analysisObj, false)
  // see if it is a straight flush draw
  if (drawObj.flushObj.isFlushDraw || analysisObj.rankObj.isFlush) {
    drawObj.straightFlushObj = straightDraw.Assessment(
      drawObj.flushObj.fullFlushDrawRankSeq,
      true,
      drawObj.flushObj.flushSuit,
      false,
      gameObj,
      holeCardsObj,
      analysisObj
    )
  } else {
    drawObj.straightFlushObj = straightDraw.initStraightObj()
  }
  //drawObj.threatFlushObj = flushDraw.Assessment([], limitedboardCardsArr, gameObj, analysisObj, true)
  const threatFlushObj = new threatFlushDraw(limitedboardCardsArr, gameObj, analysisObj)
  drawObj.threatFlushObj = threatFlushObj.getFlushDrawObj()
  if (drawObj.threatFlushObj.isFlushDraw || analysisObj.boardObj.rankObj.isFlush) {
    drawObj.threatStraightFlushObj = straightDraw.Assessment(
      drawObj.threatFlushObj.fullFlushDrawRankSeq,
      true,
      drawObj.threatFlushObj.flushSuit,
      true,
      gameObj,
      false,
      analysisObj
    )
  } else {
    drawObj.threatStraightFlushObj = straightDraw.initStraightObj()
  }
  if (analysisObj.rankObj.straight === false && analysisObj.rankObj.handrank < 6) {
    // handrank of 6 is a flush up to 9 is a straight flus
    drawObj.straightObj = straightDraw.Assessment(
      allCardsRankArr,
      false,
      "",
      false,
      gameObj,
      holeCardsObj,
      analysisObj
    )
  } else {
    drawObj.straightObj = straightDraw.initStraightObj()
  }
  drawObj.threatStraightObj = straightDraw.Assessment(
    limitedCommRankArr,
    false,
    drawObj.flushObj.flushSuit,
    true,
    gameObj,
    false,
    analysisObj
  )
  drawObj.outsArr = getOutsArr(analysisObj, gameObj, drawObj)
  drawObj = getDrawProbability(drawObj, gameObj)
  analysisObj.drawObj = drawObj
  //
  // end flush and straight draw calculations

  let actionObj = BetData.get(analysisObj, gameObj)

  if (typeof actionObj.analysisObj === 'undefined') {
    actionObj.analysisObj = analysisObj
  }

  return actionObj

}

// are computer's cards in best cards array or are the best cards all community cards?
function getIsPlayingTheBoard(analysisObj) {

  let isPlayingTheBoard = false
  if (JSON.stringify(analysisObj.boardObj.bestcardsArr) === JSON.stringify(analysisObj.bestcardsArr)) {
    isPlayingTheBoard = true
  }
 /*
  let inBestCards = false
  for (let i = 0; i < 5; i++) {
    if (typeof analysisObj.boardObj.bestcardsArr[i] === 'undefined') {
      break
    }
    let commCardSuit = analysisObj.boardObj.bestcardsArr[i].suit
    let commCardRank = analysisObj.boardObj.bestcardsArr[i].rank
    for (let j = 0; j < 5; j++) {
      let computerCardSuit = analysisObj.bestcardsArr[j].suit
      let computerCardRank = analysisObj.bestcardsArr[j].rank
      if (computerCardSuit === commCardSuit && computerCardRank === commCardRank) {
        inBestCards = true
        break
      }
    }
  }

  if (inBestCards) {
    isPlayingTheBoard = false
  }
  */
  return isPlayingTheBoard
}

function getDrawProbability(drawObj, gameObj) {
  // don't count the same out for a straight that is in a flush when calculating probability
  drawObj.drawProbability = 0
  drawObj.numTotalOuts = drawObj.outsArr.length
  if (drawObj.numTotalOuts) {
    if (gameObj.street === 'flop') {
      // 52 - 5 = 47 cards
      // 1 - (47-21)/47 * (46-21)/46 https://www.reddit.com/r/poker/comments/qh1bt1/is_my_math_correct_here/
      drawObj.drawProbability = (1 - (((47 - drawObj.numTotalOuts)/47) * ((46 - drawObj.numTotalOuts)/46))).toFixed(2)
    } else if (gameObj.street === 'turn') {
      let prob = +(drawObj.numTotalOuts / 46).toFixed(2)
      drawObj.drawProbability = +prob
    }
  }
  let drawProb = (drawObj.drawProbability * 100)
  drawObj.drawProbability = drawProb
  return drawObj
}

function getPairOutsArr(analysisObj, pairrank) {
  let Base = require("./base")
  let suitArr = Base.getSuitArr()
  for (let i in analysisObj.bestcardsArr) {
    let cardObj = analysisObj.bestcardsArr[i]
    if (cardObj.rank === pairrank) {
      suitArr = suitArr.filter((suit) => { return suit !== cardObj.suit })
    }
  }
  return suitArr
}
function getOutsArr(analysisObj, gameObj, drawObj) {

  let outsArr = []
  // START set outs for pair based hands
  //
  let suitArr
  if (analysisObj.rankObj.pairrank) {
    suitArr = getPairOutsArr(analysisObj, analysisObj.rankObj.pairrank)
    for(let i in suitArr) {
      outsArr.push({suit:suitArr[i], rank:analysisObj.rankObj.pairrank})
    }
  } else if (analysisObj.rankObj.threeofakind) {
    suitArr = getPairOutsArr(analysisObj, analysisObj.rankObj.threeofakindrank)
    for(let i in suitArr) {
      outsArr.push({suit:suitArr[i], rank:analysisObj.rankObj.threeofakindrank})
    }
  } else if (analysisObj.rankObj.twopair) {
    let handEvalModules = require("./handEvalModules")
    let twoPairObj = handEvalModules.evalTwoPair(gameObj, analysisObj)
    if (twoPairObj.pairOneObj.ownedBy === 'holecard') {
      suitArr = getPairOutsArr(analysisObj, twoPairObj.pairOneObj.cardPairRank)
      for(let i in suitArr) {
        outsArr.push({suit:suitArr[i], rank:twoPairObj.pairOneObj.cardPairRank})
      }
    }
    if (twoPairObj.pairTwoObj.ownedBy === 'holecard') {
      suitArr = getPairOutsArr(analysisObj, twoPairObj.pairTwoObj.cardPairRank)
      for(let i in suitArr) {
        outsArr.push({suit:suitArr[i], rank:twoPairObj.pairTwoObj.cardPairRank})
      }
    }
  }
  //
  // END outs for pair based hands

  // set some values about the flush and/or straight draw
  if (gameObj.street !== 'river' && analysisObj.rankObj.handrank < 7) {
    // don't bother when hand is already stronger than the draw (7,8,9 handrank is fullhouse or better)
    if (drawObj.flushObj.isFlushDraw || drawObj.straightObj.isStraightDraw) {

      let outsFlushArr = []
      let outsStraightArr = []

      if (drawObj.flushObj.isFlushDraw) {
        outsFlushArr = [...drawObj.flushObj.outsArr]
      }

      if (drawObj.straightObj.isStraightDraw) {
        outsStraightArr = [...drawObj.straightObj.outsArr]
      }

      if (outsFlushArr.length && outsStraightArr.length) {
        outsArr = [...outsFlushArr]
        for (let i in outsStraightArr) {
          let outStraightObj = outsStraightArr[i]
          let alreadyInOutsArrBool = false
          for (let j in outsFlushArr) {
            if (outsFlushArr[j].rank === outStraightObj.rank && outsFlushArr[j].suit === outStraightObj.suit) {
              alreadyInOutsArrBool = true
              break
            }
          }
          if (alreadyInOutsArrBool === false) {
            outsArr.push(outStraightObj)
          }
        }
      } else if (outsFlushArr.length) {
        outsArr = [...outsFlushArr]
      } else if (outsStraightArr.length) {
        outsArr = [...outsStraightArr]
      }
    }

    // calc overcards to the board as outs
    if (analysisObj.holeCardsObj.hasPositionOneAndTwo || analysisObj.holeCardsObj.highcard.position === 1) {
      let suitsArr = Base.getSuitArr()
      let tmpOutsArr = []
      for (let i in suitsArr) {
        if (suitsArr[i] !== analysisObj.holeCardsObj.highcard.suit) {
          tmpOutsArr.push({ rank: analysisObj.holeCardsObj.highcard.rank, suit: suitsArr[i] })
        }
        if (analysisObj.holeCardsObj.lowcard.position === 2 && suitsArr[i] !== analysisObj.holeCardsObj.lowcard.suit) {
          tmpOutsArr.push({ rank: analysisObj.holeCardsObj.lowcard.rank, suit: suitsArr[i] })
        }
      }
      if (tmpOutsArr.length) {
        for (let i in tmpOutsArr) {
          let tmpOutObj = tmpOutsArr[i]
          let alreadyInOutsBool = false
          for (let j in outsArr) {
            let outObj = outsArr[j]
            if (outObj.rank === tmpOutObj.rank && outObj.suit === tmpOutObj.suit) {
              alreadyInOutsBool = true
              break
            }
          }
          if (alreadyInOutsBool === false) {
            outsArr.push(tmpOutObj)
          }
        }
      }
      outsArr.sort(function (a, b) {
        return b.rank - a.rank;
      });
    }
  }
  return outsArr
}



// scare cards
// bluffs
// safety nets (moderate hand with a draw to a monster). if the moderate hand isn't enough, might hit the monster
