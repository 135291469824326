import React from 'react'
import Card from "./card";

export default function HeadsUpBoardCards({boardCardsArr, gameObj}) {

  let show = 0;
  let street = gameObj.street
  if (street === 'preflop') {
    show = 0;
  } else if (street === 'flop') {
    show = 3;
  } else if (street === 'turn') {
    show = 4;
  } else if (street === 'river' || street === 'done' ) {
    show = 5;
  }
  //if (typeof gameObj.previousBetType !== 'undefined' && gameObj.previousBetType === 'folded') {
    //show = 0
  //}


  return (<>
  <div className="streetLabelCont">
  <div className='headsupStreetLabel'>Community Cards</div>
  </div>
    <div key={street} className={"headsupCommCardsCont"}>
    {boardCardsArr.map((cardObj, i) => {
      return <div className="commCardCont" key={'i_' + cardObj['rank'] + '_' + cardObj['suit']} >
        <Card
          cardObj={cardObj}
          show={(i < show ? true : false)}
        />
        <div className="cb"></div>
        </div>
    })}
    <div className="cb"></div>
    </div>
    </>
  )

}
